import { isServer } from 'utils';
import dotenv from 'dotenv';

dotenv.config();

export const getPreloadedConfig: () => State.Config = () => {
  if (isServer) return;
  return (window as any).__PRELOADED_STATE__ ? (window as any).__PRELOADED_STATE__.app?.config : clientConfig;
};

export const env: string = process.env.ENVIRONMENT || 'development';
export const version: string = `${process.env.VERSION}`;
const isAdminPortal = process.env.REACT_APP_ADMIN_PORTAL === 'true';

export const clientConfig: State.Config = {
  okta: {
    spaClientId: `${process.env.REACT_APP_OKTA_SPA_CLIENT_ID}`,
    staffOrg2OrgId: `${process.env.REACT_APP_OKTA_STAFF_ORG2ORG_ID}`,
    impClientUrl: `${process.env.REACT_APP_OKTA_IMP_URL}`,
    impOrg2OrgId: `${process.env.REACT_APP_OKTA_IMP_ORG2ORG_ID}`,
    financeClientId: `${process.env.REACT_APP_OKTA_FINANCE_CLIENT_ID}`,
    b2bOpsClientId: `${process.env.REACT_APP_OKTA_B2B_OPS_CLIENT_ID}`,
    raveOpsClientId: `${process.env.REACT_APP_OKTA_RAVE_OPS_CLIENT_ID}`,
    firmBillingClientId: `${process.env.REACT_APP_OKTA_FIRM_BILLING_CLIENT_ID}`,
    centersClientId: `${process.env.REACT_APP_OKTA_CENTERS_CLIENT_ID}`,
    orgUrl: `${process.env.REACT_APP_OKTA_ORG_URL}`,
    publicUrl: process.env.REACT_APP_OKTA_PUBLIC_URL || '',
    authServerId: `${process.env.REACT_APP_OKTA_AUTH_SERVER_ID}`,
    redirectLoginUrl: process.env.REACT_APP_OKTA_REDIRECT_LOGIN_URL || '',
    b2cUrl: `${process.env.REACT_APP_OKTA_B2C_URL}`,
    b2cClientId: `${process.env.REACT_APP_OKTA_B2C_CLIENT_ID}`,
    b2cAuthServerId: `${process.env.REACT_APP_OKTA_B2C_AUTH_SERVER_ID}`,
  },
  sentryDSN: `${process.env.REACT_APP_SENTRY_DSN}`,
  showTogglePage: `${process.env.REACT_APP_TOGGLE_DASHBOARD ? process.env.REACT_APP_TOGGLE_DASHBOARD : 'false'}`,
  isFBE: `${process.env.isFBE ? process.env.isFBE : 'false'}`,
  isTealiumEnabled: process.env.TEALIUM_ACCOUNT !== 'false',
  isTealiumDebug: process.env.TEALIUM_ENV === 'dev' || process.env.TEALIUM_ENV === undefined,
  graphqlUrl: `${process.env.REACT_APP_GRAPHQL_URL_BROWSER || '/graphql'}`,
  provisioning: {
    mediusUrl: `${process.env.REACT_APP_MEDIUS_URL}`,
  },
  brightcove: {
    brightcoveId: `${process.env.REACT_APP_BRIGHTCOVE_ID}`,
    videoPlayerId: `${process.env.REACT_APP_BRIGHTCOVE_VIDEO_PLAYER_ID}`,
    playlistPlayerId: `${process.env.REACT_APP_BRIGHTCOVE_PLAYLIST_PLAYER_ID}`,
  },
  externalRegisterUrl: `${
    process.env.EXTERNAL_REGISTER_URL ||
    'https://account.aicpa.org/eweb/dynamicpage.aspx?webcode=CreateAccountCheck&RedirectURL=https://www.aicpa-cima.com/'
  }`,
  vergicUrl: process.env.REACT_APP_VERGIC_URL || null,
  vergicId: process.env.REACT_APP_VERGIC_ID || null,
  isAdminPortal,
  productAccess: {
    oplHidUrl: `${process.env.REACT_APP_OPL_HID_URL}`,
    oplRedirectUrl: `${process.env.REACT_APP_OPL_REDIRECT_URL}`,
  },
  googleTagManagerTag: {
    id: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`,
    conversionLabel: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ADS_CONVERSION_LABEL}`,
  },
  shipStationReturnPortalLink: `${process.env.REACT_APP_SHIPSTATION_RETURN_PORTAL_LINK}`,
  flpPlatformLink: `${process.env.REACT_APP_FLP_PLATFORM_URL}`,
  trustSpotKeyStart: `${process.env.REACT_APP_TRUSTSPOT_KEY_START}`,
  trustSpotKeyEnd: `${process.env.REACT_APP_TRUSTSPOT_KEY_START}`,
  pearsonVueSSOLink: `${process.env.REACT_APP_PV_SSO_URL}`,
  flpPlatformCGMALink: `${process.env.REACT_APP_FLP_PLATFORM_CGMA_URL}`,
  cimaStudyLink: `${process.env.REACT_APP_CIMA_STUDY_URL}`,
  cookieSecretKey: `${process.env.REACT_APP_COOKIE_SECRET_KEY}`,
  paypalUrl: `${process.env.REACT_APP_PAYPAL_AICPA_REDIRECT}`,
  isCimaQpMembership: process.env.REACT_APP_CIMA_QP_MEMBERSHIP === 'true',
  bridgeUrl: `${process.env.BRIDGE_API_URL}`,
  doubleDonationApi: `${process.env.DOUBLE_DONATION_API_KEY}`,
  holdingPageBypass: `${process.env.REACT_APP_HOLDING_PAGE_BYPASS}`,
  paypalAicpaSWIndetifier: `${process.env.REACT_APP_PAYPAL_AICPA_FRAUDNET_SW_IDENTIFIER}`,
  paypalCimaSWIdentifier: `${process.env.REACT_APP_PAYPAL_CIMA_FRAUDNET_SW_IDENTIFIER}`,
  reCAPTCHAKey: `${process.env.REACT_APP_RECAPTCHA_KEY}`,
  blacklistEmailDomains: `${process.env.REACT_APP_BLACKLIST_EMAIL_DOMAINS}`,
  cimaStudyHubLink: `${process.env.REACT_APP_STUDY_HUB}`,
  fcmaPeriodCheckDuration: `${process.env.REACT_APP_FCMA_PERIOD_CHECK_DURATION ?? '90'}`,
};

export interface ServerConfig {
  origin: string;
  graphqlUrl: string;
  okta: {
    publicUrl: string;
    orgUrl: string;
  };
  tealium: {
    account: string;
    profile: string;
    env: string;
  };
  aicpaPublicPassword: string;
  sentryID: string;
  cspOverride: string[];
  requestTimeout: string;
  keepAliveTimeout: string;
  headersTimeout: string;
  googleTagManagerTag: {
    id: string;
  };
  isAdminPortal: boolean;
  trustSpotKeyStart: string;
  trustSpotKeyEnd: string;
}

export const serverConfig: ServerConfig = {
  origin: `${process.env.REACT_APP_OKTA_PUBLIC_URL}`,
  graphqlUrl: `${process.env.REACT_APP_GRAPHQL_URL}`,
  okta: {
    publicUrl: process.env.REACT_APP_OKTA_PUBLIC_URL || '',
    orgUrl: `${process.env.REACT_APP_OKTA_ORG_URL}`,
  },
  tealium: {
    account: `${process.env.TEALIUM_ACCOUNT}`,
    profile: `${process.env.TEALIUM_PROFILE}`,
    env: `${process.env.TEALIUM_ENV}`,
  },
  aicpaPublicPassword: process.env.AICPA_PUBLIC_PASSWORD || '',
  sentryID: `${
    process.env.REACT_APP_SENTRY_DSN
      ? process.env.REACT_APP_SENTRY_DSN.replace('https://', '').replace('@sentry*', '')
      : ''
  }`,
  cspOverride: process.env.CSP_OVERRIDE ? JSON.parse(`${process.env.CSP_OVERRIDE}`) : [],
  requestTimeout: '600',
  keepAliveTimeout: process.env.NODE_KEEP_ALIVE_TIMEOUT_SEC ? process.env.NODE_KEEP_ALIVE_TIMEOUT_SEC : '900',
  headersTimeout: process.env.NODE_HEADERS_TIMEOUT_SEC ? process.env.NODE_HEADERS_TIMEOUT_SEC : '901',
  googleTagManagerTag: {
    id: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`,
  },
  isAdminPortal,
  trustSpotKeyStart: `${process.env.REACT_APP_TRUSTSPOT_KEY_START}`,
  trustSpotKeyEnd: `${process.env.REACT_APP_TRUSTSPOT_KEY_END}`,
};
