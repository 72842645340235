import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components/macro';
import {
  Button as StyledButton,
  ButtonEnums,
  Divider,
  Modal,
  OnlyDesktopCSS,
  OnlyMobileCSS,
  ButtonVariants,
  Link,
  LinkEnums,
} from 'components/atoms';

import { ReactComponent as IconClose } from 'resources/images/ic-close.svg';
import { MembershipTypes } from 'mxp-schemas';
import { isAuthSelector } from 'modules/user/selectors';
import { isAdminPortalSelector } from 'modules/app/selectors';
import { getPath } from 'utils';
import { CurrentJourney, ExtLinks, Routes } from 'constants/index';
import { useHistory } from 'react-router';
import { setIsCredentialsJourney, setMembershipEvent } from 'modules/membership';

interface ModalProps {
  ethicsStatusSelector: MembershipTypes.EthicsStatusEnum;
  hasFirmBillingInvite: boolean;
  isCima?: boolean;
  isUserMember?: boolean;
  currentJourney?: string | undefined;
}

export const ModalEthicsAndFirmBillingInvite: React.FC<ModalProps> = ({
  ethicsStatusSelector,
  hasFirmBillingInvite,
  isCima,
  isUserMember,
  currentJourney,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isAdmin = useSelector(isAdminPortalSelector);
  const isAuth = useSelector(isAuthSelector);
  const isImpersonated = Boolean(isAuth) && isAdmin;

  const hasEthicsCase = ethicsStatusSelector === MembershipTypes?.EthicsStatusEnum?.TERMINATED;

  const toggleSectionsCredentialsJourney = () => {
    dispatch(setIsCredentialsJourney(false));
    dispatch(setMembershipEvent('isClickedSectionsJourney', false));
  };
  const goToPreviousPage = () => {
    history.goBack();
    toggleSectionsCredentialsJourney();
  };

  const onModalCloseBtnClick = () => {
    toggleSectionsCredentialsJourney();
    if (isImpersonated || hasEthicsCase) {
      // TODO: Change Association global homepage (prop page?)
      history.push(getPath(Routes.ROOT));
    } else {
      // TODO: change to CIMA prop page route
      history.push(getPath(Routes.FEED));
    }
  };

  const onUnderstandButtonClicked = () => {
    // TODO: Change Association global homepage (prop page?)
    history.push(getPath(Routes.ROOT));
  };

  const isImpersonatedOrHasEthicsCase = isImpersonated || hasEthicsCase;

  const modalHeading =
    hasEthicsCase && hasFirmBillingInvite
      ? 'Ethics and Firm Billing Invite Violation'
      : hasFirmBillingInvite
      ? 'Pending Invite From Your Employer'
      : currentJourney === CurrentJourney.CREDENTIALS
      ? 'You cannot proceed with this application'
      : 'You cannot proceed with this purchase';

  const modalCimaHeading =
    hasFirmBillingInvite && !isImpersonatedOrHasEthicsCase
      ? 'Pending Invite from Sponsoring Organization'
      : isImpersonatedOrHasEthicsCase
      ? 'It appears that you are unable to proceed.'
      : isUserMember
      ? 'Your membership is a part of a firm billing invoice'
      : 'Pending Invite from Sponsoring Organization';
  // tslint:disable-next-line: no-shadowed-variable

  const renderModalBody = () =>
    hasFirmBillingInvite ? (
      renderDefault(true)
    ) : currentJourney === CurrentJourney.CREDENTIALS ? (
      <CenteredText>
        Your profile has been blocked for ethics issues. Select the chat icon to the right of your screen, or{' '}
        <Link type={LinkEnums.type.inlineLink} isExternal to={ExtLinks.AICPA_CIMA_HELP} testId="ei-question-us-phone">
          visit our help page
        </Link>{' '}
        for frequently asked questions and other contact information.
      </CenteredText>
    ) : (
      <CenteredText>
        You cannot proceed with this purchase, please contact us by selecting the chat icon to the right of your screen,
        or{' '}
        <Link type={LinkEnums.type.inlineLink} isExternal to={ExtLinks.AICPA_CIMA_HELP} testId="ei-question-us-phone">
          visit our help page
        </Link>{' '}
        for frequently asked questions and other contact information.
      </CenteredText>
    );

  const renderCimaModalBody = () =>
    hasFirmBillingInvite && !isImpersonatedOrHasEthicsCase ? (
      renderDefault()
    ) : isImpersonatedOrHasEthicsCase ? (
      <CenteredText>
        <p>Please check the customer’s ethics flags.</p>
        <p>For assistance, please select the chat icon to the right of your screen,</p>
        <p>
          or{' '}
          <a href="https://www.aicpa.org/about/help" target={'_blank'} rel="noopener noreferrer">
            visit our help page
          </a>{' '}
          for frequently asked questions and other contact information.
        </p>
      </CenteredText>
    ) : isUserMember ? (
      <CenteredText>
        <p>If you are still employed with the firm, please contact firmservice@cima.org.</p>
        <p>
          If you are no longer with the firm please update your employment information, then continue to update your
          membership.
        </p>
      </CenteredText>
    ) : (
      renderDefault()
    );
  return (
    <>
      <StyledModal
        size="tiny"
        heading={isCima ? modalCimaHeading : modalHeading}
        open={hasEthicsCase || hasFirmBillingInvite}
      >
        <CloseButton>
          <StyledCloseIcon onClick={isCima ? onModalCloseBtnClick : goToPreviousPage} />
        </CloseButton>
        <Spacer />
        {isCima ? renderCimaModalBody() : renderModalBody()}
        <DividerWithBottomMargin />
        <CenteredButtons>
          <DesktopStyled>
            <UnderstandButton onClick={isCima ? onUnderstandButtonClicked : goToPreviousPage} isCima={isCima} />
          </DesktopStyled>
          <MobileStyled>
            <UnderstandButton onClick={isCima ? onUnderstandButtonClicked : goToPreviousPage} />
          </MobileStyled>
        </CenteredButtons>
      </StyledModal>
    </>
  );
};

const renderDefault = (isAicpa?: boolean) => (
  <CenteredText>
    You cannot proceed with this purchase as you have an invite pending from{' '}
    {isAicpa
      ? 'your employer to sign up for AICPA Membership'
      : 'a sponsoring organization to sign up for the CGMA Professional Qualification'}
    . Please check your email inbox for this invite and sign up using the link within.
    <br />
    <br />
    {isAicpa ? (
      <>
        If you have any questions, please speak with your organization's Admin or{' '}
        <Link type={LinkEnums.type.inlineLink} isExternal to={getPath(Routes.HELP)} testId="ei-question-us-phone">
          contact us
        </Link>{' '}
        us for assistance.
      </>
    ) : (
      <>
        If you wish to decline the invitation from your sponsoring organization, please speak with your designated
        organization Admin or{' '}
        <Link type={LinkEnums.type.inlineLink} isExternal to={getPath(Routes.HELP)} testId="ei-question-us-phone">
          contact us
        </Link>{' '}
        to be removed from the Organization Billing Roster.
      </>
    )}
  </CenteredText>
);

const DividerWithBottomMargin = styled(Divider)`
  &&& {
    margin-top: ${props => props.theme.pxToRem(40)};
    margin-bottom: ${props => props.theme.pxToRem(20)};
  }
`;

const Spacer = styled.div`
  margin-top: ${props => props.theme.pxToRem(10)};
`;

const CenteredText = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} ${props => props.theme.pxToRem(10)}
      ${props => props.theme.pxToRem(10)};
  }
`;
const CenteredButtons = styled.div`
  text-align: center;
  margin: auto;
  padding: 0 ${props => props.theme.pxToRem(30)} 0 ${props => props.theme.pxToRem(30)};
  ${props => props.theme.mediaQueries.mobileOnly} {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

interface UnderstandButtonProps {
  onClick: () => void;
  variant?: ButtonVariants;
  isCima?: boolean;
}

const UnderstandButton: React.FC<UnderstandButtonProps> = ({ onClick, isCima = false }) => (
  <StyledUnderstandButton
    onClick={onClick}
    size={ButtonEnums.sizes.small}
    variant={ButtonEnums.variants.primary}
    testId="understand-ethics-modal"
  >
    {isCima ? 'Got it!' : 'I understand'}
  </StyledUnderstandButton>
);

const StyledUnderstandButton = styled(StyledButton)`
  &&&& {
    padding: 0 ${props => props.theme.pxToRem(10)} 0 ${props => props.theme.pxToRem(10)};
  }
`;

const StyledModal = styled(Modal)`
  &&&&&& {
    .actions {
      ${props => props.theme.mediaQueries.mobileOnly} {
        display: block;
      }
    }

    > .content {
      padding: 0.5rem 0 0 0 !important;
    }

    button {
      width: ${props => props.theme.pxToRem(170)};
      height: ${props => props.theme.pxToRem(34)};
      ${props => props.theme.mediaQueries.ieOnly} {
        font-weight: ${props => props.theme.fontWeights.medium};
      }
    }
  }
`;

const DesktopStyled = styled(OnlyDesktopCSS)`
  &&& {
    margin-right: ${props => props.theme.pxToRem(5)};
    margin-left: ${props => props.theme.pxToRem(5)};
  }
`;

const MobileStyled = styled(OnlyMobileCSS)`
  &&& {
    margin-bottom: ${props => props.theme.pxToRem(10)};
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: ${props => props.theme.pxToRem(10)};
  right: ${props => props.theme.pxToRem(-60)};
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const StyledCloseIcon = styled(IconClose)`
  fill: ${props => props.theme.colors.primaryPurple};
`;
