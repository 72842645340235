import { UserHomepage } from 'constants/index';
import { CONSTANTS } from 'modules/app/constants';
import { constantsSelector } from 'modules/app/selectors';
import { categoriesFetchedSelector } from 'modules/categories/selectors';
import { megamenuFetchedSelector } from 'modules/header/selectors';
import { industriesHashSelector, industriesSelector } from 'modules/industries/selectors';
import { skillsHashSelector, skillsSelector } from 'modules/skills/selectors';
import { getInviteIdSelector } from 'modules/startup/selectors';
import { topicsFetchedSelector, topicsHashSelector, topicsSelector } from 'modules/topics/selectors';
import { trendsHashSelector, trendsSelector } from 'modules/trends/selectors';
import { isPERMgmtLevelFailed, isCPQStratLevelUnlocked } from 'utils/productItemHelpers';
import moment from 'moment-timezone';
import {
  Checkout,
  Contentful,
  MembershipTypes,
  Salesforce,
  SalesforceResponses,
  SmartyStreets,
  User,
  User as UserTypes,
  ZuoraTypes,
  Product,
} from 'mxp-schemas';
import {
  CheckoutCountriesListHash,
  CountriesList,
  User as UserUtils,
  ConvertFromISOAlpha3ToText,
  Admin as AdminUtils,
} from 'mxp-utils';
import { createSelector } from 'reselect';
import { emptyArray } from 'utils/emptyItems';
import { initialState, UserMemberTypes } from './constants';
import { areAllTruthy } from 'utils';
import { isMipRenewalSeasonSelector } from 'modules/mip/selectors';

// ------------------------------------
// Selectors
// ------------------------------------

// TODO - complete for industries, trends and skills

export const userSelector = createSelector(
  (state: State.Root) => state.user,
  (user: State.User): State.User => user
);

export const membershipSelector = createSelector(
  (state: State.Root) => state.membership,
  (membership: State.Membership): State.Membership => membership
);

export const userDataSelector = createSelector(userSelector, (user: State.User): State.Profile => user.data);

export const userLoadingSelector = createSelector(userSelector, (user: State.User): boolean => user.loading);

export const userPrefSelector = createSelector(
  userSelector,
  (user: State.User): State.Preferences => user.preferences || initialState.preferences
);

export const userIndustryPrefSelector = createSelector(
  userSelector,
  (user: State.User): State.industryPreferences => user.industryPreferences || initialState.industryPreferences
);

export const userTrendPrefSelector = createSelector(
  userSelector,
  (user: State.User): State.trendPreferences => user.trendPreferences || initialState.trendPreferences
);

export const userSkillPrefSelector = createSelector(
  userSelector,
  (user: State.User): State.skillPreferences => user.skillPreferences || initialState.skillPreferences
);

export const userHasPrefSelector = createSelector(userPrefSelector, (preferences: State.Preferences): boolean =>
  Boolean(Object.keys(preferences).length)
);

export const userHasIndustryPrefSelector = createSelector(
  userIndustryPrefSelector,
  (industryPreferences: State.industryPreferences): boolean => Boolean(Object.keys(industryPreferences).length)
);

export const userHasTrendPrefSelector = createSelector(
  userTrendPrefSelector,
  (trendPreferences: State.trendPreferences): boolean => Boolean(Object.keys(trendPreferences).length)
);

export const userHasSkillPrefSelector = createSelector(
  userSkillPrefSelector,
  (skillPreferences: State.skillPreferences): boolean => Boolean(Object.keys(skillPreferences).length)
);

export const userTopicsPrefSelector = createSelector(userPrefSelector, (preferences: State.Preferences): string[] =>
  Object.keys(preferences)
);

export const userSubtopicsPrefSelector = createSelector(userPrefSelector, (preferences: State.Preferences): string[] =>
  Object.values(preferences).reduce((acc: string[], subtopics: string[]) => acc.concat(subtopics), [])
);

export const userIndustriesPrefSelector = createSelector(
  userIndustryPrefSelector,
  (industryPreferences: State.industryPreferences): string[] => Object.keys(industryPreferences)
);

export const userTrendsPrefSelector = createSelector(
  userTrendPrefSelector,
  (trendPreferences: State.trendPreferences): string[] => Object.keys(trendPreferences)
);

export const userSkillsPrefSelector = createSelector(
  userSkillPrefSelector,
  (skillPreferences: State.skillPreferences): string[] => Object.keys(skillPreferences)
);

export const userSubskillsPrefSelector = createSelector(
  userSkillPrefSelector,
  (skillPreferences: State.skillPreferences): string[] =>
    Object.values(skillPreferences).reduce((acc: string[], subskills: string[]) => acc.concat(subskills), [])
);

export const userShortcutsSelector = createSelector(
  userSelector,
  (user: State.User): State.Shortcut[] => user.shortcuts
);

export const userMaskedEmailSelector = createSelector(userSelector, (user: State.User): string => user.forgotEmail);
export const userPlatformSelector = createSelector(
  userSelector,
  (user: State.User): UserTypes.UserPlatform | null => user.platform
);

export const userEmailResetPassSendSuccessSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.resetEmailSendSuccess
);

export const userPasswordUpdatedSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.userPasswordUpdated
);

export const userShortcutsUrlsSelector = createSelector(
  userShortcutsSelector,
  (shortcuts: State.Shortcut[]): string[] => {
    return shortcuts
      ? shortcuts.map((item: State.Shortcut) => `${item.categorySlug || ''}${item.topicSlug}${item.subtopicSlug || ''}`)
      : [''];
  }
);

export const userErrorSelector = createSelector(userSelector, (user: State.User): State.UserError | null => user.error);

export const userTopicsSelector = createSelector(
  [userTopicsPrefSelector, topicsHashSelector],
  (topicsPref: string[], topicsHash: State.TopicsHash | null): State.TopicOption[] | [] => {
    if (!topicsPref || !topicsHash) return emptyArray;
    const topics = topicsPref.reduce((acc: State.TopicOption[], id: string) => {
      const topic: State.Topic = topicsHash[id];
      // topic from user db can be deleted from contentful
      if (topic) acc.push({ id, name: topic.name, description: topic.description, slug: topic.slug });
      return acc;
    }, []);
    return topics.sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const userIndustriesSelector = createSelector(
  [userIndustriesPrefSelector, industriesHashSelector],
  (industriesPref: string[], industriesHash: State.IndustriesHash | null): State.IndustryOption[] | [] => {
    if (!industriesPref || !industriesHash) return emptyArray;
    const industries = industriesPref.reduce((acc: State.IndustryOption[], id: string) => {
      const industry: State.Industry = industriesHash[id];
      // industry from user db can be deleted from contentful
      if (industry) acc.push({ id, name: industry.name, description: industry.description, slug: industry.slug });
      return acc;
    }, []);
    return industries.sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const userTrendsSelector = createSelector(
  [userTrendsPrefSelector, trendsHashSelector],
  (trendsPref: string[], trendsHash: State.TrendsHash | null): State.TrendOption[] | [] => {
    if (!trendsPref || !trendsHash) return emptyArray;
    const trends = trendsPref.reduce((acc: State.TrendOption[], id: string) => {
      const trend: State.Trend = trendsHash[id];
      // trend from user db can be deleted from contentful
      if (trend) acc.push({ id, name: trend.name, description: trend.description, slug: trend.slug });
      return acc;
    }, []);
    return trends.sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const userSkillsSelector = createSelector(
  [userSkillsPrefSelector, skillsHashSelector],
  (skillsPref: string[], skillsHash: State.SkillsHash | null): State.SkillOption[] | [] => {
    if (!skillsPref || !skillsHash) return emptyArray;
    const skills = skillsPref.reduce((acc: State.SkillOption[], id: string) => {
      const skill: State.Skill = skillsHash[id];
      // skill from user db can be deleted from contentful
      if (skill) acc.push({ id, name: skill.name, description: skill.description, slug: skill.slug });
      return acc;
    }, []);
    return skills.sort((a, b) => a.name.localeCompare(b.name));
  }
);

export const netforumProfileSelector = createSelector(
  userSelector,
  (user: State.User): State.NetforumProfile => user.netforumProfile
);

export const marketingPreferencesErrorSelector = createSelector(
  userSelector,
  (user: State.User): State.UserError | null => user.marketingPreferences.error
);

export const marketingPreferencesSelector = createSelector(
  userSelector,
  (user: State.User): State.MarketingPreferences => user.marketingPreferences.preferences
);

export const marketingPreferencesLoadingSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.marketingPreferences.loading
);

export const isDataExistSelector = createSelector(
  [userSelector, topicsFetchedSelector, categoriesFetchedSelector, megamenuFetchedSelector],
  ({ isAuth, userFetched, goldenRecordFetched }, topicsFetched, categoriesFetched, megamenuFetched): boolean => {
    const logoutUserDataFetched: boolean = topicsFetched && categoriesFetched && megamenuFetched;
    const loginUserDataFetched: boolean = logoutUserDataFetched && userFetched && goldenRecordFetched;
    // return result based on isAuth boolean
    return isAuth ? loginUserDataFetched : logoutUserDataFetched;
  }
);

export const isAuthSelector = createSelector([userSelector], ({ isAuth }: State.User): boolean | null => isAuth);

export const userNameSelector = createSelector(
  userDataSelector,
  (data: State.Profile): string => `${data.firstName} ${data.lastName}`
);

export const userFirstNameSelector = createSelector(userDataSelector, (data: State.Profile): string => data.firstName);

export const userLastNameSelector = createSelector(userDataSelector, (data: State.Profile): string => data.lastName);

export const userEmailSelector = createSelector(userDataSelector, (data: State.Profile): string => data.email);

export const userRolesSelector = createSelector(
  userDataSelector,
  (data: State.Profile): UserTypes.MembershipIdsEnum[] => data.roles
);

export const clientAdminOrgIdsSelector = createSelector(
  userDataSelector,
  (data: State.Profile): string[] => data.clientAdminOf
);

export const isUserMemberSelector = createSelector(
  userRolesSelector,
  (roles: UserTypes.MembershipIdsEnum[] | undefined): boolean =>
    Boolean(
      roles?.length &&
        (roles?.includes(UserTypes.MembershipIdsEnum.MRUSR0001) ||
          roles?.includes(UserTypes.MembershipIdsEnum.MRUKR0001))
    )
);

export const isUserCredentialHolderSelector = createSelector(
  userRolesSelector,
  (roles: UserTypes.MembershipIdsEnum[] | undefined): boolean =>
    Boolean(roles?.length && roles?.some(role => UserUtils.credentialMembershipRoles.includes(role)))
);

export const isUserFirmMemberSelector = createSelector(
  userRolesSelector,
  (roles: UserTypes.MembershipIdsEnum[] | undefined): boolean =>
    Boolean(roles?.length && roles?.some(role => UserUtils.firmMembershipRoles.includes(role)))
);

export const isUserMemberSuspendedSelector = createSelector(
  userDataSelector,
  (data: State.Profile): boolean => data.memberStatus === MembershipTypes.MembershipState.OVERDUE
);

export const isUserSuspendedSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.personAccountData.ethicsStatus === MembershipTypes.EthicsStatusEnum.SUSPENDED
);

export const isClientAdminSelector = createSelector(clientAdminOrgIdsSelector, (orgIds): boolean =>
  Boolean(orgIds?.length)
);

export const firmBillingClientAdminSelector = createSelector(
  userDataSelector,
  (data: State.Profile): string[] => data.firmBillingClientAdminOf || emptyArray
);

export const isFirmBillingClientAdminSelector = createSelector(
  firmBillingClientAdminSelector,
  (firmBillingClientAdminOf: string[]): boolean => Boolean(firmBillingClientAdminOf?.length)
);

export const centersClientAdminSelector = createSelector(
  userDataSelector,
  (data: State.Profile): string[] => data.centersClientAdminOf || emptyArray
);

export const isCentersClientAdminSelector = createSelector(
  centersClientAdminSelector,
  (centersClientAdminOf: string[]): boolean => Boolean(centersClientAdminOf?.length)
);

const tuitionProviderClientAdminOfSelector = createSelector(
  userDataSelector,
  (data: State.Profile): string[] => data.tuitionProviderClientAdminOf || emptyArray
);

export const isTuitionProviderClientAdminSelector = createSelector(
  tuitionProviderClientAdminOfSelector,
  (tuitionProviderClientAdminOf: string[]): boolean => Boolean(tuitionProviderClientAdminOf?.length)
);

export const userSelectedUnselectedTopicSelector = createSelector(
  [topicsSelector, userTopicsPrefSelector],
  (topics: State.Topic[] | null, userTopics: string[]) => {
    const ret: { selected: Common.TopicIF[]; unselected: Common.TopicIF[] } = {
      selected: [],
      unselected: [],
    };
    if (topics) {
      topics.forEach((item: State.Topic) => {
        const subtopics: State.Subtopic[] = Object.values(item.subtopics);
        const itemIsInUserTopics: boolean = userTopics.includes(item.id);
        ret[itemIsInUserTopics ? 'selected' : 'unselected'].push({ ...item, subtopics });
      });
    }
    return ret;
  }
);

export const userSelectedUnselectedIndustriesSelector = createSelector(
  [industriesSelector, userIndustriesPrefSelector],
  (industries: State.Industry[] | null, userIndustries: string[]) => {
    const ret: { selected: Common.IndustryIF[]; unselected: Common.IndustryIF[] } = {
      selected: [],
      unselected: [],
    };
    if (industries) {
      industries.forEach((item: State.Industry) => {
        const itemIsInUserIndustries: boolean = userIndustries.includes(item.id);
        ret[itemIsInUserIndustries ? 'selected' : 'unselected'].push({ ...item });
      });
    }
    return ret;
  }
);

export const userSelectedUnselectedTrendsSelector = createSelector(
  [trendsSelector, userTrendsPrefSelector],
  (trends: State.Trend[] | null, userTrends: string[]) => {
    const ret: { selected: Common.TrendIF[]; unselected: Common.TrendIF[] } = {
      selected: [],
      unselected: [],
    };
    if (trends) {
      trends.forEach((item: State.Trend) => {
        const itemIsInUserTrends: boolean = userTrends.includes(item.id);
        ret[itemIsInUserTrends ? 'selected' : 'unselected'].push({ ...item });
      });
    }
    return ret;
  }
);

export const userSelectedUnselectedSkillSelector = createSelector(
  [skillsSelector, userSkillsPrefSelector],
  (skills: State.Skill[] | null, userSkills: string[]) => {
    const ret: { selected: Common.SkillIF[]; unselected: Common.SkillIF[] } = {
      selected: [],
      unselected: [],
    };
    if (skills) {
      skills.forEach((item: State.Skill) => {
        const subskills: State.Subskill[] = Object.values(item.subskills);
        const itemIsInUserSkills: boolean = userSkills.includes(item.id);
        ret[itemIsInUserSkills ? 'selected' : 'unselected'].push({ ...item, subskills });
      });
    }
    return ret;
  }
);

export const userSectionMembershipSelector = createSelector(
  userRolesSelector,
  (roles?: UserTypes.MembershipIdsEnum[]): any[] => {
    const userHasRoles: boolean = Boolean(roles && roles.length);
    const sectionMemberships: Common.IExternalLinks[] = [];
    if (!userHasRoles) return sectionMemberships;
    roles!.forEach((role: UserTypes.MembershipIdsEnum) => {
      const trimmedRole: string = role.trim();
      if (
        UserUtils.sectionMembershipMap[trimmedRole] &&
        !sectionMemberships.find(membership => membership.title === UserUtils.sectionMembershipMap[trimmedRole].title)
      ) {
        const roleData: Common.IExternalLinks = {
          title: UserUtils.sectionMembershipMap[trimmedRole].title,
          link: UserUtils.sectionMembershipMap[trimmedRole].link,
        };
        sectionMemberships.push(roleData);
      }
    });
    return sectionMemberships.sort((a, b) => a.title.localeCompare(b.title));
  }
);

export const userFirmMembershipSelector = (firmMembershipsHash: State.FirmMembershipsHash) =>
  createSelector(userRolesSelector, (roles?: UserTypes.MembershipIdsEnum[]): any[] => {
    const userHasRoles: boolean = Boolean(roles && roles.length);
    const firmMemberships: Common.IExternalLinks[] = [];
    if (!userHasRoles) return firmMemberships;
    roles!.forEach((role: UserTypes.MembershipIdsEnum) => {
      const trimmedRole: string = role.trim();
      if (
        UserUtils.firmMembershipMap[trimmedRole] &&
        !firmMemberships.find(membership => membership.title === UserUtils.firmMembershipMap[trimmedRole].title)
      ) {
        const firmMembershipLink = firmMembershipsHash[trimmedRole]?.propPageLink;
        const roleData: Common.IExternalLinks = {
          title: UserUtils.firmMembershipMap[trimmedRole].title,
          link: firmMembershipLink,
        };
        firmMemberships.push(roleData);
      }
    });
    return firmMemberships.sort((a, b) => a.title.localeCompare(b.title));
  });

export const userHasChangedPreferencesSelector = createSelector(
  userSelector,
  (user: State.User): boolean => JSON.stringify(user.preferences) !== JSON.stringify(user.previousPreferences)
);

export const userHasChangedSkillPreferencesSelector = createSelector(
  userSelector,
  (user: State.User): boolean => JSON.stringify(user.skillPreferences) !== JSON.stringify(user.previousSkillPreferences)
);

export const userFeedbackValuesSelector = createSelector(
  userSelector,
  (user: State.User): State.UserFeedback | null => user.feedback
);

export const userSessionInvalidated = createSelector(
  userSelector,
  (user: State.User): boolean => user.invalidatedSession
);

export const userMemberTypeSelector = createSelector(
  [isAuthSelector, userRolesSelector],
  (isAuth: boolean | null, userRoles: UserTypes.MembershipIdsEnum[]): UserMemberTypes => {
    // logged out user
    if (!isAuth) {
      return UserMemberTypes.LOGGED_OUT;
    }

    // premium user
    if (userRoles.length > 1) {
      return UserMemberTypes.PREMIUM;
    }

    // member user
    const isMembershipInRoles: boolean =
      userRoles.includes(UserTypes.MembershipIdsEnum.MRUSR0001) ||
      userRoles.includes(UserTypes.MembershipIdsEnum.MRUKR0001);
    if (userRoles.length === 1 && isMembershipInRoles) {
      return UserMemberTypes.MEMBER;
    }

    // registered user
    return UserMemberTypes.NONMEMBER;
  }
);

export const userFVSSectionMemberSelector = createSelector(
  userRolesSelector,
  (roles: UserTypes.MembershipIdsEnum[] | undefined): UserTypes.MembershipIdsEnum[] | [] =>
    (roles || emptyArray).filter(
      (role: UserTypes.MembershipIdsEnum) =>
        role === UserTypes.MembershipIdsEnum.MRUSR0018 || role === UserTypes.MembershipIdsEnum.MRUSR0019
    )
);

export const userTaxSectionMemberSelector = createSelector(
  userRolesSelector,
  (roles: UserTypes.MembershipIdsEnum[] | undefined): UserTypes.MembershipIdsEnum[] | [] =>
    (roles || emptyArray).filter(
      (role: UserTypes.MembershipIdsEnum) =>
        role === UserTypes.MembershipIdsEnum.MRUSR0002 || role === UserTypes.MembershipIdsEnum.MRUSR0003
    )
);

export const userNFPSectionMemberSelector = createSelector(
  userRolesSelector,
  (roles: UserTypes.MembershipIdsEnum[] | undefined): UserTypes.MembershipIdsEnum[] | [] =>
    (roles || emptyArray).filter(
      (role: UserTypes.MembershipIdsEnum) =>
        role === UserTypes.MembershipIdsEnum.MRUSR0033 || role === UserTypes.MembershipIdsEnum.MRUSR0034
    )
);

export const userPFPSectionMemberSelector = createSelector(
  userRolesSelector,
  (roles: UserTypes.MembershipIdsEnum[] | undefined): UserTypes.MembershipIdsEnum[] | [] =>
    (roles || emptyArray).filter(
      (role: UserTypes.MembershipIdsEnum) =>
        role === UserTypes.MembershipIdsEnum.MRUSR0004 || role === UserTypes.MembershipIdsEnum.MRUSR0005
    )
);

export const isUserSectionMemberSelector = createSelector(
  userFVSSectionMemberSelector,
  userTaxSectionMemberSelector,
  userPFPSectionMemberSelector,
  userNFPSectionMemberSelector,
  (
    fvsMembership: UserTypes.MembershipIdsEnum[] | undefined,
    taxMembership: UserTypes.MembershipIdsEnum[] | undefined,
    pfpMembership: UserTypes.MembershipIdsEnum[] | undefined,
    nfpMembership: UserTypes.MembershipIdsEnum[] | undefined
  ): boolean =>
    Boolean(
      (fvsMembership || emptyArray).length > 0 ||
        (taxMembership || emptyArray).length > 0 ||
        (pfpMembership || emptyArray).length > 0 ||
        (nfpMembership || emptyArray).length > 0
    )
);

export const userOktaIdSelector = createSelector(userSelector, (user: State.User): string => user.data.userId);

export const userAicpaUidSelector = createSelector(userSelector, (user: State.User): string => user.data.AICPAUID);

export const userAuthStatusSelector = createSelector([userSelector], ({ isAuth }: State.User): string =>
  isAuth === null ? UserHomepage.UNKNOWN : isAuth ? UserHomepage.LOGGED_IN : UserHomepage.LOGGED_OUT
);

export const isLicensedCPASelector = createSelector(
  userSelector,
  (user: State.User): boolean | null => user.cpaStatus.isLicensedCPA
);

export const cpaStatusFetchedSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.cpaStatus.cpaStatusFetched
);

export const cpaStatusLoadingSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.cpaStatus.loading
);

export const personAccountDataSelector = createSelector(
  userSelector,
  (user: State.User): State.PersonAccountData => user.personAccountData
);

export const EPA2SubmissionsSelector = createSelector(
  userSelector,
  (user: State.User): State.User['EPA2Submissions'] => user.EPA2Submissions
);

export const EPA2L7SubmissionsSelector = createSelector(
  userSelector,
  (user: State.User): State.User['EPA2L7Submissions'] => user.EPA2L7Submissions
);

export const customerMembershipsSelector = createSelector(
  userSelector,
  (user: State.User): Salesforce.FirmMembership[] => user.memberships
);

export const isInLatestCycleSelector = createSelector(
  customerMembershipsSelector,
  (customerMemberships: Salesforce.FirmMembership[]) => {
    const currentDate = moment();
    const latestMembershipTermDate = customerMemberships?.[0]?.allTerms?.[0].expiryDate;

    // Magic number is 11 to get the startDate of cycle based on latest end date
    const startDateOfCycle = moment(latestMembershipTermDate).subtract(11, 'months').startOf('month');
    const endDateOfCycle = moment(latestMembershipTermDate);

    // We are only allowed to upgrade within current cycle to prevent renewing during upgrade
    const isInLatestCycle = UserUtils.conditionalFunction(
      latestMembershipTermDate,
      // currentDate is in range of start and end date of latest cycle
      currentDate.isSameOrAfter(startDateOfCycle) && currentDate.isSameOrBefore(endDateOfCycle),
      false // no membership found
    );

    return isInLatestCycle;
  }
);

export const aicpaMembershipSelector = createSelector(
  customerMembershipsSelector,
  (memberships: Salesforce.FirmMembership[]) => {
    const hasMembership = Boolean(memberships?.length);
    if (hasMembership) {
      const results = (memberships || emptyArray).filter(
        (membership: Salesforce.FirmMembership) => membership.membershipBody === MembershipTypes.MembershipBody.AICPA
      );
      return results[0] || {};
    }
  }
);

export const cimaMembershipSelector = createSelector(
  customerMembershipsSelector,
  (memberships: Salesforce.FirmMembership[]) => {
    const hasMembership = Boolean(memberships?.length);
    if (hasMembership) {
      const results = (memberships || emptyArray).filter(
        (membership: Salesforce.FirmMembership) => membership.membershipBody === MembershipTypes.MembershipBody.CIMA
      );
      return results[0] || {};
    }
  }
);

export const userHasMultipleTermsSelector = createSelector(
  customerMembershipsSelector,
  (memberships: Salesforce.FirmMembership[]) => {
    // if the  customerMemberships[0].allTerms has more than one record it means it has done renewal before
    const hasMembership = Boolean(memberships?.length);
    if (hasMembership && memberships[0]?.allTerms !== undefined) {
      return memberships[0]?.allTerms.length > 1;
    }
    return false;
  }
);

export const userMembershipBodySelector = createSelector(
  customerMembershipsSelector,
  (memberships: Salesforce.FirmMembership[]) => {
    const hasMembership = Boolean(memberships?.length);
    if (hasMembership && memberships[0]?.allTerms !== undefined) {
      return memberships[0]?.membershipBody;
    }
  }
);

export const cimaMembershipsTermSelector = createSelector(
  cimaMembershipSelector,
  (membership: any): any => membership?.membershipTerm
);

export const cimaMembershipsTermTypeSelector = createSelector(
  cimaMembershipSelector,
  (membership: any): any => membership?.membershipTerm?.membershipTermType
);

export const cimaMembershipsProductIdSelector = createSelector(
  cimaMembershipSelector,
  (membership: any): string => membership?.membershipTerm?.productId
);

export const isCimaCandidateOrAffiliateSelector = createSelector(
  cimaMembershipsTermTypeSelector,
  (membershipType: any): boolean =>
    membershipType === MembershipTypes.MembershipKeys.AFFILIATE ||
    membershipType === MembershipTypes.MembershipKeys.CANDIDATE
);

export const cimaMembershipsTermTierSelector = createSelector(
  cimaMembershipSelector,
  (membership: any): any => membership?.membershipTerm?.membershipTermTier
);

export const cimaMembershipTermSelector = createSelector(cimaMembershipSelector, (membership: any): any => {
  return membership?.allTerms;
});

export const aicpaMembershipTermSelector = createSelector(aicpaMembershipSelector, (membership: any): any => {
  return membership?.allTerms;
});

export const cimaMembershipTermIsTenYearsSelector = createSelector(
  cimaMembershipTermSelector,
  constantsSelector,
  (membershipTerms: any, constant: null | Contentful.Constants.Constants): boolean => {
    if (membershipTerms !== undefined) {
      // Will get terms with regular membership and sort them depending on startdate
      const regularMembership = membershipTerms
        ?.filter((item: any) => item?.membershipTermType === 'Regular')
        .sort((a: any, b: any) => {
          return a.startDate.localeCompare(b.startDate);
        });

      // Will get the user number of years membership years
      const diff = moment().diff(regularMembership[0]?.startDate, 'years');

      return diff >= constant?.[CONSTANTS.CIMA_CONTINUOUS_REGULAR_MEMBERSHIP_FCMA_CHECK];
    }
    return false;
  }
);

export const customerInactiveMembershipsSelector = createSelector(
  userSelector,
  (user: State.User): Salesforce.FirmMembership[] => user.inactiveMemberships
);

export const cimaInactiveMembershipSelector = createSelector(
  customerInactiveMembershipsSelector,
  (memberships: Salesforce.FirmMembership[]) => {
    const hasMembership = Boolean(memberships?.length);
    if (hasMembership) {
      const results = (memberships || emptyArray).filter(
        (membership: Salesforce.FirmMembership) => membership.membershipBody === MembershipTypes.MembershipBody.CIMA
      );
      return results[0] || {};
    }
  }
);

export const inactiveMembershipsTermTypeSelector = createSelector(
  customerInactiveMembershipsSelector,
  (inactiveMembership: Salesforce.FirmMembership[]): any =>
    (inactiveMembership?.[0] || {})?.membershipTerm?.membershipTermType ||
    (inactiveMembership?.[0] || {})?.inactiveTerms?.[0]?.membershipTermType
);

export const inactiveMembershipTermSelector = createSelector(
  customerInactiveMembershipsSelector,
  (inactiveMembership: Salesforce.FirmMembership[]): any =>
    (inactiveMembership?.[0] || {})?.membershipTerm || (inactiveMembership?.[0] || {})?.inactiveTerms?.[0]
);

export const inactiveMembershipsBodySelector = createSelector(
  customerInactiveMembershipsSelector,
  (inactiveMembership: Salesforce.FirmMembership[]): any => (inactiveMembership?.[0] || {})?.membershipBody
);

export const customerInactiveSectionsSelector = createSelector(
  userSelector,
  (user: State.User): Salesforce.Section[] => user.inactiveSections
);

export const customerSectionsSelector = createSelector(
  userSelector,
  (user: State.User): Salesforce.Section[] => user.sections
);

export const customerInactiveCredentialsSelector = createSelector(
  userSelector,
  (user: State.User): Salesforce.Credential[] => user.inactiveCredentials
);

export const customerSuspendedCredentialsSelector = createSelector(
  userSelector,
  (user: State.User): Salesforce.Credential[] => user.suspendedCredentials
);

export const customerCredentialsSelector = createSelector(
  userSelector,
  (user: State.User): Salesforce.Credential[] => user.credentials
);

export const fcmaSfCredentialSelector = createSelector(
  [customerCredentialsSelector, customerInactiveCredentialsSelector],
  (activeCredentials: State.Credential[], inactiveCredentials: State.Credential[]): State.Credential | undefined => {
    // Check Active Credential First if does not exist, Check Inactive Credentials
    const hasActiveFcmaCredential = activeCredentials?.some(item => item.sku === Product.CIMA_CREDENTIALS_SKU.FCMA);
    if (hasActiveFcmaCredential) {
      return activeCredentials?.find(item => item.sku === Product.CIMA_CREDENTIALS_SKU.FCMA);
    }
    const hasInActiveFcmaCredential = inactiveCredentials?.some(item => item.sku === Product.CIMA_CREDENTIALS_SKU.FCMA);
    if (hasInActiveFcmaCredential) {
      return inactiveCredentials?.find(item => item.sku === Product.CIMA_CREDENTIALS_SKU.FCMA);
    }
  }
);

export const credentialSinceDateACMASelector = createSelector(
  customerCredentialsSelector,
  (credentialData: State.Credential[]): Salesforce.Credential['since'] =>
    credentialData?.find(item => item.sku.includes(Product.CredentialKey.ACMA))?.since || ''
);

export const customerOrderHistorySelector = createSelector(
  userSelector,
  (user: State.User): SalesforceResponses.ConsolidatedOrder[] => user.customerOrderHistory
);

export const customerProfileFetchedSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.profileFetched
);

export const customerProfileFetchSuccessSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.profileFetchedSuccess
);

export const personAccountUpdateLoadingSelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): boolean | undefined => person.personAccountDataUpdateLoading || undefined
);

export const personAccountUpdateSuccessSelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): boolean | undefined => person.personAccountDataUpdateSuccess || undefined
);

export const personAccountCountrySelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): string | undefined => person.address.country
);

export const isAddressValidSelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): boolean | undefined => {
    return person.isAddressValid;
  }
);

export const personAccountSSValidationReasonSelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): SmartyStreets.ValidationReason | null =>
    (person.smartystreetsValidation?.shippingAddress?.reason as SmartyStreets.ValidationReason) || null
);

export const personAccountSSSuggestionsSelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): SmartyStreets.ValidationCandidate[] =>
    person.smartystreetsValidation?.shippingAddress?.suggestions || []
);

export const personAccountSSValidationFetchedSelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): boolean => Boolean(person.smartystreetsValidation?.smartystreetsValidationFetched)
);

export const personAccountIsSecondaryAddressNeededSelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): boolean => Boolean(person.isSecondaryAddressNeeded)
);

export const personAccountSmartystreetsValidationSkippedSelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): boolean => Boolean(person.smartystreetsValidationSkipped)
);

export const personAccountIsHonorarySelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): boolean => Boolean(person.isHonorary)
);
export const personAccountIsCPASelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): boolean => Boolean(person.isCPA)
);

export const isAddressValidationLoadingSelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): boolean => Boolean(person.isAdressValidationLoading)
);

export const ethicsCaseSelector = createSelector(
  userSelector,
  (user: State.User): MembershipTypes.EthicsStatusEnum => user.personAccountData.ethicsStatus
);

export const firmBillingInviteSelector = createSelector(userSelector, (user: State.User): boolean =>
  Boolean(user.hasFirmBillingInvite)
);

export const quoteStatusAndIsMembershipPaidByFirmSelector = createSelector(
  userSelector,
  (user: State.User): State.QuotesStatusAndPaidByFirm | null => user.quoteStatusAndIsMembershipPaidByFirm
);

export const hasFirmBillingRelationSelector = createSelector(
  userSelector,
  (user: State.User): boolean | undefined => user.hasFirmBillingRelation
);

export const hasCimaFirmBillingRelationSelector = createSelector(
  userSelector,
  (user: State.User): boolean | undefined => user.hasCimaFirmBillingRelation
);

export const hasActiveQuoteFromFirmSelector = createSelector(
  userSelector,
  (user: State.User): boolean | undefined => user.hasActiveQuoteFromFirm
);

export const userUnpaidFirmBillingInvoices = createSelector(
  userSelector,
  (user: State.User): ZuoraTypes.InvoiceWithOwner[] => user.userUnpaidFirmBillingInvoices
);

export const showEthicsAndFirmBillingSelector = createSelector(
  [userSelector, getInviteIdSelector, membershipSelector],
  (user: State.User, inviteId: string, membership: State.Membership): boolean =>
    user.personAccountData.ethicsStatus === MembershipTypes.EthicsStatusEnum.TERMINATED ||
    (Boolean(user.hasFirmBillingInvite) &&
      !inviteId &&
      (membership?.isRenewalsJourney
        ? Boolean(user.hasFirmBillingRelation) || Boolean(user.hasCimaFirmBillingRelation)
        : true))
);

// selector for ethics violation only
export const userHasEthicsViolationSelector = createSelector(
  [userSelector],
  (user: State.User): boolean => user.personAccountData.ethicsStatus === MembershipTypes.EthicsStatusEnum.TERMINATED
);

export const userHasEthicsViolationOnListSelector = createSelector([userSelector], (user: State.User): boolean => {
  const userHasEthicsViolation = [
    MembershipTypes.EthicsStatusEnum.UNDER_INVESTIGATION,
    MembershipTypes.EthicsStatusEnum.TERMINATED,
    MembershipTypes.EthicsStatusEnum.SANCTIONED,
    MembershipTypes.EthicsStatusEnum.REMEDIATION,
    MembershipTypes.EthicsStatusEnum.SUSPENDED,
  ].some(violation => violation === user.personAccountData.ethicsStatus);

  return userHasEthicsViolation;
});

export const qualificationDataSelector = createSelector(
  userSelector,
  (user: State.User): State.QualificationData => user.qualificationData
);

export const cpaLicenseListDataSelector = createSelector(
  qualificationDataSelector,
  (qualificationData: State.QualificationData): State.CPALicenseListData => qualificationData.cpaLicenseListData
);

// This selector checking if the value of the licenseNumber is equal to default value which is "000"
// if true it will display empty string else it will display the updated value of the licenseNumber.
export const cpaLicenseCertificateNumberSelector = createSelector(qualificationDataSelector, () => {
  return (cpaLicenseData: Salesforce.CPALicense[]) => {
    const licenseCertificateNumbers = (cpaLicenseData || []).map((value: Salesforce.CPALicense) => {
      if (value?.licenseNumber === '000') {
        return '';
      }
      return value?.licenseNumber;
    });
    return [...licenseCertificateNumbers];
  };
});

export const cpaLicenseDataSelector = createSelector(
  qualificationDataSelector,
  (qualificationData: State.QualificationData): State.CPALicenseData => qualificationData.cpaLicenseData
);

export const cpaLicenseDataSelectorUpdateLoadingSelector = createSelector(
  cpaLicenseDataSelector,
  (user: State.CPALicenseData): boolean | undefined => user.cpaLicenseDataUpdateLoading || undefined
);

export const employmentDataSelector = createSelector(
  userSelector,
  (user: State.User): State.EmploymentData => user.employmentData
);

export const employmentDataUpdateLoadingSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): boolean => employmentData.employmentDataUpdateLoading
);

export const employmentDataUpdateSuccessSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): boolean => employmentData.employmentDataUpdateSuccess
);

export const employerAlreadyExistsSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): boolean => employmentData.employerAlreadyExists
);

export const employersSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): Array<Omit<State.AccountPersonAccount, 'personAccount'>> =>
    employmentData.employers
);

export const employersWithEmployeeRelationshipSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): Array<Omit<State.AccountPersonAccount, 'personAccount'>> => {
    return (
      employmentData?.employers?.filter(
        (data: Omit<State.AccountPersonAccount, 'personAccount'>) =>
          data.relationshipType === MembershipTypes.RelationshipTypeEnum.EMPLOYEE
      ) || []
    );
  }
);

export const employersWithStudentRelationshipSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): Array<Omit<State.AccountPersonAccount, 'personAccount'>> => {
    return employmentData?.employers?.filter(
      (data: Omit<State.AccountPersonAccount, 'personAccount'>) =>
        data.relationshipType === MembershipTypes.RelationshipTypeEnum.STUDENT &&
        data.organization?.tuitionProviderType === Salesforce.TuitionProviderType.NONE
    );
  }
);

export const studentTuitionProviderSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): Array<Omit<State.AccountPersonAccount, 'personAccount'>> => {
    return employmentData.employers?.filter(
      (data: Omit<State.AccountPersonAccount, 'personAccount'>) =>
        data.relationshipType === MembershipTypes.RelationshipTypeEnum.STUDENT
    );
  }
);

export const primaryEmployerSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): Omit<State.AccountPersonAccount, 'personAccount'> | null => {
    return (
      employmentData.employers?.find((data: Omit<State.AccountPersonAccount, 'personAccount'>) => data.isPrimary) ||
      null
    );
  }
);

export const newEmployerSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): State.NewAccountPersonAccount => employmentData.newEmployer
);

export const newEmployerJobTitleSelector = createSelector(
  newEmployerSelector,
  (newEmployer: State.NewAccountPersonAccount): string => newEmployer.jobTitle
);

// get training provider info from redux store
export const newTrainingProviderSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): State.NewAccountPersonAccount => employmentData.newTrainingProvider
);

export const employerIsInUSSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): boolean | undefined => {
    return employmentData?.employers
      ?.filter(employer => employer.status === 'Active')
      ?.some(
        activeEmployer =>
          (activeEmployer.organization?.billingAddress?.country || '') ===
            CheckoutCountriesListHash.USA.ISOAlpha3Code ||
          (activeEmployer.organization?.profileEmployerAddress?.country || '') === CheckoutCountriesListHash.USA.text
      );
  }
);

export const isActiveEmployerSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): boolean | undefined => {
    return employmentData?.employers?.some(employer => employer.status === 'Active');
  }
);

export const personAccountIdSelector = createSelector(
  personAccountDataSelector,
  (user: State.PersonAccountData): string => user.id
);

export const searchOrganizationsByWildcardLoadingSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): boolean => employmentData.searchOrganizationsByWildcardLoading
);

export const searchResultOrganizationsSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData) => employmentData.searchResultOrganizations
);

export const searchOrganizationsCitiesLoadingSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): boolean => employmentData.searchOrganizationsCitiesLoading
);

export const searchResultOrganizationsCitiesSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData) => employmentData.searchResultOrganizationsCities
);

const candidateDataSelector = createSelector(
  qualificationDataSelector,
  (qualificationData: State.QualificationData) => qualificationData.candidateData
);

export const searchResultUniversitiesSelector = createSelector(
  candidateDataSelector,
  (candidateData: State.CandidateData) => candidateData.searchResultUniversities
);

export const searchUniversitiesLoadingSelector = createSelector(
  candidateDataSelector,
  (candidateData: State.CandidateData): boolean | undefined => candidateData.searchUniversitiesLoading || undefined
);

export const candidateUniversitySelector = createSelector(
  candidateDataSelector,
  (candidateData: State.CandidateData) => candidateData.university
);

export const employmentDataFromPromoFetchedSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): boolean => employmentData.employmentDataFromPromoFetched
);

export const employmentDataFromPromoFetchSuccessSelector = createSelector(
  employmentDataSelector,
  (employmentData: State.EmploymentData): boolean => employmentData.employmentDataFromPromoFetchSuccess
);

export const applicationSelector = createSelector(
  userSelector,
  (user: State.User): State.Application => user.application
);

export const tlwSelector = createSelector(userSelector, (user: State.User): State.TLWInfoList => user.tlw);

export const applicationInitialLoadSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.applicationInitialLoad
);

export const applicationPartSelector = createSelector(
  applicationSelector,
  (application: State.Application): State.ApplicationPart => application.applicationPart
);

export const applicationPartsSelector = createSelector(
  applicationSelector,
  (application: State.Application): State.ApplicationPart[] => application?.applicationParts || []
);

export const isOrganizationAddressValidSelector = createSelector(
  employmentDataSelector,
  (employment: State.EmploymentData): boolean | undefined => {
    return employment.isAddressValid;
  }
);

export const organizationSSValidationReasonSelector = createSelector(
  employmentDataSelector,
  (employment: State.EmploymentData): SmartyStreets.ValidationReason | null =>
    (employment.smartystreetsValidation?.shippingAddress?.reason as SmartyStreets.ValidationReason) || null
);

export const organizationSSSuggestionsSelector = createSelector(
  employmentDataSelector,
  (employment: State.EmploymentData): SmartyStreets.ValidationCandidate[] =>
    employment.smartystreetsValidation?.shippingAddress?.suggestions || []
);

export const organizationSSValidationFetchedSelector = createSelector(
  employmentDataSelector,
  (employment: State.EmploymentData): boolean =>
    Boolean(employment.smartystreetsValidation?.smartystreetsValidationFetched)
);

export const organizationIsSecondaryAddressNeededSelector = createSelector(
  employmentDataSelector,
  (employment: State.EmploymentData): boolean => Boolean(employment.isSecondaryAddressNeeded)
);

export const organizationSmartystreetsValidationSkippedSelector = createSelector(
  employmentDataSelector,
  (employment: State.EmploymentData): boolean => Boolean(employment.smartystreetsValidationSkipped)
);

export const isOrganizationAddressValidationLoadingSelector = createSelector(
  employmentDataSelector,
  (employment: State.EmploymentData): boolean => Boolean(employment.isAdressValidationLoading)
);

export const isEmploymentPageSkippedSelector = createSelector(
  employmentDataSelector,
  (employment: State.EmploymentData): boolean => Boolean(employment.isEmploymentPageSkipped)
);

export const personAddressSelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): State.SalesforceAddress => person.address
);

export const addressBookSelector = createSelector(userSelector, (user: State.User): State.SalesforceAddress[] => {
  const allAddresses = user?.allAddresses;
  if (Array.isArray(allAddresses)) {
    return allAddresses.filter(address => Boolean(![null, ''].includes(address.addressLine1))) || [];
  }
  return [];
});

export const addressBookPrimarySelector = createSelector(
  addressBookSelector,
  (address: State.SalesforceAddress[]): State.SalesforceAddress | undefined => {
    if (address?.length > 1) {
      const primaryAddress = address.find((location: State.SalesforceAddress) => location.isPrimary);
      return primaryAddress;
    }
  }
);

export const attestationSelector = createSelector(userSelector, (user: State.User): string[] => user.attestation);
// Center Membership Application Selectors
export const centerMembershipApplicationSelector = createSelector(
  userSelector,
  (user: State.User): State.CenterMembershipApplication => user.centerMembershipApplication
);

export const sfmcLinkSelector = createSelector(userSelector, (user: State.User): string => user.sfmcLink);

export const centerMembershipApplicationObjectSelector = createSelector(
  centerMembershipApplicationSelector,
  (centerMembershipApplication: State.CenterMembershipApplication): State.Application =>
    centerMembershipApplication.applicationObject
);

export const centerMembershipPackageOrganizationSelector = createSelector(
  centerMembershipApplicationSelector,
  (centerMembershipApplication: State.CenterMembershipApplication): State.Organization & State.UserChoiceOrganization =>
    centerMembershipApplication.organization
);

export const centerMembershipApplicationAdminDetailSelector = createSelector(
  centerMembershipApplicationSelector,
  (centerMembershipApplication: State.CenterMembershipApplication): State.UserChoiceAdminDetail =>
    centerMembershipApplication.adminDetail
);

export const centerMembershipPackageOrganizationIdSelector = createSelector(
  centerMembershipPackageOrganizationSelector,
  (organization: State.UserChoiceOrganization): string => organization.id
);

const userPartnerEmailSelector = createSelector(
  personAccountDataSelector,
  (person: State.PersonAccountData): string => person.secondaryEmail.emailAddress || ''
);

export const userPartnerDetailSelector = createSelector(
  [personAccountDataSelector, userPartnerEmailSelector],
  (personAccount: State.PersonAccountData, email: string): State.UserChoiceAdminDetail => ({
    id: personAccount.id,
    firstName: personAccount.firstName,
    lastName: personAccount.lastName,
    email: personAccount.primaryEmail.emailAddress || personAccount.secondaryEmail.emailAddress,
  })
);

export const securityQuestionDataSelector = createSelector(
  userSelector,
  (user: State.User): State.SecurityQuestionState => user.securityQuestion
);

// cima student progression
export const studentProgressionSelector = createSelector(
  userSelector,
  (user: State.User): State.StudentProgression => user.studentProgression
);

export const exemptionsStatusSelector = createSelector(
  studentProgressionSelector,
  (studentProgression: State.StudentProgression): State.StudentProgression['exemptionsStatus'] =>
    studentProgression?.exemptionsStatus || null
);

export const hasMultipleUpcomingExamBooking = createSelector(
  studentProgressionSelector,
  (studentProgression: State.StudentProgression): boolean =>
    Boolean(studentProgression?.upcomingExamBooking?.length > 0)
);

export const getStudentExamSectionResultsSelector = createSelector(
  userSelector,
  (user: State.User): State.StudentExamSectionResults => user.studentExamSectionResults
);

export const qualificationLevelsSelector = createSelector(
  studentProgressionSelector,
  (studentProgression: State.StudentProgression): State.StudentProgression['qualificationLevels'] =>
    studentProgression?.qualificationLevels
);

export const gatewayStatusSelector = createSelector(
  studentProgressionSelector,
  (studentProgression: State.StudentProgression): State.StudentProgression['gatewayStatus'] | undefined =>
    studentProgression?.gatewayStatus
);

export const allowPqwithL4OrL7toSwitchSelector = createSelector(
  studentProgressionSelector,
  (progression: State.StudentProgression): boolean | undefined => progression?.allowPqwithL4OrL7toSwitch
);

export const upcomingExamBookingSelector = createSelector(
  studentProgressionSelector,
  (studentProgression: State.StudentProgression): State.StudentProgression['upcomingExamBooking'] =>
    studentProgression?.upcomingExamBooking
);

export const learningPathwayToSwitchSelector = createSelector(
  studentProgressionSelector,
  (studentProgression: State.StudentProgression): State.StudentProgression['learningPathwayToSwitch'] =>
    studentProgression?.learningPathwayToSwitch
);

export const examsSelector = createSelector(userSelector, (user: State.User): State.ExamResponse[] => user.exams);

export const examsPassSelector = createSelector(
  userSelector,
  (user: State.User): State.ExamResponse[] => user?.exams?.filter(exam => exam.SFS_EXA_ExamPassedFlag__c) || []
);

export const epa2L4CaseStatusUpdateSelector = createSelector(
  userSelector,
  (user: State.User): State.EPA2L4CaseStatusUpdate => user.epa2L4CaseStatusUpdate
);

export const epa2L7CaseStatusUpdateSelector = createSelector(
  userSelector,
  (user: State.User): State.EPA2L7CaseStatusUpdate => user.epa2L7CaseStatusUpdate
);

export const specialConsiderationsExamsSelector = createSelector(
  userSelector,
  (user: State.User): State.SpecialConsiderationsExams => user.specialConsiderationsExams
);

export const createSpecialConsiderationSelector = createSelector(
  userSelector,
  (user: State.User): State.SpecialConsideration => user.specialConsiderationInfo
);

export const createSpecialConsiderationLoadingSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.createSpecialConsiderationLoading
);

export const createSpecialConsiderationSuccessSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.createSpecialConsiderationSuccess
);

export const learningPathwaySelector = createSelector(
  personAccountDataSelector,
  (user: State.PersonAccountData): MembershipTypes.Pathway | null | undefined => user?.learningPathway
);

export const pathwaySwitchedSelector = createSelector(
  studentProgressionSelector,
  (progression: State.StudentProgression): boolean => progression?.isPathwaySwitched
);

export const eventRegistrationDataSelector = createSelector(
  userSelector,
  (user: State.User): State.EventRegistration => user.eventRegistrationData
);

export const eventDataFetchedSuccessSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.eventDataFetchedSuccess
);

export const createEventDataSuccessSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.createEventRegistrationSuccess
);

export const createEventDataLoadingSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.createEventRegistrationLoading
);

export const zuoraCutOverDateSelector = createSelector(
  userSelector,
  (user: State.User): string => user.zuoraCutOverDate
);

export const userLocationSelector = createSelector(
  userSelector,
  (user: State.User): State.UserLocation => user.userLocation
);

export const isUserCountryEmbargoedSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.isUserCountryEmbargoed
);

export const registrationLoadingSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.registrationLoading
);

const hasActiveCimaMembershipSelector = createSelector(
  customerMembershipsSelector,
  (membership: Salesforce.FirmMembership[]): boolean =>
    membership.some(
      item =>
        (item.status === MembershipTypes.MembershipState.ACTIVE ||
          item.status === MembershipTypes.MembershipState.GRACEPERIOD ||
          item.status === MembershipTypes.MembershipState.SUSPENDED) &&
        item?.membershipBody === MembershipTypes.MembershipBody.CIMA
    )
);

const hasActiveAicpaMembershipSelector = createSelector(
  customerMembershipsSelector,
  (membership: Salesforce.FirmMembership[]): boolean =>
    membership.some(
      item =>
        (item.status === MembershipTypes.MembershipState.ACTIVE ||
          item.status === MembershipTypes.MembershipState.GRACEPERIOD ||
          item.status === MembershipTypes.MembershipState.SUSPENDED) &&
        item?.membershipBody === MembershipTypes.MembershipBody.AICPA
    )
);

// for lapsed accounts that previously purchased AICPA products so we can determine the right journey when they decide to buy again.
export const hasInactiveCIMAMembershipBodySelector = createSelector(
  customerInactiveMembershipsSelector,
  (membership: Salesforce.FirmMembership[]): boolean =>
    membership.some(item => item?.membershipBody === MembershipTypes.MembershipBody.CIMA)
);

export const isCimaMemberSelector = createSelector(
  [isUserMemberSelector, isUserMemberSuspendedSelector, hasActiveCimaMembershipSelector],
  (isUserMember: boolean, isUserMemberSuspended: boolean, hasActiveCimaMem: boolean): boolean | undefined =>
    (isUserMember || isUserMemberSuspended) && hasActiveCimaMem
);

export const isAicpaMemberSelector = createSelector(
  [isUserMemberSelector, isUserMemberSuspendedSelector, hasActiveAicpaMembershipSelector],
  (isUserMember: boolean, isUserMemberSuspended: boolean, hasActiveAicpaMem: boolean): boolean | undefined =>
    (isUserMember || isUserMemberSuspended) && hasActiveAicpaMem
);

export const isStrategicLevelQualificationCompletedSelector = createSelector(
  qualificationLevelsSelector,
  (qualificationLevels: User.QualificationLevel[]): boolean => {
    const strategicLevelQualification = qualificationLevels?.filter(
      level => level.name === MembershipTypes.CimaQualificationLevelPathValue.StrategicLevel
    );
    return (
      strategicLevelQualification[0]?.status === User.QualificationLevelStatus.Completed ||
      strategicLevelQualification[0]?.blocks[0]?.status === User.SubjectBlockStatus.Exempted
    );
  }
);

export const currentJourneyLearningPathwaySelector = createSelector(
  userSelector,
  (user: State.User): string =>
    user.currentJourneyLearningPathway || user.application.applicationPart.currentLearningPathway || ''
);

export const isChinesePathwaySignUpJourneySelector = createSelector(
  [isUserMemberSelector, currentJourneyLearningPathwaySelector],
  (isUserMember: boolean, currentLearningPathway: string): boolean =>
    !isUserMember && AdminUtils.isChinesePathway(currentLearningPathway)
);

export const isEpaLevel7QualificationCompletedSelector = createSelector(
  qualificationLevelsSelector,
  (qualificationLevels: User.QualificationLevel[]): boolean => {
    const EPAQualification = qualificationLevels?.filter(
      level => level.name === MembershipTypes.CimaQualificationLevelPathValue.EPA7
    );
    return (
      EPAQualification[0]?.blocks[0]?.status === User.SubjectBlockStatus.Completed ||
      EPAQualification[0]?.blocks[0]?.status === User.SubjectBlockStatus.Exempted
    );
  }
);

export const epaLevel4Epa2ProductIdSelector = createSelector(
  qualificationLevelsSelector,
  (qualificationLevels: User.QualificationLevel[]): any => {
    const EPA2L4Block = qualificationLevels?.filter(
      level => level.name === MembershipTypes.CimaQualificationLevelPathValue.EPA4
    );

    const examCreditNameL4 = EPA2L4Block?.[0]?.blocks?.find(
      val => val.examRecordType === Salesforce.ExamRecordType.EPA2
    )?.name;
    // added fetching of EPA2 L4 name to match with product list in case there was no product id in qualification level
    return {
      epa2L4ProductId: EPA2L4Block?.[0]?.blocks?.find(val => val.examRecordType === Salesforce.ExamRecordType.EPA2)
        ?.productId,
      epa2L4Name: examCreditNameL4?.replace(/\s/g, '-').toLowerCase(),
    };
  }
);

export const epa2Level7ProductIdSelector = createSelector(
  qualificationLevelsSelector,
  (qualificationLevels: User.QualificationLevel[]): any => {
    const EPA2L7Block = qualificationLevels?.filter(
      level => level.name === MembershipTypes.CimaQualificationLevelPathValue.EPA7
    );

    const examCreditNameL7 = EPA2L7Block?.[0]?.blocks?.find(
      val => val.examRecordType === Salesforce.ExamRecordType.EPA2
    )?.name;
    // added fetching of EPA2 L4 name to match with product list in case there was no product id in qualification level
    return {
      epa2L7ProductId: EPA2L7Block?.[0]?.blocks?.find(val => val.examRecordType === Salesforce.ExamRecordType.EPA2)
        ?.productId,
      epa2L7Name: examCreditNameL7?.replace(/\s/g, '-').toLowerCase(),
    };
  }
);

export const isOperationalQualificationCompletedSelector = createSelector(
  qualificationLevelsSelector,
  (qualificationLevels: User.QualificationLevel[]): boolean => {
    const EPAQualification = qualificationLevels?.filter(
      level => level.name === MembershipTypes.CimaQualificationLevelPathValue.OperationalLevel
    );
    return (
      EPAQualification[0]?.blocks[0]?.status === User.SubjectBlockStatus.Completed ||
      EPAQualification[0]?.blocks[0]?.status === User.SubjectBlockStatus.Exempted
    );
  }
);

export const isManagementQualificationCompletedSelector = createSelector(
  qualificationLevelsSelector,
  (qualificationLevels: User.QualificationLevel[]): boolean => {
    const EPAQualification = qualificationLevels?.filter(
      level => level.name === MembershipTypes.CimaQualificationLevelPathValue.ManagementLevel
    );
    return (
      EPAQualification[0]?.blocks[0]?.status === User.SubjectBlockStatus.Completed ||
      EPAQualification[0]?.blocks[0]?.status === User.SubjectBlockStatus.Exempted
    );
  }
);

export const isCimaCertBaQualificationCompletedSelector = createSelector(
  qualificationLevelsSelector,
  (qualificationLevels: User.QualificationLevel[]): boolean => {
    const EPAQualification = qualificationLevels?.filter(
      level => level.name === MembershipTypes.CimaQualificationLevelPathValue.CertBa
    );
    return (
      EPAQualification[0]?.blocks[0]?.status === User.SubjectBlockStatus.Completed ||
      EPAQualification[0]?.blocks[0]?.status === User.SubjectBlockStatus.Exempted
    );
  }
);

export const isEPA1CompletedSelector = createSelector(
  qualificationLevelsSelector,
  (qualificationLevels: User.QualificationLevel[]): boolean => {
    const EPA1 = qualificationLevels
      ?.find(level => level.name === MembershipTypes.CimaQualificationLevelPathValue.StrategicLevel)
      ?.blocks.find(block => block.type === User.SubjectBlockType.CaseStudy);
    return EPA1?.status === User.SubjectBlockStatus.Completed;
  }
);

export const activeQualificationLevelSelector = createSelector(
  qualificationLevelsSelector,
  (qualificationLevels: User.QualificationLevel[]): number => {
    const activeQualificationLevelExams: any = Boolean(qualificationLevels?.length)
      ? qualificationLevels
          ?.find((qualification: any) => qualification?.status === User.QualificationLevelStatus.Active)
          ?.blocks.filter((exam: any) => !exam?.name?.includes('Case Study Exam'))
      : [];
    const completedOrExemptedExams: any =
      Boolean(activeQualificationLevelExams?.length) &&
      activeQualificationLevelExams.filter(
        (exam: any) =>
          exam?.status === User.SubjectBlockStatus.Completed || exam?.status === User.SubjectBlockStatus.Exempted
      );
    const activeQualification = Boolean(qualificationLevels?.length)
      ? qualificationLevels?.find(
          (qualification: any) => qualification?.status === User.QualificationLevelStatus.Active
        )
      : null;
    if (
      Boolean(activeQualificationLevelExams?.length) &&
      Boolean(completedOrExemptedExams?.length) &&
      activeQualificationLevelExams?.length === completedOrExemptedExams?.length
    ) {
      return Number(activeQualification?.entryPointLevel) + 1;
    }

    return Number(activeQualification?.entryPointLevel);
  }
);

export const isEPA2CompletedSelector = createSelector(
  qualificationLevelsSelector,
  (qualificationLevels: User.QualificationLevel[]): boolean => {
    const EPA2 = qualificationLevels
      ?.find(level => level.name === MembershipTypes.CimaQualificationLevelPathValue.EPA7)
      ?.blocks.find(block => block.examRecordType === Salesforce.ExamRecordType.EPA2);
    return EPA2?.status === User.SubjectBlockStatus.Completed;
  }
);

export const isFirmAICPAAffiliatedSelector = createSelector(
  centerMembershipPackageOrganizationSelector,
  (centerMembershipPackageOrganization: State.Organization & State.UserChoiceOrganization): boolean =>
    centerMembershipPackageOrganization.isAICPAaffiliated || false
);

export const crossOverMembershipsTermTypeSelector = createSelector(
  aicpaMembershipSelector,
  (membership: any): any => membership?.membershipTerm?.membershipTermType
);

export const hasPreferredCurrencySelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.hasPreferredCurrency
);

export const userTierSelector = createSelector(
  userSelector,
  (user: State.User): Checkout.Tiers =>
    CountriesList.find(country => country.key === user?.userLocation?.country)?.tier || Checkout.Tiers.TIER1
);

export const allAddressesSelector = createSelector(
  userSelector,
  (user: State.User): State.SalesforceAddress[] => user?.allAddresses || []
);

export const primaryAddressSelector = createSelector(
  userSelector,
  (user: State.User): State.SalesforceAddress | undefined => {
    const allAddresses = user?.allAddresses;
    if (Array.isArray(allAddresses)) {
      const primaryAddress = user?.allAddresses?.find((addr: State.SalesforceAddress) => addr.isPrimary);
      return primaryAddress;
    }
  }
);

export const isUserAffiliateSelector = createSelector(userSelector, (user: State.User): boolean => {
  const activeMembership = user?.memberships?.find(
    (membership: Salesforce.FirmMembership) => membership.status === MembershipTypes.MembershipState.ACTIVE
  );

  return (
    user?.data?.memberType === User.MembershipTypes.AFFILIATE ||
    activeMembership?.membershipTerm?.membershipTermType === User.MembershipTypes.AFFILIATE
  );
});

export const membershipsTermTypeSelector = createSelector(
  customerMembershipsSelector,
  (membership: any): any => membership?.[0]?.membershipTerm?.membershipTermType
);

export const isUserStudyingSelector = createSelector(
  personAccountDataSelector,
  (personAccountData: Salesforce.PersonAccount): boolean => {
    const currentYear = moment().format('YYYY');
    return parseInt(currentYear, 10) < parseInt(personAccountData?.yearOfGraduation as string, 10);
  }
);

export const isConditionalExemptionStudentSelector = createSelector(
  personAccountDataSelector,
  (personAccountData: Salesforce.PersonAccount): boolean | undefined => personAccountData?.conditionalExemptionStudent
);

export const isUserMemberLapsedSelector = createSelector(userDataSelector, (data: State.Profile): boolean =>
  [
    MembershipTypes.MembershipState.LAPSED,
    MembershipTypes.MembershipState.RESIGNED,
    MembershipTypes.MembershipState.TERMINATED,
  ].includes(
    (data.memberStatus as MembershipTypes.MembershipState) || (data.memberType as MembershipTypes.MembershipState)
  )
);

export const personChosenExemptionLevelSelector = createSelector(
  personAccountDataSelector,
  (personAccountData: Salesforce.PersonAccount): string => personAccountData?.chosenExemptionLevel || ''
);

export const isPrimaryAddressAmericasSelector = createSelector(
  [primaryAddressSelector, personAccountDataSelector],
  (primaryAddress: State.SalesforceAddress | undefined, personAccountData: State.PersonAccountData): boolean =>
    CountriesList.find(
      country =>
        country.text === ConvertFromISOAlpha3ToText(primaryAddress?.country || personAccountData.address?.country || '')
    )?.isAmerica || false
);

export const examSyllabusSelector = createSelector(userSelector, (user: State.User): string[] => {
  return user.allExamResults?.syllabus?.results || [];
});

export const isSyllabusLoadingSelector = createSelector(userSelector, (user: State.User): boolean => {
  return user.allExamResults?.syllabus?.loading || false;
});

export const allExamResultsSelector = createSelector(userSelector, (user: State.User): User.AllStudentExamResults[] => {
  return user.allExamResults?.allExams?.results || [];
});

export const isAllExamResultLoadingSelector = createSelector(userSelector, (user: State.User): boolean => {
  return user.allExamResults?.allExams?.loading || false;
});

export const isStudentProgressionFetchedSelector = createSelector(
  userSelector,
  (user: State.User): boolean => user.studentProgression.isFetched || false
);

export const isApprenticeL7ForUpgradeSelector = createSelector(
  [learningPathwaySelector, cimaMembershipsTermTypeSelector, isEPA1CompletedSelector, isEPA2CompletedSelector],
  (
    learningPathway: MembershipTypes.Pathway | null | undefined,
    cimaMembershipsTermType: string,
    isEPA1Completed: boolean,
    isEPA2Completed: boolean
  ): boolean => {
    const isApprenticeL7 = learningPathway === MembershipTypes.Pathway.APPRENTICE_L7;
    const isCandidate = cimaMembershipsTermType === MembershipTypes.MembershipKeys.CANDIDATE;
    const isL7CandidateUpgrade = isApprenticeL7 && isCandidate && isEPA1Completed && isEPA2Completed;
    return isL7CandidateUpgrade || false;
  }
);

export const cpaLicenseQualificationDataSelector = createSelector(
  qualificationDataSelector,
  (qualificationData: any): State.CPALicenseData => qualificationData.cpaLicenseListData
);

export const isUnitedKingdomSelector = createSelector(
  [personAccountCountrySelector, addressBookSelector],
  (countrySelector: string | undefined, addressBook: State.SalesforceAddress[]): boolean => {
    return (
      countrySelector === CheckoutCountriesListHash.GBR.text ||
      countrySelector === CheckoutCountriesListHash.GBR.ISOAlpha3Code ||
      addressBook?.some(
        (address: State.SalesforceAddress) =>
          (address?.country === CheckoutCountriesListHash.GBR.text && address?.isPrimary) ||
          (address?.country === CheckoutCountriesListHash.GBR.ISOAlpha3Code && address?.isPrimary)
      )
    );
  }
);

export const hasUserPerManagementLevelSelector = createSelector(
  [studentProgressionSelector, learningPathwaySelector],
  (studentProgression: State.StudentProgression, learningPathway: any): boolean => {
    const isFailed = isPERMgmtLevelFailed(studentProgression);
    const isPathwayValid = [
      MembershipTypes.Pathway.PQ,
      MembershipTypes.Pathway.FLP,
      MembershipTypes.Pathway.CHINESE_PQ,
    ].includes(learningPathway);
    return !isFailed && isPathwayValid;
  }
);

export const userHasPermissionForLevelPQAndFLPSelector = createSelector(
  [studentProgressionSelector, personAccountDataSelector],
  (studentProgression: State.StudentProgression, personAccount: State.PersonAccountData): boolean => {
    const isFailed = !isPERMgmtLevelFailed(studentProgression);
    const isPathwayValid: boolean = [MembershipTypes.Pathway.PQ, MembershipTypes.Pathway.FLP].includes(
      personAccount.learningPathway as MembershipTypes.Pathway
    );
    return isFailed && isPathwayValid;
  }
);

export const userHasPermissionForLevelChinesePQSelector = createSelector(
  [studentProgressionSelector, personAccountDataSelector],
  (studentProgression: State.StudentProgression, personAccount: State.PersonAccountData): boolean => {
    // will only access the PER once Chinese PQ Strategic level become active or unlocked
    const isPQStrategicLevelUnlock = isCPQStratLevelUnlocked(studentProgression);
    const isPathwayValid: boolean = [MembershipTypes.Pathway.CHINESE_PQ].includes(
      personAccount.learningPathway as MembershipTypes.Pathway
    );
    return isPQStrategicLevelUnlock && isPathwayValid;
  }
);

export const inactiveMembershipEntitySelector = createSelector(
  userSelector,
  (user: State.User): Salesforce.LegalEntity => {
    const findMembershipBody = user.inactiveMemberships?.[0]?.membershipBody ?? user.memberships?.[0]?.membershipBody;
    return findMembershipBody === MembershipTypes.MembershipBody.AICPA
      ? Salesforce.LegalEntity.ASSOCIATION
      : Salesforce.LegalEntity.CIMA;
  }
);

export const isFlpToPqSwitchSelector = createSelector(
  learningPathwaySelector,
  currentJourneyLearningPathwaySelector,
  (learningPathway: MembershipTypes.Pathway | null | undefined, currentLearningPathway: string): boolean => {
    const isSubscribedToFlp = AdminUtils.isFlpPathway(learningPathway as string);
    const hasSelectedCimaPq = AdminUtils.isCimaPqPathway(currentLearningPathway);
    return areAllTruthy(isSubscribedToFlp, hasSelectedCimaPq);
  }
);

export const isMIPCredentialForRenewalSelector = createSelector(
  customerCredentialsSelector,
  isMipRenewalSeasonSelector,
  (credentials: State.User['credentials'], isMipRenewalSeason: boolean): boolean => {
    const mipCredential = credentials?.find(credential => credential?.sku === MembershipTypes.CimaMip.MIP);
    if (mipCredential) {
      const currentDate = moment();
      const latestCredentialTermDate = mipCredential?.credentialTerms?.[0]?.expiryDate;

      // Magic number is 11 to get the startDate of cycle based on latest end date
      const startDateOfCycle = moment(latestCredentialTermDate).subtract(11, 'months').startOf('month');
      const endDateOfCycle = moment(latestCredentialTermDate);

      const isInLatestCycle = UserUtils.conditionalFunction(
        latestCredentialTermDate,
        // currentDate is in range of start and end date of latest cycle
        areAllTruthy(currentDate.isSameOrAfter(startDateOfCycle), currentDate.isSameOrBefore(endDateOfCycle)),
        false // no mip credenital found
      );

      return isMipRenewalSeason && isInLatestCycle;
    }
    return false;
  }
);
