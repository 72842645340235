import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { AddressActionNames } from './constants';
import { profileCacheAddressSelector, baseCacheAddressSelector } from './selectors';
import { updateUserAddressList } from '../user/actions';
import request from 'utils/GraphQLClient';
import { ADD_ADDRESS, DELETE_ADDRESS } from 'mxp-graphql-queries';
import { Salesforce, SmartyStreets } from 'mxp-schemas';
import { CheckoutCountriesTextToISOAlpha3CodeMap } from 'mxp-utils';
import { toggleModalAddressValidationOpen } from 'modules/layouts';
import { getSmartyStreetsValidation, parseAddressResponses } from 'modules/checkout/helpers';
import { addressBookSelector } from '../user/selectors';

export const convertCountryToCode: any = (address: State.Address) => {
  if (!address?.country) {
    return address;
  }
  return {
    ...address,
    country: CheckoutCountriesTextToISOAlpha3CodeMap[address?.country] || address?.country,
  };
};

// ------------------------------------
// Actions
// ------------------------------------
export const addSalesforceAddress: any = createAction(
  AddressActionNames.ADD_ADDRESS,
  (
      address: Salesforce.ContactPointAddress,
      isPrimary: boolean,
      addressType: Salesforce.HomeAddressType,
      firstName = '',
      lastName = '',
      company = ''
    ) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state = getState();
      const addressBook = addressBookSelector(state);
      const newAddress = { ...address, isPrimary, addressType, firstName, lastName, company };
      return request(ADD_ADDRESS, { address: newAddress }).then((response: any) => {
        const updatedAddress = { id: response?.addAddress?.id, ...newAddress };
        if (isPrimary) {
          // if address is primary clear set all addresses to isPrimary false
          const newAddressBook = addressBook.map((data: any) => ({ ...data, isPrimary: false }));
          return [...newAddressBook, updatedAddress];
        }
        return [...addressBook, updatedAddress];
      });
    }
);

export const updateProfileCacheAddress = createAction(
  AddressActionNames.SET_PROFILE_CACHE_ADDRESS,
  (address: Partial<State.Address>) => convertCountryToCode(address)
);

export const saveNewAddress: any = createAction(
  AddressActionNames.SAVE_NEW_ADDRESS,
  () => async (dispatch: Dispatch, getState: () => State.Root) => isAddressProcessedChecker(dispatch, getState())
);

export const deleteAddress: any = createAction(
  AddressActionNames.DELETE_ADDRESS,
  (id: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(addressLoading());

    const response = await request(DELETE_ADDRESS, { id });
    if (response?.deleteAddress === true) {
      // Will trigger list update
      dispatch(updateUserAddressList(id));
    }
    return { ...response, deletedId: id };
  }
);

const isAddressProcessedChecker = async (dispatch: Dispatch, state: State.Root) => {
  const address: State.Address = baseCacheAddressSelector(state);
  const fullShippingAddress: Salesforce.ContactPointAddress = profileCacheAddressSelector(state);

  const response = await dispatch(getAddressFormValidation(address));

  const responseBase = response.payload?.smartystreetsValidation;
  const scenario = await parseAddressResponses(
    async (zuoraAddress, sfAddress) => {
      await dispatch(
        addSalesforceAddress(
          sfAddress,
          fullShippingAddress.isPrimary,
          fullShippingAddress.addressType,
          fullShippingAddress.firstName,
          fullShippingAddress.lastName,
          fullShippingAddress.company
        )
      );
    },
    responseBase.shippingAddress,
    responseBase.validationNeeded,
    false
  );

  if (
    scenario !== SmartyStreets.ValidationCheckoutToggle.INVALID &&
    scenario !== SmartyStreets.ValidationCheckoutToggle.SECONDARY &&
    scenario === SmartyStreets.ValidationCheckoutToggle.MODAL
  ) {
    dispatch(toggleModalAddressValidationOpen(true));
  }

  if (scenario === SmartyStreets.ValidationCheckoutToggle.SECONDARY) {
    await dispatch(setSecondaryAddressNeeded(true));
    dispatch(setSavedAddressEdited(true));
  }
  return response.payload?.isShippingAddressValid;
};

export const getAddressFormValidation: any = createAction(
  AddressActionNames.GET_COMPLETE_ADDRESS_VALIDATION,
  (address: State.Address) => async () => {
    return getSmartyStreetsValidation(address);
  }
);
export const setSecondaryAddressNeeded: any = createAction(AddressActionNames.SET_SECONDARY_ADDRESS_NEEDED);
export const setSavedAddressEdited: any = createAction(AddressActionNames.SET_SAVED_SHIPPING_ADDRESS_EDIT);
export const addressLoading: any = createAction(AddressActionNames.LOADING);
