import { publicEncrypt } from 'crypto';
import { User as UserTypes } from 'mxp-schemas';

export const getPasswordBufferString = (value: string, pubKey: string) => {
  const buffer = publicEncrypt(
    {
      key: pubKey, // returned from graphql
      padding: 4,
      oaepHash: 'sha1',
    },
    Buffer.from(value, 'utf8')
  );

  return buffer.toString('base64');
};

export const isTokenProblemError = (error: any) =>
  error?.errorCode === UserTypes.UserErrorCodes.RESET_PASS_TOKEN_INCORRECT ||
  error?.errorCode === UserTypes.UserErrorCodes.RESET_PASS_TOKEN_EXPIRED;
