import { handleActions, Action, combineActions } from 'redux-actions';
import { initialState } from './constants';
import {
  loading,
  createZuoraOrder,
  getLocalCheckout,
  goToOrderErrorPage,
  setShippingAddress,
  setBillingAddress,
  setSelectedCreditCardId,
  toggleSameShippingAddressForBilling,
  toggleSameShippingAddressForPayment,
  toggleSavedShippingAddressChecked,
  toggleSavedBillingAddressChecked,
  getZuoraAccount,
  getOrderPreview,
  getMembershipOrderPreview,
  getRefundMembershipSubscriptionPreview,
  proceedToOrderPreview,
  updateZuoraAccount,
  goToPaymentAction,
  getAddressAutocompleteItems,
  clearAddressAutocompleteItems,
  toggleIsLoadingAutocompleteItems,
  toggleSaveCreditCardForFuture,
  resetModule,
  setConferenceUserInfo,
  setCheckoutPage,
  getConferenceUserInfo,
  setDefaultPaymentMethod,
  removePaymentMethod,
  getHostedPageSignature,
  getMultiHostedPageSignature,
  setSavedCardUsed,
  sendCreditCardMail,
  setSecondaryAddressNeeded,
  toggleSubscriptionRenewal,
  getReceipt,
  getReceiptLoading,
  goToReceiptCheckoutErrorPage,
  payInvoice,
  getReceiptPaymentConfirmationResultLoading,
  getReceiptPaymentConfirmationResult,
  resetReceiptPaymentConfirmationData,
  getPaymentById,
  resetOfflineVerify,
  setSavedShippingAddressEdited,
  setPaymentTechCodeError,
  setSavedBillingAddressEdited,
  setShippingEmbargoEdited,
  setBillingEmbargoEdited,
  setSecondaryBillingAddressNeeded,
  getCompleteAddressFormValidation,
  getPaymentsIdByAccountId,
  setSelectedPaymentMethodEntity,
  setBypassValidation,
  setBypassValidationBilling,
  createMandate,
  createPaypalPaymentMethod,
  resetSalesTax,
  resetZuoraAccount,
  getContentfulAttestations,
  setPaypalSessionIdentifier,
} from './actions';
import { setSessionStorageItem, removeSessionStorageItem, emptyObject } from 'utils';
import { StorageNames } from 'constants/index';
import { Checkout, ZuoraTypes, Orders } from 'mxp-schemas';
import { getSavedShippingBillingAddresses, Response } from './helpers';
import { cardsCompare, getCardById, getDefaultCardOrAny } from 'utils/CreditCardHelpers';
import { CheckoutCountriesListHash, ConvertFromTextToISOAlpha3 } from 'mxp-utils';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [getContentfulAttestations]: (state: State.Checkout, action: any): State.Checkout => ({
    ...state,
    mandate: action.payload,
  }),
  [loading]: (state: State.Checkout, action: any | true): State.Checkout => ({
    ...state,
    loading: action.payload === undefined ? true : action.payload,
  }),
  [getLocalCheckout]: (state: State.User, action: any): State.User => ({
    ...state,
    ...(action.payload || emptyObject),
  }),
  [createMandate]: (state: State.Checkout, action: { payload: boolean }): State.Checkout => ({
    ...state,
    loading: false,
  }),
  [createPaypalPaymentMethod]: (
    state: State.Checkout,
    action: Action<{ legalEntity: string; success: boolean }>
  ): State.Checkout => ({
    ...state,
    legalEntity: action.payload.legalEntity,
    addPaypalSuccess: action.payload.success,
  }),

  [setPaypalSessionIdentifier]: {
    next: (state: State.Checkout, action: { payload: string }): State.Checkout => {
      return {
        ...state,
        paypalSessionIdentifier: action.payload,
      };
    },
  },

  [setBypassValidation]: {
    next: (state: State.Checkout, action: { payload: boolean }): State.Checkout => {
      return {
        ...state,
        shippingAddress: {
          ...state.shippingAddress,
          bypassValidation: action.payload,
        },
      };
    },
  },
  [setBypassValidationBilling]: {
    next: (state: State.Checkout, action: { payload: boolean }): State.Checkout => {
      return {
        ...state,
        billingAddress: {
          ...state.billingAddress,
          bypassValidation: action.payload,
        },
      };
    },
  },

  [setShippingAddress]: (state: State.Checkout, action: Action<Partial<State.Address>>): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      loading: false,
      shippingAddress: {
        ...state.shippingAddress,
        ...{
          ...action.payload,
          country: ConvertFromTextToISOAlpha3(action.payload?.country || state.shippingAddress.country),
        },
      },
      isShippingAddressValid: null,
      isSecondaryAddressNeeded:
        state.isSecondaryAddressNeeded && action.payload.addressLine2 !== undefined
          ? null
          : state.isSecondaryAddressNeeded,
      smartystreetsValidation:
        state.smartystreetsValidation !== null
          ? {
              ...state.smartystreetsValidation,
              billingAddress: { ...state.smartystreetsValidation.billingAddress },
              billingValidationNeeded: state.smartystreetsValidation.billingValidationNeeded,
              shippingAddress: { valid: true, reason: '', suggestions: [] },
              smartystreetsValidationFetched: state.smartystreetsValidation.smartystreetsValidationFetched,
              validationNeeded: false,
            }
          : null,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },

  [setBillingAddress]: (state: State.Checkout, action: Action<Partial<State.Address>>): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      loading: false,
      billingAddress: {
        ...state.billingAddress,
        ...{ ...action.payload, country: ConvertFromTextToISOAlpha3(action.payload?.country || '') },
      },
      isBillingAddressValid: null,
      isSecondaryBillingAddressNeeded:
        state.isSecondaryBillingAddressNeeded && action.payload.addressLine2 !== undefined
          ? null
          : state.isSecondaryBillingAddressNeeded,
      smartystreetsValidation:
        state.smartystreetsValidation !== null
          ? {
              ...state.smartystreetsValidation,
              billingAddress: { valid: true, reason: '', suggestions: [] },
              billingValidationNeeded: false,
              shippingAddress: { ...state.smartystreetsValidation.shippingAddress },
              smartystreetsValidationFetched: state.smartystreetsValidation.smartystreetsValidationFetched,
              validationNeeded: state.smartystreetsValidation.validationNeeded,
            }
          : null,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },

  [setSelectedCreditCardId]: (state: State.Checkout, action: Action<string | null>): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      selectedCreditCardId: action.payload,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },
  [setSelectedPaymentMethodEntity]: (state: State.Checkout, action: Action<string | null>): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      selectedPaymentMethodEntity: action.payload,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },

  [setCheckoutPage]: (state: State.Checkout, action: Action<string | null>): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      pageStep: action.payload,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },

  [combineActions(getConferenceUserInfo, setConferenceUserInfo) as any]: (
    state: State.Checkout,
    action: Action<State.ConferenceUserInfo>
  ): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      conferenceUserInfo: action.payload,
      loading: false,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },

  [toggleSameShippingAddressForBilling]: (
    state: State.Checkout,
    action: Action<{ firstName: string; lastName: string }>
  ): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      billingAddress: {
        ...state.billingAddress,
        firstName: state.billingAddress.firstName || action.payload.firstName,
        lastName: state.billingAddress.lastName || action.payload.lastName,
      },
      sameShippingAddressForBilling: !state.sameShippingAddressForBilling,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },

  [toggleSameShippingAddressForPayment]: (state: State.Checkout): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      sameShippingAddressForPayment: !state.sameShippingAddressForPayment,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },
  [toggleSaveCreditCardForFuture]: (state: State.Checkout): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      saveCreditCardForFuture: !state.saveCreditCardForFuture,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },

  [toggleSavedShippingAddressChecked]: (state: State.Checkout, action: any): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      shippingAddress: {
        ...action.payload,
        isPrimary: action.payload?.isPrimary || true,
      },
      savedShippingAddressChecked: action.payload?.id !== 'NEW' && action.payload?.id !== undefined,
      isShippingAddressValid: null,
      isSecondaryAddressNeeded: null,
      savedShippingAddressEdited: !state.savedShippingAddressChecked && false,
      isShippingEmbargoed: null,
      smartystreetsValidation:
        state.smartystreetsValidation !== null
          ? {
              ...state.smartystreetsValidation,
              billingAddress: { ...state.smartystreetsValidation.billingAddress },
              billingValidationNeeded: state.smartystreetsValidation.billingValidationNeeded,
              shippingAddress: { valid: true, reason: '', suggestions: [] },
              smartystreetsValidationFetched: state.smartystreetsValidation.smartystreetsValidationFetched,
              validationNeeded: false,
            }
          : null,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },
  [setSavedShippingAddressEdited]: (state: State.Checkout, action: any): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      savedShippingAddressEdited: action.payload,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },

  [setSavedBillingAddressEdited]: (state: State.Checkout, action: any): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      savedBillingAddressEdited: action.payload,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },

  [setShippingEmbargoEdited]: (state: State.Checkout, action: any): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      isShippingEmbargoed: !action.payload,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },

  [setBillingEmbargoEdited]: (state: State.Checkout, action: any): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      isBillingEmbargoed: !action.payload,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },

  [toggleSavedBillingAddressChecked]: (state: State.Checkout, action: any): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      savedBillingAddressChecked: action.payload !== 'NEW' && action.payload !== undefined,
      isBillingAddressValid: null,
      isBillingEmbargoed: null,
      smartystreetsValidation:
        state.smartystreetsValidation !== null
          ? {
              ...state.smartystreetsValidation,
              billingAddress: { valid: true, reason: '', suggestions: [] },
              billingValidationNeeded: false,
              shippingAddress: { ...state.smartystreetsValidation.shippingAddress },
              smartystreetsValidationFetched: state.smartystreetsValidation.smartystreetsValidationFetched,
              validationNeeded: state.smartystreetsValidation.validationNeeded,
            }
          : null,
    };
    setSessionStorageItem({ [StorageNames.checkout]: nextState });
    return nextState;
  },

  [getZuoraAccount]: {
    next: (state: State.Checkout, action: any): State.Checkout => {
      const { success, selectedOrganization, isCenterMembershipJourney, hasExistingAddressFromAddressBook } =
        action.payload;

      if (success) {
        const { billToContact, basicInfo, subscriptions, paymentMethods = [] } = action.payload;
        const orgShippingAddress = selectedOrganization?.shippingAddress;
        const orgBillingAddress = selectedOrganization?.billingAddress;

        const creditCards = (action.payload.creditCards || []).sort(cardsCompare);

        const selectedCreditCardId: string | null = getCardById(creditCards, state.selectedCreditCardId)
          ? state.selectedCreditCardId
          : getDefaultCardOrAny(state.creditCards)?.id || null;

        let { savedShippingAddress } = getSavedShippingBillingAddresses(subscriptions) as Response;

        if (isCenterMembershipJourney) {
          savedShippingAddress = orgShippingAddress;
        }

        const validateZipCode: boolean | undefined = CheckoutCountriesListHash[billToContact.country]?.validateZipCode;
        const isBillToContactValid: boolean =
          billToContact.addressLine1 &&
          billToContact.city &&
          billToContact.state &&
          (!validateZipCode || billToContact.zipCode) &&
          billToContact.country;
        // Currently we use defaultBillingAddress as a savedBillingAddress but it can be easily changed to use last billing address from subscriptions
        const nextState: State.Checkout = {
          ...state,
          loading: false,
          success,
          zuoraAccountFetched: true,
          accountId: basicInfo.id,
          hasDefaultPaymentMethod: Boolean(basicInfo.defaultPaymentMethod),
          defaultBillingAddress: isBillToContactValid ? billToContact : state.defaultBillingAddress,
          creditCards,
          paymentMethods,
          selectedCreditCardId,
          // Center memberships will use the organization's addresses instead of user's saved addresses.
          savedShippingAddress: Boolean(state.shippingAddress.city)
            ? {
                ...(isCenterMembershipJourney ? orgShippingAddress : state.shippingAddress),
                country: state.shippingAddress.country,
              }
            : savedShippingAddress,
          savedBillingAddress: Boolean(state.billingAddress.city)
            ? {
                ...(isBillToContactValid && { firstName: billToContact.firstName, lastName: billToContact.lastName }),
                ...(isCenterMembershipJourney ? orgBillingAddress : state.billingAddress),
              }
            : isBillToContactValid && billToContact,
          savedShippingAddressChecked: hasExistingAddressFromAddressBook,
          savedBillingAddressChecked: hasExistingAddressFromAddressBook,
          sameShippingAddressForBilling: Boolean(isBillToContactValid && billToContact)
            ? false
            : state.sameShippingAddressForBilling,
          creditHold: basicInfo.CreditHold__c,
          currency: basicInfo?.currency,
        };
        setSessionStorageItem({ [StorageNames.checkout]: nextState });
        return nextState;
      }

      const newState: State.Checkout = {
        ...state,
        loading: false,
        success,
        zuoraAccountFetched: true,
        savedCardUsed: state.savedCardUsed,
      };
      setSessionStorageItem({ [StorageNames.checkout]: newState });
      return newState;
    },
    throw: (state: State.Checkout): State.Checkout => ({
      ...state,
      loading: false,
    }),
  },
  [resetZuoraAccount]: (state: State.Checkout, action: any): State.Checkout => {
    const nextState: State.Checkout = {
      ...state,
      paymentMethods: initialState.paymentMethods,
    };
    return nextState;
  },

  [updateZuoraAccount]: {
    next: (state: State.Checkout, action: Action<State.Address>): State.Checkout => {
      const nextState: State.Checkout = {
        ...state,
        defaultBillingAddress: action.payload,
      };
      setSessionStorageItem({ [StorageNames.checkout]: nextState });
      return nextState;
    },
  },

  [getOrderPreview]: {
    next: (state: State.Checkout, action: any): State.Checkout => {
      const taxAmount: number = action.payload?.previewResult?.invoices?.reduce(
        (acc: number, invoice: any) => acc + invoice.taxAmount,
        0
      );
      const creditMemoTaxAmount: number = action.payload?.previewResult?.creditMemos?.reduce(
        (acc: number, creditMemo: any) => acc + creditMemo.taxAmount,
        0
      );
      const totalMemoTaxAmount = action.payload?.isFLPSwitchOrUpgrade
        ? creditMemoTaxAmount < 0
          ? creditMemoTaxAmount * -1
          : creditMemoTaxAmount
        : creditMemoTaxAmount * -1;
      const proratedPrice: Orders.ZuoraInvoiceItem[] = action.payload?.previewResult?.invoices?.reduce(
        (acc: Orders.ZuoraInvoiceItem[], invoice: any) => {
          invoice?.invoiceItems?.forEach((invoiceItem: any) => acc.push(invoiceItem));
          return acc;
        },
        []
      );
      // NOTE: credit memo invoice items would compute now what is the total credit and return a positive value
      // we need to make this into negative value in order to present correctly in Cart Summary.
      if (action.payload?.previewResult?.creditMemos?.[0]) {
        action.payload?.previewResult?.creditMemos?.[0]?.creditMemoItems?.forEach((item: any) => {
          item.amountWithoutTax = item.amountWithoutTax *= -1;
          proratedPrice.push(item);
        });
      }

      const negativeInvoice = action?.payload?.previewResult?.invoices.find((inv: any) => inv.amountWithoutTax < 0);
      const totalRefund = action?.payload?.previewResult?.creditMemos
        ? action?.payload?.previewResult?.creditMemos[0]?.amountWithoutTax
        : negativeInvoice
        ? negativeInvoice.amountWithoutTax * -1
        : 0;

      const nextState: State.Checkout = {
        ...state,
        taxAmount: taxAmount !== null ? taxAmount : totalMemoTaxAmount,
        proratedPrice,
        legalEntity: action.payload?.legalEntity,
        ...(action.payload?.isFLPSwitchOrUpgrade && { membershipRefundPrice: totalRefund }),
      };
      setSessionStorageItem({ [StorageNames.checkout]: nextState });
      return nextState;
    },
  },
  [resetSalesTax]: {
    next: (state: State.Checkout, action: any): State.Checkout => {
      const nextState: State.Checkout = {
        ...state,
        taxAmount: null,
      };
      setSessionStorageItem({ [StorageNames.checkout]: nextState });
      return nextState;
    },
  },

  [getMembershipOrderPreview]: {
    next: (state: State.Checkout, action: any): State.Checkout => {
      const membershipProratedPrice: number =
        action.payload?.previewResult?.invoices?.reduce(
          (acc: number, invoice: any) => acc + invoice.amountWithoutTax,
          0
        ) ||
        action.payload?.previewResult?.creditMemos?.reduce((acc: number, cm: any) => acc + cm.amountWithoutTax, 0) ||
        0;
      const nextState: State.Checkout = {
        ...state,
        membershipProratedPrice,
      };
      setSessionStorageItem({ [StorageNames.checkout]: nextState });
      return nextState;
    },
  },

  [getRefundMembershipSubscriptionPreview]: {
    next: (state: State.Checkout, action: any): State.Checkout => {
      const nextState: State.Checkout = {
        ...state,
        membershipRefundPrice: action.payload?.refundAmount || 0,
      };
      setSessionStorageItem({ [StorageNames.checkout]: nextState });
      return nextState;
    },
  },

  [proceedToOrderPreview]: {
    next: (state: State.Checkout, action: any): State.Checkout => {
      const nextState: State.Checkout = {
        ...state,
        loading: false,
      };
      setSessionStorageItem({ [StorageNames.checkout]: nextState });
      return nextState;
    },
  },

  [goToPaymentAction]: {
    next: (
      state: State.Checkout,
      action: Action<{ isAddressProcessed: boolean; isImpersonate: boolean }>
    ): State.Checkout => {
      const { isAddressProcessed, isImpersonate } = action?.payload;
      const nextState: State.Checkout = {
        ...state,
        pageStep: !Boolean(isAddressProcessed)
          ? Checkout.CheckoutStep.ADDRESS
          : !isImpersonate
          ? Checkout.CheckoutStep.PAYMENT
          : Checkout.CheckoutStep.ADMIN_PAYMENT_OPTIONS,
        loading: false,
      };
      setSessionStorageItem({ [StorageNames.checkout]: nextState });
      return nextState;
    },
  },

  [combineActions(getCompleteAddressFormValidation) as any]: {
    next: (
      state: State.Checkout,
      action: Action<{
        smartystreetsValidation: State.SmartyAddressValidationState | null;
        isShippingAddressValid: boolean;
        corrected: boolean;
        isBillingForm: boolean;
        autoCorrection: boolean;
        isBillingAddressValid: boolean;
        isShippingEmbargoed: boolean;
        isBillingEmbargoed: boolean;
      }>
    ): State.Checkout => {
      const {
        smartystreetsValidation,
        isShippingAddressValid,
        isBillingAddressValid,
        isShippingEmbargoed,
        isBillingEmbargoed,
      } = action.payload;
      return {
        ...state,
        smartystreetsValidation: {
          ...(smartystreetsValidation as State.SmartyAddressValidationState),
          smartystreetsValidationFetched: true,
        },
        isShippingAddressValid,
        isBillingAddressValid,
        isShippingEmbargoed,
        isBillingEmbargoed,
      };
    },
  },

  [getAddressAutocompleteItems]: {
    next: (state: State.Checkout, action: Action<State.AddressAutocompleteItem[]>): State.Checkout => ({
      ...state,
      addressAutocompleteItems: action.payload || initialState.addressAutocompleteItems,
      isLoadingAutocompleteItems: false,
    }),
    throw: (state: State.Checkout, action: Action<State.AddressAutocompleteItem[]>): State.Checkout => ({
      ...state,
      isLoadingAutocompleteItems: false,
    }),
  },

  [getPaymentById]: {
    next: (
      state: State.Checkout,
      action: Action<Pick<ZuoraTypes.Payment, 'id' | 'type' | 'currency' | 'success' | 'unappliedAmount'> | null>
    ): State.Checkout => {
      return {
        ...state,
        paymentVerifyResult: action.payload,
        loading: false,
      };
    },
    throw: (state: State.Checkout): State.Checkout => ({
      ...state,
      paymentVerifyResult: { unappliedAmount: 0, currency: '', id: '', success: false, type: null },
      loading: false,
    }),
  },

  [resetOfflineVerify]: (state: State.Checkout): State.Checkout => {
    return {
      ...state,
      paymentVerifyResult: null,
      loading: false,
    };
  },

  [clearAddressAutocompleteItems]: (state: State.Checkout): State.Checkout => ({
    ...state,
    addressAutocompleteItems: initialState.addressAutocompleteItems,
    isLoadingAutocompleteItems: false,
  }),

  [toggleIsLoadingAutocompleteItems]: (state: State.Checkout): State.Checkout => ({
    ...state,
    isLoadingAutocompleteItems: true,
  }),

  [resetModule]: (state: State.Checkout): State.Checkout => {
    const newState = { ...initialState, savedCardUsed: state.savedCardUsed };
    removeSessionStorageItem(StorageNames.checkout);
    return newState;
  },

  [setDefaultPaymentMethod]: {
    next: (state: State.Checkout, action: any): State.Checkout => ({
      ...state,
      selectedCreditCardId: action.payload.paymentMethodId,
      creditCards: state.creditCards
        .map(card => ({
          ...card,
          defaultPaymentMethod: card.id === action.payload.paymentMethodId,
        }))
        .sort(cardsCompare),
    }),
  },

  [removePaymentMethod]: {
    next: (state: State.Checkout, action: any): State.Checkout => {
      const selectedCreditCardId: string | null =
        state.selectedCreditCardId === action.payload.paymentMethodId
          ? getDefaultCardOrAny(state.creditCards)?.id || null
          : state.selectedCreditCardId;
      return {
        ...state,
        selectedCreditCardId,
        creditCards: state.creditCards.filter(card => card.id !== action.payload.paymentMethodId).sort(cardsCompare),
      };
    },
  },

  [setSavedCardUsed]: (state: State.Checkout, action: any): State.Checkout => ({
    ...state,
    savedCardUsed: action.payload,
  }),

  [setSecondaryAddressNeeded]: (state: State.Checkout, action: any): State.Checkout => ({
    ...state,
    isSecondaryAddressNeeded: action.payload,
  }),

  [setSecondaryBillingAddressNeeded]: (state: State.Checkout, action: any): State.Checkout => ({
    ...state,
    isSecondaryBillingAddressNeeded: action.payload,
  }),

  [getReceiptLoading]: (state: State.Checkout): State.Checkout => ({
    ...state,
    receipt: {
      ...state.receipt,
      payReceipt: {
        ...state.receipt.payReceipt,
        loading: true,
      },
    },
  }),

  [getReceipt]: {
    next: (state: State.Checkout, action: Action<State.Invoice>): State.Checkout => ({
      ...state,
      receipt: {
        ...state.receipt,
        payReceipt: {
          receipt: action.payload,
          loading: false,
          error: null,
        },
      },
    }),
    throw: (state: State.Checkout, { payload: error }: { payload: CustomErrors.GraphQLError }): State.Checkout => ({
      ...state,
      receipt: {
        ...state.receipt,
        payReceipt: {
          receipt: null,
          loading: false,
          error,
        },
      },
    }),
  },

  [getReceiptPaymentConfirmationResultLoading]: (state: State.Checkout): State.Checkout => ({
    ...state,
    receipt: {
      ...state.receipt,
      paymentResult: {
        ...state.receipt.paymentResult,
        loading: true,
      },
    },
  }),

  [getReceiptPaymentConfirmationResult]: {
    next: (state: State.Checkout, action: Action<State.Invoice>): State.Checkout => ({
      ...state,
      receipt: {
        ...state.receipt,
        paymentResult: {
          receiptPaymentResult: action.payload,
          loading: false,
          error: null,
        },
      },
    }),
    throw: (state: State.Checkout, { payload: error }: { payload: CustomErrors.GraphQLError }): State.Checkout => ({
      ...state,
      receipt: {
        ...state.receipt,
        paymentResult: {
          receiptPaymentResult: null,
          loading: false,
          error,
        },
      },
    }),
  },

  [resetReceiptPaymentConfirmationData]: (state: State.Checkout): State.Checkout => ({
    ...state,
    receipt: {
      ...state.receipt,
      paymentResult: {
        receiptPaymentResult: null,
        loading: false,
        error: null,
      },
    },
  }),

  [setPaymentTechCodeError]: (state: State.Checkout, action: any): State.Checkout => ({
    ...state,
    paymentTechCodeError: action.payload,
  }),

  [getPaymentsIdByAccountId]: {
    next: (state: State.Checkout, action: any): State.Checkout => {
      return {
        ...state,
        paymentsId: action.payload,
        loading: false,
      };
    },
    throw: (state: State.Checkout): State.Checkout => ({
      ...state,
      paymentsId: [],
      loading: false,
    }),
  },
};
export {
  getZuoraAccount,
  createZuoraOrder,
  getHostedPageSignature,
  getMultiHostedPageSignature,
  setShippingAddress,
  setBillingAddress,
  toggleSameShippingAddressForBilling,
  toggleSameShippingAddressForPayment,
  goToPaymentAction,
  getAddressAutocompleteItems,
  clearAddressAutocompleteItems,
  setSelectedCreditCardId,
  toggleSavedShippingAddressChecked,
  toggleSavedBillingAddressChecked,
  goToOrderErrorPage,
  toggleSaveCreditCardForFuture,
  resetModule,
  setCheckoutPage,
  getConferenceUserInfo,
  setConferenceUserInfo,
  setDefaultPaymentMethod,
  removePaymentMethod,
  setSavedCardUsed,
  sendCreditCardMail,
  setSecondaryAddressNeeded,
  toggleSubscriptionRenewal,
  getReceiptLoading,
  getReceipt,
  goToReceiptCheckoutErrorPage,
  payInvoice,
  getReceiptPaymentConfirmationResultLoading,
  getReceiptPaymentConfirmationResult,
  resetReceiptPaymentConfirmationData,
  setSavedShippingAddressEdited,
  setPaymentTechCodeError,
  setSavedBillingAddressEdited,
  setSecondaryBillingAddressNeeded,
  getCompleteAddressFormValidation,
  setSelectedPaymentMethodEntity,
  proceedToOrderPreview,
  setBypassValidationBilling,
  setBypassValidation,
};

// ------------------------------------
// Reducer
// ------------------------------------
export default handleActions(ACTION_HANDLERS, initialState);
