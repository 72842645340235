import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { generatePath } from 'react-router-dom';
import { default as request, updateContext } from 'utils/GraphQLClient';
import { getAuthHelper } from 'utils/auth';
import { AppcAuthHelper } from 'utils/auth/appc';
import {
  CANCEL_ORDER,
  CANCEL_PREVIEW_ORDER,
  DEPROVISION,
  GET_ADMIN_FEATURE_TOGGLES,
  GET_ADMIN_IMPERSONATE,
  SET_LEGACY_IMPERSONATION,
  GET_ORGANIZATION_ORDERS,
  ORGANIZATION_ADMIN_USERS,
  SEARCH_PRODUCT,
  SEARCH_USER as SEARCH_USER_QUERY,
  GET_ORGANIZATION_BY_ACCOUNT_ID,
  REMOVE_SWAP_ADMINS,
  SEARCH_INVOICES_BY_UID,
  SEARCH_INVOICES_BY_INVOICE_NUMBER,
  GET_ADMIN_INVOICE_FILE,
  GET_INVOICE_ITEMS,
  PAY_INVOICES,
  GET_B2B_ORDER_PREVIEW,
  CREATE_B2B_ORDER,
  GET_ASSIGNED_ORDERS,
  GET_ASSIGNED_APPLICATIONS,
  REMOVE_CLIENT_ADMIN,
  GENERATE_TEMPORARY_PASSWORD,
  MUTATE_TEMPORARY_PASSWORD_MAIL,
  GET_CENTER_MEMBERSHIPS,
  GET_SF_ORDER_ITEMS_BY_ORDER_NUMBER,
  REFUND,
  CANCEL_AND_MOVE,
  CANCEL_FIRM_MEMBERSHIP,
  CANCEL_INVOICE,
  CANCEL_MEMBERSHIP_ORDER,
  GET_ORGANIZATION_CURRENCY,
  GET_ORGANIZATION_ZUORA_ACCOUNT_ID,
  UPDATE_MEMBERSHIP_TERMINATION_STATUS,
  GENERATE_BULK_RENEWAL_REPORT,
  GET_SUBSCRIPTIONS,
  GET_RECENT_DOWNLOAD_REPORT,
  GET_BULK_RENEWAL_TRANSACTION_HISTORY,
  GET_RECENT_BULK_RENEWAL_TRANSACTION,
  PROCESS_BULK_RENEWAL,
  DOWNLOAD_BULK_RENEWAL_REPORT,
  EXTENSION_RAISE_CREDIT_MEMO,
  EXTEND_PRODUCT_ACCESS,
} from 'mxp-graphql-queries';
import { checkSession, setAuth } from 'modules/user/actions';
import { appEnvSelector, oktaConfigSelector } from 'modules/app/selectors';
import { getFeatureToggles } from 'modules/featureToggle/actions';
import {
  toggleModalAddressValidationOpen,
  toggleModalPaymentProcessingOpen,
  toggleModalResetImpersonatedUserPassword,
  toggleModalExtendAccessOpen,
} from 'modules/layouts/actions';
import { isAuthSelector, userOktaIdSelector } from 'modules/user/selectors';
import {
  adminShippingAddressSelector,
  getAccountIdSelector,
  getTotalBalanceSelector,
  selectedInvoicesSelector,
  adminBillingAddressSelector,
  adminClientRoleSelector,
  adminToRemoveSelector,
  b2bCartSelector,
  invoiceDetailsSelector,
  searchInvoicesListSelector,
  organizationOrderRefundCancelSelector,
  selectedCurrency,
  legalEntitySelector,
  isAdminInvoicesPaymentJourneySelector,
  isB2BSelector,
} from 'modules/admin/selectors';
import { getSmartyStreetsValidation, getAddressValidationScenario } from 'modules/checkout/helpers';
import { User as UserUtils } from 'mxp-utils';
import { push, getLocation, createMatchSelector } from 'connected-react-router';
import { emptyObject, getPath, getSessionStorageItem, setSessionStorageItem, areAllTruthy } from 'utils';
import {
  ADMIN_AUTH_STATUS,
  Routes,
  SESSION_STORE_NAMES,
  StorageNames,
  PAYMENT_PROCESSING_TIMEOUT,
  ImpersonationOptions,
  AdminTableType,
  BulkRenewalAndReportActions,
  BulkRenewalAndReportStatus,
} from 'constants/index';
import {
  B2B,
  Invoices,
  Salesforce,
  SmartyStreets,
  User as UserTypes,
  Product,
  User,
  ZuoraTypes,
  Orders,
} from 'mxp-schemas';
import { pageAdminOrganizationAccountIdSelector } from '../router/selectors';
import download from 'downloadjs';
import { removeLocalStorageItem, LOCAL_STORAGE } from 'utils/localStorage';
import { centerMembershipsListSelector } from 'modules/centerAdmin/selectors';
import OktaAuth from '@okta/okta-auth-js';
import moment from 'moment-timezone';

interface IFeatureToggle {
  key: string;
  toggleCheck: boolean;
}

export const loginViaRedirect: any = createAction('admin/LOGIN_VIA_REDIRECT', () => async () => {
  const authHelper = getAuthHelper();
  if (!(authHelper instanceof AppcAuthHelper)) {
    return Promise.reject('Authentication is mis-configured');
  }
  return authHelper.redirectForLogin();
});

export const checkAdminSession: any = createAction('admin/CHECK_SESSION', async () => async (dispatch: Dispatch) => {
  const authHelper = getAuthHelper();
  if (!(authHelper instanceof AppcAuthHelper)) {
    return Promise.reject('Authentication is mis-configured');
  }

  const adminAuthStatus = await authHelper.appcAgentSessionExists();

  if (adminAuthStatus === ADMIN_AUTH_STATUS.UNAUTHENTICATED) {
    await dispatch(loginViaRedirect());
  }

  if (adminAuthStatus === ADMIN_AUTH_STATUS.AUTHORIZED) {
    await dispatch(getFeatureToggles());
    await dispatch(refreshImpersonation());
    await dispatch(getBulkRenewalAccess());
  }
  return { adminAuthStatus };
});

export const checkAdminClientRoleChosen: any = createAction(
  'admin/CHECK_ADMIN_CLIENT_ROLE_CHOSEN',
  async () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const adminClientRole = getSessionStorageItem(
      StorageNames.adminClientRole,
      SESSION_STORE_NAMES.ADMIN_SESSION_STORE_KEY
    );
    await dispatch(getAdminFeatureToggles());
    const adminClientRoleCurrent = adminClientRoleSelector(state);
    await dispatch(getBulkRenewalAccess());
    if (adminClientRole && adminClientRoleCurrent !== adminClientRole) {
      dispatch(setAdminClientRole(adminClientRole));
    }
  }
);

export const getAdminFeatureToggles: any = createAction(
  'admin/GET_FEATURE_TOGGLE',
  () => () => request(GET_ADMIN_FEATURE_TOGGLES)
);

export const toggleAdminFeature: any = createAction('admin/TOGGLE_FEATURE', (featureToggle: IFeatureToggle) => {
  return featureToggle;
});

export const searchUser: any = createAction(
  'admin/SEARCH_USER',
  (accountNumber: string, email: string) => (dispatch: Dispatch) => {
    return request(SEARCH_USER_QUERY, {
      accountNumber,
      email,
    })
      .then((data: any) => {
        return data.searchUser;
      })
      .catch(() => {
        dispatch(push(getPath(Routes.ADMIN_ERROR_PAGE)));
      });
  }
);

export const searchInvoicesLoading: any = createAction('admin/SEARCH_INVOICES_LOADING');
export const searchInvoices: any = createAction(
  'admin/SEARCH_INVOICES',
  (uid: string, legalEntity: string, invoiceNumber: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(searchInvoicesLoading());
    const state: State.Root = getState();
    const association = ZuoraTypes.LegalEntity.ASSOCIATION;
    const existingEntity = legalEntity ? legalEntity.toLowerCase() : association;
    const currency = selectedCurrency(state);

    const customHeaders = {
      existingEntity,
      currency: {
        label: currency,
      },
    };
    updateContext(customHeaders);

    const getSessionList = () => {
      // get cancel invoice triggered session
      const invoiceInProgress: any = getSessionStorageItem(
        StorageNames.faCancelInvoice,
        SESSION_STORE_NAMES.FB_ADMIN
      ) as any;

      // check valid invoice triggered to cancel session
      if (invoiceInProgress) {
        let hasExpiredSession = false;
        const list = invoiceInProgress.filter((item: any) => {
          let isValid = false;
          if (moment(item.triggeredDate).isValid()) {
            const twoMinuteAgo = moment().subtract(2, 'minutes');
            isValid = moment(item.triggeredDate).isAfter(twoMinuteAgo);
            if (!isValid) {
              hasExpiredSession = true;
            }
          }
          return isValid && item;
        });

        // update sessionStorage if has expired triggered
        if (hasExpiredSession) {
          setSessionStorageItem({ [StorageNames.faCancelInvoice]: list }, SESSION_STORE_NAMES.FB_ADMIN);
        }
        return list;
      }
      return [];
    };

    // get cancel invoice triggered session
    const sessionList = getSessionList();
    if (uid) {
      return request(SEARCH_INVOICES_BY_UID, {
        uid,
        legalEntity,
      })
        .then((data: { searchInvoicesByUid: State.InvoiceTableRow[] | null }) => {
          let responseData: any[] | null = [];
          responseData = data.searchInvoicesByUid;

          if (data.searchInvoicesByUid?.length) {
            if (sessionList) {
              responseData = data.searchInvoicesByUid?.map(invoice => {
                const isInList = sessionList.find(
                  (item: any) => item.invoiceId === invoice.id && invoice.status !== Invoices.InvoiceStatus.CANCELLED
                );
                return isInList ? { ...invoice, status: Invoices.InvoiceStatus.INPROGRESS } : invoice;
              });
            }
          }
          dispatch(
            setPaginationAction({
              type: AdminTableType.INVOICES,
              modifier: { totalCount: responseData?.length || 0 },
            })
          );
          return responseData;
        })
        .catch(() => {
          dispatch(push(getPath(Routes.ADMIN_ERROR_PAGE)));
        });
    }
    return request(SEARCH_INVOICES_BY_INVOICE_NUMBER, {
      invoiceNumber,
    })
      .then((data: { searchInvoicesByInvoiceNumber: State.InvoiceTableRow[] }) => {
        let responseData: any[] | null = [];
        responseData = data.searchInvoicesByInvoiceNumber;

        if (data.searchInvoicesByInvoiceNumber?.length) {
          if (sessionList) {
            responseData = data.searchInvoicesByInvoiceNumber?.map(invoice => {
              const isInList = sessionList.find(
                (item: any) => item.invoiceId === invoice.id && invoice.status !== Invoices.InvoiceStatus.CANCELLED
              );
              return isInList ? { ...invoice, status: Invoices.InvoiceStatus.INPROGRESS } : invoice;
            });
          }
        }

        dispatch(
          setPaginationAction({
            type: AdminTableType.INVOICES,
            modifier: { totalCount: responseData?.length || 0 },
          })
        );
        return responseData;
      })
      .catch(() => {
        dispatch(push(getPath(Routes.ADMIN_ERROR_PAGE)));
      });
  }
);
export const refreshImpersonation: any = createAction(
  'admin/REFRESH_IMPERSONATION_STATE',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    const {
      payload: { isAuth },
    } = await dispatch(checkSession());
    await dispatch(setAuth(isAuth));
  }
);

export const impersonate: any = createAction(
  'admin/IMPERSONATE',
  (profile: UserTypes.Profile, impValue: Common.ImpersonationOptions, country: string) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      if (isAuthSelector(state)) {
        return Promise.reject('Impersonation is already in progress');
      }
      const authHelper = getAuthHelper();
      if (!(authHelper instanceof AppcAuthHelper)) {
        return Promise.reject('Authentication is mis-configured');
      }
      if (impValue && impValue === ImpersonationOptions.AICPA) {
        const { impersonate: impersonationToken } = await request(GET_ADMIN_IMPERSONATE, {
          oktaId: profile.userId,
          country,
        });
        authHelper.setImpersonationToken(impersonationToken);
        await dispatch(setAuth(true));
        dispatch(push(getPath(Routes.FEED)));
      }
    }
);

export const stopLegacyImpersonation: any = createAction(
  'admin/STOP_LEGACY_IMPERSONATION',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(SET_LEGACY_IMPERSONATION, { targetEmail: '' }).then(async () => {
      const state: State.Root = getState();
      const oktaCfg = oktaConfigSelector(state);
      const env = appEnvSelector(state);
      const issuer =
        oktaCfg.authServerId !== 'BUILT_IN'
          ? `https://${oktaCfg.b2cUrl}/oauth2/${oktaCfg.b2cAuthServerId}`
          : `https://${oktaCfg.b2cUrl}`;
      const authClient = new OktaAuth({
        issuer,
        clientId: oktaCfg.b2cClientId,
        redirectUri: oktaCfg.publicUrl,
        pkce: false,
        tokenManager: { storage: 'sessionStorage' },
      });
      await authClient.session.close().catch(() => null);

      if (['prod', 'production'].includes(env)) {
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.open('GET', 'https://us.aicpa.org/bin/aicpaorg/uca?command=logout', true);
        xmlHttp.onerror = () => console.error('XMLHttpRequest to www.aicpa.org failed');
        xmlHttp.send(null);
      }

      removeLocalStorageItem(StorageNames.isLegacyImpersonation, LOCAL_STORAGE.ANON_AICPA_DATA);
    });
  }
);

export const endImpersonation: any = createAction('admin/END_IMPERSONATION', () => {
  const authHelper = getAuthHelper();
  if (!(authHelper instanceof AppcAuthHelper)) {
    return Promise.reject('Authentication is mis-configured');
  }

  authHelper.endImpersonation();
  window.location.reload();
});

export const cancelProductPreview: any = createAction(
  'admin/CANCEL_PREVIEW_PRODUCT',
  (details: {
    subscriptionNumber: string;
    subscriptionStartDate: string;
    ratePlanId: string;
    isAutoRenewable: boolean;
    shouldCancelFromStartDate?: boolean;
  }) => {
    const { subscriptionNumber, subscriptionStartDate, ratePlanId, isAutoRenewable, shouldCancelFromStartDate } =
      details;
    const payload = {
      subscriptionStartDate,
      subscriptionNumber,
      ratePlanId,
      isOnlyProductOnSubscription: false,
      isAutoRenewable,
      shouldCancelFromStartDate,
    };
    return request(CANCEL_PREVIEW_ORDER, payload);
  }
);

export const cancelSubscriptionPreview: any = createAction(
  'admin/CANCEL_PREVIEW_SUBSCRIPTION',
  (details: {
    subscriptionNumber: string;
    subscriptionStartDate: string;
    isAutoRenewable: boolean;
    shouldCancelFromStartDate: boolean;
    previewThruStartDate?: boolean;
  }) => {
    const {
      subscriptionNumber,
      subscriptionStartDate,
      isAutoRenewable,
      shouldCancelFromStartDate,
      previewThruStartDate,
    } = details;
    const payload = {
      subscriptionStartDate,
      subscriptionNumber,
      isOnlyProductOnSubscription: true,
      isAutoRenewable,
      shouldCancelFromStartDate,
      previewThruStartDate,
    };
    return request(CANCEL_PREVIEW_ORDER, payload);
  }
);

export const cancelOrder: any = createAction(
  'admin/CANCEL_ORDER',
  (payload: {
      subscriptionNumber: string;
      subscriptionStartDate: string;
      ratePlanId: string;
      isAutoRenewable: boolean;
      isOnlyProductOnSubscription: boolean;
    }) =>
    (dispatch: Dispatch) => {
      return request(CANCEL_ORDER, payload)
        .then(() => {
          return {
            success: true,
            error: false,
            empty: false,
          };
        })
        .catch(() => {
          return {
            success: false,
            error: true,
            empty: false,
          };
        });
    }
);

export const deprovision: any = createAction('admin/DEPROVISION', (orderId: string, sku: string) => {
  const payload = {
    orderId,
    skusForItemsBeingDeprovisioned: [sku],
  };
  return request(DEPROVISION, payload);
});

export const deprovisionMultipleProduct: any = createAction(
  'admin/DEPROVISION_MULTIPLE_SKU',
  (orderId: string, skus: string[], isTerminated?: boolean) => {
    const payload = {
      orderId,
      skusForItemsBeingDeprovisioned: skus,
      isTerminated,
    };
    return request(DEPROVISION, payload);
  }
);

interface MembershipTerminationStatusPayload {
  productType: string;
  productSku: string;
  productStatus: string;
  terminationReason: string;
  terminationComment: string;
  ethicStatus: string;
}

export const updateMembershipTerminationStatus: any = createAction(
  'admin/UPDATE_MEMBERSHIP_TERMINATION_STATUS',
  (payload: MembershipTerminationStatusPayload) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const { productType, productSku, productStatus, terminationReason, terminationComment, ethicStatus } = payload;
    const MEMBERSHIP_PRODUCTS = [
      Product.ProductType.MEMBERSHIP,
      Product.ProductType.SECTION,
      Product.ProductType.CREDENTIAL,
    ];

    if (MEMBERSHIP_PRODUCTS.some(memProduct => memProduct === productType)) {
      // Update Termination Section
      try {
        // tslint:disable-next-line:no-shadowed-variable
        const { updateMembershipTerminationStatus } = await request(UPDATE_MEMBERSHIP_TERMINATION_STATUS, {
          productSku,
          productType,
          productStatus,
          terminationReason,
          terminationComment,
          ethicStatus,
        });
        if (!updateMembershipTerminationStatus?.[0]?.success) {
          return {
            success: false,
            error: true,
            empty: false,
          };
        }
      } catch (e) {
        return {
          success: false,
          error: true,
          empty: false,
        };
      }
    }
  }
);

export const setAdminClientRole: any = createAction(
  'admin/SET_B2B_ROLE',
  (role: State.B2BRoles) => async (dispatch: Dispatch, getState: () => State.Root) => {
    setSessionStorageItem({ [StorageNames.adminClientRole]: role }, SESSION_STORE_NAMES.ADMIN_SESSION_STORE_KEY);
    getAuthHelper(true);
    return role;
  }
);

export const setProductCancellationStatus: any = createAction('admin/PRODUCT_CANCELLATION_STATUS', (status: any) => {
  return {
    success: status.success,
    error: status.error,
    empty: status.empty,
  };
});

export const getOrganizationAdmins: any = createAction(
  'admin/GET_ORGANIZATION_ADMINS',
  (id: string) => (dispatch: Dispatch) => {
    return request(ORGANIZATION_ADMIN_USERS, { id }).catch(() => {
      dispatch(
        push({
          pathname: getPath(Routes.ADMIN_ERROR_PAGE),
          state: { backToAdminRoot: true },
        })
      );
    });
  }
);

export const setAdminToRemove: any = createAction(
  'admin/SET_ADMIN_TO_REMOVE',
  (adminToRemove: State.OrganizationAdmin) => adminToRemove
);

export const checkForAdminRelatedEntities: any = createAction(
  'admin/CHECK_FOR_ADMIN_RELATED_ENTITIES',
  (adminType: Salesforce.AccountContactRole) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(relatedEntitiesLoading());
    const state = getState();
    const adminToRemove = adminToRemoveSelector(state);
    const accountId = pageAdminOrganizationAccountIdSelector(state);
    if (!adminToRemove || !accountId) {
      dispatch(
        push({
          pathname: getPath(Routes.ADMIN_ERROR_PAGE),
          state: { backToAdminRoot: true },
        })
      );
    }
    const aicpaId = adminToRemove?.aicpaId || '';
    try {
      const orders = await request(GET_ASSIGNED_ORDERS, { accountId, aicpaId });
      const memberships = await request(GET_CENTER_MEMBERSHIPS, {
        organizationId: accountId,
        adminAccountId: adminToRemove?.id,
        membershipStatus: 'active',
        date: '',
      });
      const applications = await request(GET_ASSIGNED_APPLICATIONS, {
        personAccountId: adminToRemove?.id,
      });
      const hasRelatedEntities: boolean =
        // if user removes a center admin and it has an active membership
        (adminType === Salesforce.AccountContactRole.CENTERS_ADMIN && memberships.getCenterMemberships.length) ||
        // if user removes an admin which is not center and it has an associated membership or orders or application
        (adminType !== Salesforce.AccountContactRole.CENTERS_ADMIN &&
          (memberships.getCenterMemberships.length ||
            orders.getAssignedOrders.length ||
            applications.getAssignedApplications.length));
      return hasRelatedEntities;
    } catch (error) {
      dispatch(
        push({
          pathname: getPath(Routes.ADMIN_ERROR_PAGE),
          state: { backToAdminRoot: true },
        })
      );
    }
  }
);

export const relatedEntitiesLoading: any = createAction('admin/ASSIGNED_ORDERS_LOADING');

export const getOrganizationOrders: any = createAction(
  'admin/GET_ORGANIZATION_ORDERS',
  (accountId: string, accountNumber: string, legalEntity: string) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      const state: State.Root = getState();
      const association = ZuoraTypes.LegalEntity.ASSOCIATION;
      const existingEntity = legalEntity ? legalEntity.toLowerCase() : association;
      const currency = selectedCurrency(state);

      const customHeaders = {
        existingEntity,
        currency: {
          label: currency,
        },
      };
      await updateContext(customHeaders);

      return request(GET_ORGANIZATION_ORDERS, { accountId, accountNumber, legalEntity })
        .then(response => response.organizationOrders)
        .catch(() => {
          dispatch(
            push({
              pathname: getPath(Routes.ADMIN_ERROR_PAGE),
              state: { backToAdminRoot: true },
            })
          );
        });
    }
);

export const getOrganizationOrdersDetails: any = createAction(
  'admin/GET_SF_ORDER_ITEMS_BY_ORDER_NUMBER',
  (orderNumber: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(removeOrganizationOrderDetailList());
    const state: State.Root = getState();
    const association = 'association';
    const legalEntity = legalEntitySelector(state) || association;
    const currency = selectedCurrency(state);

    const customHeaders = {
      existingEntity: legalEntity.toLowerCase(),
      currency: {
        label: currency,
      },
    };
    await updateContext(customHeaders);

    return request(GET_SF_ORDER_ITEMS_BY_ORDER_NUMBER, { orderNumber })
      .then(response => response.getOrderItemsByOrderNumber)
      .catch(() => {
        dispatch(
          push({
            pathname: getPath(Routes.ADMIN_ERROR_PAGE),
            state: { backToAdminRoot: true },
          })
        );
      });
  }
);

export const removeOrganizationOrderDetailList: any = createAction('admin/REMOVE_ORDER_DETAIL_LIST');

export const swapAdmins: any = createAction(
  'admin/SWAP_ADMINS',
  (adminToSwapEmail: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setSwapAdminModalLoading(true));
    const state = getState();

    const adminToRemove = adminToRemoveSelector(state);
    const accountId = pageAdminOrganizationAccountIdSelector(state);
    if (!adminToRemove || !adminToSwapEmail || !accountId) {
      dispatch(
        push({
          pathname: getPath(Routes.ADMIN_ERROR_PAGE),
          state: { backToAdminRoot: true },
        })
      );
    }

    const adminToRemoveId = adminToRemove?.id || '';
    const firmMembershipTypeToRemove = adminToRemove?.firmMembershipType;
    const firmMembershipTypes = adminToRemove?.firmMembershipTypes;
    return request(REMOVE_SWAP_ADMINS, {
      accountId,
      adminToRemoveId,
      adminToSwapEmail,
      firmMembershipTypeToRemove,
      firmMembershipTypes,
    })
      .then(response => response.removeSwapClientAdmin)
      .then(() => {
        // refresh
        dispatch(getOrganizationAdmins(accountId));
      })
      .catch(() => {
        dispatch(
          push({
            pathname: getPath(Routes.ADMIN_ERROR_PAGE),
            state: { backToAdminRoot: true },
          })
        );
      });
  }
);

export const removeAdmin: any = createAction(
  'admin/REMOVE_ADMIN',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setSwapAdminModalLoading(true));
    const state = getState();

    const adminToRemove = adminToRemoveSelector(state);
    const accountId = pageAdminOrganizationAccountIdSelector(state);
    if (!adminToRemove || !accountId) {
      dispatch(
        push({
          pathname: getPath(Routes.ADMIN_ERROR_PAGE),
          state: { backToAdminRoot: true },
        })
      );
    }

    const adminToRemoveId = adminToRemove?.id || '';

    return request(REMOVE_CLIENT_ADMIN, { accountId, adminToRemoveId })
      .then(response => response.removeSwapClientAdmin)
      .then(() => {
        // refresh
        dispatch(getOrganizationAdmins(accountId));
      })
      .catch(() => {
        dispatch(
          push({
            pathname: getPath(Routes.ADMIN_ERROR_PAGE),
            state: { backToAdminRoot: true },
          })
        );
      });
  }
);

export const setCartLoading: any = createAction('admin/CART_LOADING', async (isLoading: boolean) => {
  return isLoading;
});

export const setSwapAdminModalOpen: any = createAction('admin/SET_SWAP_ADMIN_MODAL_OPEN');
export const setSwapAdminModalLoading: any = createAction('admin/SET_SWAP_ADMIN_MODAL_LOADING');

export const addProductToCart: any = createAction(
  'admin/ADD_PRODUCT_TO_CART',
  async (sku: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setCartLoading(true));
    const state = getState();
    const association = 'association';
    const legalEntity = legalEntitySelector(state) || association;
    const currency = selectedCurrency(state);
    const customHeaders = {
      existingEntity: legalEntity.toLowerCase(),
      currency: {
        label: currency,
      },
    };
    await updateContext(customHeaders);
    const product = await request(SEARCH_PRODUCT, { sku });
    return { ...product };
  }
);

export const removeProductFromCart: any = createAction('admin/REMOVE_PRODUCT_TO_CART', async (index: number) => {
  return { index };
});

export const changeDiscountPrice: any = createAction('admin/CHANGE_DISCOUNT_PRICE', (index: number, price: string) => {
  return { index, price };
});

export const changeListPrice: any = createAction(
  'admin/CHANGE_LIST_PRICE',
  (index: number, amount: string, currency: string) => {
    return { index, amount, currency };
  }
);

export const changeCurrencyCode: any = createAction('admin/CHANGE_CURRENCY_CODE', (currency: string) => {
  return { currency };
});

export const setOrganizationZuoraAccountId: any = createAction(
  'admin/SET_ORGANIZATION_ZOURA_ACCOUNT_ID',
  (value: string) => {
    return { value };
  }
);

export const getOrganizationCurrency: any = createAction(
  'admin/GET_ORGANIZATION_CURRENCY',
  (accountNumber: string, legalEntity: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    let customHeaders;

    const association = ZuoraTypes.LegalEntity.ASSOCIATION;
    const existingEntity = legalEntity?.toLowerCase() || association;

    customHeaders = {
      existingEntity,
    };

    await updateContext(customHeaders);

    return request(GET_ORGANIZATION_CURRENCY, { accountNumber, legalEntity: existingEntity }).then(async response => {
      const currency = response?.getOrganizationCurrency?.currency || Product.ProductCurrencyLabel.USD;
      const currencyList = [
        Product.ProductCurrencyLabel.EUR,
        Product.ProductCurrencyLabel.GBP,
        Product.ProductCurrencyLabel.USD,
      ];
      const properCurrency = currencyList.includes(currency) ? currency : Product.ProductCurrencyLabel.USD;
      customHeaders = {
        existingEntity,
        currency: {
          label: properCurrency,
        },
      };
      await updateContext(customHeaders);
      await dispatch(changeCurrencyCode(properCurrency));
      return properCurrency;
    });
  }
);

export const getOrganizationZuoraAccountId: any = createAction(
  'admin/ORGANIZATION_ZUORA_ACCOUNT_ID',
  (accountNumber: string, legalEntity: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const association = ZuoraTypes.LegalEntity.ASSOCIATION;
    const existingEntity = legalEntity?.toLowerCase() || association;
    const customHeaders = {
      existingEntity,
    };

    await updateContext(customHeaders);
    const response = await request(GET_ORGANIZATION_ZUORA_ACCOUNT_ID, { accountNumber });
    await dispatch(setOrganizationZuoraAccountId(response.getOrganizationZuoraAccountId));

    return response;
  }
);

export const changeField: any = createAction('admin/CHANGE_FIELD', (index: number, value: string, field: string) => {
  return { index, value, field };
});

export const editDescription: any = createAction('admin/EDIT_DESCRIPTION', (index: number, description: string) => {
  return { index, description };
});

export const editBundleName: any = createAction('admin/EDIT_BUNDLE_NAME', (index: number, bundleName: string) => {
  return { index, bundleName };
});

export const editBundleSubStart: any = createAction(
  'admin/EDIT_BUNDLE_SUBSCRIPTION_START',
  (index: number, startDate: string) => {
    return { index, startDate };
  }
);

export const editBundleOccurrenceDate: any = createAction(
  'admin/EDIT_BUNDLE_OCCURRENCE_DATE',
  (index: number, occDate: string) => {
    return { index, occDate };
  }
);

export const editBundlePeriod: any = createAction('admin/EDIT_BUNDLE_PERIOD', (index: number, period: number) => {
  return { index, period };
});

export const editBundlePeriodType: any = createAction(
  'admin/EDIT_BUNDLE_PERIOD_TYPE',
  (index: number, periodType: string) => {
    return { index, periodType };
  }
);

export const updateToggleStatus: any = createAction('admin/UPDATE_TOGGLE_STATUS', (index: number) => {
  return { index };
});

export const openAllProducts: any = createAction('admin/OPEN_ALL_PRODUCTS');

export const closeAllProducts: any = createAction('admin/CLOSE_ALL_PRODUCTS');

export const changeProvisioning: any = createAction(
  'admin/CHANGE_PROVISIONING',
  (index: number, provisionBy: B2B.ProvisionBy) => {
    return { index, provisionBy };
  }
);

export const checkoutLoading: any = createAction('admin/CHECKOUT_LOADING');
export const setShippingAddress: any = createAction(
  'admin/SET_SHIPPING_ADDRESS',
  (address: Partial<State.Address>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    if ('city' in address || 'country' in address) {
      dispatch(setShippingEmbargoEdited(true));
    }
    // Will set bypassvalidation flag to false once form is edited
    dispatch(setBypassValidation(false));
    return address;
  }
);
export const setBillingAddress: any = createAction(
  'admin/SET_BILLING_ADDRESS',
  (address: Partial<State.Address>) => async (dispatch: Dispatch, getState: () => State.Root) => {
    if ('city' in address || 'country' in address) {
      dispatch(setBillingEmbargoEdited(true));
    }
    // Will set bypassvalidation flag to false once form is edited
    dispatch(setBypassValidationBilling(false));

    return address;
  }
);

export const getOrganizationAddress: any = createAction(
  'admin/GET_ORGANIZATION_ADDRESS',
  async (accountId: string, legalEntity: string) => (dispatch: Dispatch) => {
    dispatch(checkoutLoading());
    return request(GET_ORGANIZATION_BY_ACCOUNT_ID, {
      accountId,
      legalEntity,
    }).then((data: { organization: Salesforce.Organization | null }) => {
      const company: string = data.organization?.name || '';
      return {
        billingAddress: {
          ...data.organization?.billingAddress,
          company,
        },
        shippingAddress: {
          ...data.organization?.shippingAddress,
          company,
        },
      };
    });
  }
);

export const setSecondaryAddressNeeded: any = createAction('admin/SET_SECONDARY_ADDRESS_NEEDED');
export const setSecondaryBillingAddressNeeded: any = createAction('admin/SET_SECONDARY_BILLING_ADDRESS_NEEDED');
export const resetCheckoutModule: any = createAction('admin/RESET_CHECKOUT_MODULE');
export const resetCartModule: any = createAction('admin/RESET_CART_MODULE');

export const setSavedShippingAddressEdited: any = createAction('admin/SET_SAVED_SHIPPING_ADDRESS_EDIT');
export const setSavedBillingAddressEdited: any = createAction('admin/SET_SAVED_BILLING_ADDRESS_EDIT');
export const setShippingEmbargoEdited: any = createAction('admin/SET_SHIPPING_EMBARGO_EDIT');
export const setBillingEmbargoEdited: any = createAction('admin/SET_BILLING_EMBARGO_EDIT');

export const setBypassValidation: any = createAction('admin/SET_BYPASS_VALIDATION');
export const setBypassValidationBilling: any = createAction('admin/SET_BYPASS_VALIDATION_BILLING');

export const addressProceedToNext: any = createAction(
  'admin/ADDRESS_TO_NEXT_PAGE',
  async () => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(checkoutLoading());
    const state = getState();
    const address = adminShippingAddressSelector(state);
    const billingAddress = adminBillingAddressSelector(state);
    const match: any = createMatchSelector(getPath(Routes.ADMIN_CREATE_ORDER))(state as State.Root);
    const { accountId, accountNumber, legalEntity } = match && match.params;

    await dispatch(getOrganizationAdmins(accountId));

    const {
      state: { activeScreenIndex },
    }: any = getLocation(state as State.Root);

    const response = await (() => {
      return dispatch(getCompleteAddressFormValidation(address, billingAddress));
    })();

    const isAddressValid = response?.payload?.isShippingAddressValid;
    const billingAddresssValid = response?.payload?.isBillingAddressValid;

    if (isAddressValid && billingAddresssValid) {
      dispatch(
        push({
          pathname: generatePath(getPath(Routes.ADMIN_CREATE_ORDER), { accountId, accountNumber, legalEntity }),
          state: { activeScreenIndex: (activeScreenIndex as number) + 1 },
        })
      );
    }

    // address not valid -> smarty needs more
    const shippingAddress = response.payload?.smartystreetsValidation?.shippingAddress;
    const billingAddresss = response.payload?.smartystreetsValidation?.billingAddress;
    const reason = shippingAddress?.reason || '';
    const reason2 = billingAddresss?.reason || '';

    if (!response.payload?.smartystreetsValidation?.validationNeeded) {
      const fullSuggestion = response.payload?.smartystreetsValidation?.shippingAddress?.suggestions[0]?.fullSuggestion;
      if (fullSuggestion) {
        const transformedResponse: State.Address = {
          addressLine1: fullSuggestion.street || '',
          addressLine2: fullSuggestion.streetSec || '',
          city: fullSuggestion.city || '',
          country: fullSuggestion.country || 'USA',
          zipCode: fullSuggestion.zipcode || '',
          state: fullSuggestion.state || '',
          county: fullSuggestion.county || '',
        };
        await dispatch(setShippingAddress(transformedResponse));
      }
    }

    const shippingScenario = getAddressValidationScenario(reason);
    const billingScenario = getAddressValidationScenario(reason2);

    if (!response.payload?.smartystreetsValidation?.billingValidationNeeded) {
      const fullSuggestion = response.payload?.smartystreetsValidation?.billingAddress?.suggestions[0]?.fullSuggestion;
      if (fullSuggestion) {
        const transformedResponse: State.Address = {
          addressLine1: fullSuggestion.street || '',
          addressLine2: fullSuggestion.streetSec || '',
          city: fullSuggestion.city || '',
          country: fullSuggestion.country || 'USA',
          zipCode: fullSuggestion.zipcode || '',
          state: fullSuggestion.state || '',
          county: fullSuggestion.county || '',
        };
        await dispatch(setBillingAddress(transformedResponse));
      }
    }

    if (
      shippingScenario !== SmartyStreets.ValidationCheckoutToggle.INVALID &&
      billingScenario !== SmartyStreets.ValidationCheckoutToggle.INVALID &&
      shippingScenario !== SmartyStreets.ValidationCheckoutToggle.SECONDARY &&
      billingScenario !== SmartyStreets.ValidationCheckoutToggle.SECONDARY &&
      (shippingScenario === SmartyStreets.ValidationCheckoutToggle.MODAL ||
        billingScenario === SmartyStreets.ValidationCheckoutToggle.MODAL)
    ) {
      const openModal = true;
      dispatch(toggleModalAddressValidationOpen(openModal));
    }

    if (shippingScenario === SmartyStreets.ValidationCheckoutToggle.SECONDARY) {
      await dispatch(setSecondaryAddressNeeded(true));
      dispatch(setSavedShippingAddressEdited(true));
    }
    if (billingScenario === SmartyStreets.ValidationCheckoutToggle.SECONDARY) {
      const isMissingSecondary = true;
      await dispatch(setSecondaryBillingAddressNeeded(isMissingSecondary));
      dispatch(setSavedBillingAddressEdited(true));
    }

    return { proceed: isAddressValid, proceedBilling: billingAddresssValid };
  }
);

export const getCompleteAddressFormValidation: any = createAction(
  'checkout/GET_COMPLETE_ADDRESS_VALIDATION',
  async () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const shippingAddress = adminShippingAddressSelector(state);
    const billingAddress = adminBillingAddressSelector(state);
    return getSmartyStreetsValidation(shippingAddress, billingAddress);
  }
);

export const changeOpportunityNumber: any = createAction(
  'admin/CHANGE_OPPORTUNITY_NUMBER',
  (opportunityNumber: string) => {
    return { opportunityNumber };
  }
);

export const changePoNumber: any = createAction('admin/CHANGE_PO_NUMBER', (poNumber: string) => {
  return { poNumber };
});

export const changeInvoicePresentation: any = createAction(
  'admin/CHANGE_INVOICE_PRESENTATION',
  (value: B2B.InvoicePresentationOptions) => {
    return { value };
  }
);

export const changeSellingCompany: any = createAction(
  'admin/CHANGE_SELLING_COMPANY',
  (value: B2B.InvoicePresentationOptions) => {
    return { value };
  }
);

export const changeClientAdmin: any = createAction('admin/CHANGE_CLIENT_ADMIN', (contactId: string) => {
  return { contactId };
});

export const setInvoiceInClientAdminProfile: any = createAction(
  'admin/SET_INVOICE_IN_CLIENT_ADMIN_PROFILE',
  (value: boolean) => {
    return { value };
  }
);

export const setSendConfirmationEmailState: any = createAction(
  'admin/SET_CONFIRMATION_EMAIL_STATE',
  (value: boolean) => {
    return { value };
  }
);

export const setSendConfirmationEmails: any = createAction('admin/SET_CONFIRMATION_EMAILS', (emails: string) => {
  return { emails };
});

export const changeInvoiceDate: any = createAction('admin/CHANGE_INVOICE_DATE', (value: boolean) => {
  return { value };
});

export const setDownloadingId: any = createAction('admin/INVOICES_SET_DOWNLOADING_ID');

export const getAdminInvoiceFile: any = createAction(
  'admin/INVOICES_GET_INVOICE_FILE',
  async (invoice: State.SearchInvoicesResult) => async (dispatch: Dispatch) => {
    dispatch(setDownloadingId(invoice.id));

    const customHeaders = {
      existingEntity: invoice?.legalEntity,
      currency: {
        label: invoice?.currency,
      },
    };
    await updateContext(customHeaders);

    return request(GET_ADMIN_INVOICE_FILE, { invoiceId: invoice.id }).then(response => {
      download(`data:application/pdf;base64,${response.getAdminInvoiceFile}`, `${invoice.invoiceNumber}.pdf`);
    });
  }
);

export const getMultipleAdminInvoiceFiles: any = createAction(
  'admin/INVOICES_GET_MULTIPLE_INVOICE_FILES',
  () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const selectedInvoices = selectedInvoicesSelector(state);
    selectedInvoices.forEach((invoice: State.SearchInvoicesResult) => {
      dispatch(getAdminInvoiceFile(invoice));
    });
  }
);

export const getInvoiceItems: any = createAction(
  'admin/GET_INVOICE_ITEMS',
  async (invoiceNumber: string, legalEntity?: ZuoraTypes.LegalEntity, offset?: number, limit?: number) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(invoiceItemsLoading());
      const state = getState();
      const invoices: State.SearchInvoicesResult[] | null = searchInvoicesListSelector(state);
      const invoiceId = invoices?.find((invoice: State.Invoice) => invoice.invoiceNumber === invoiceNumber)?.id;
      return request(GET_INVOICE_ITEMS, {
        invoiceId,
        offset: offset || 0,
        limit: limit || 10,
        legalEntity,
      })
        .then((data: { getInvoiceItems: State.InvoiceItemsResponse }) => {
          dispatch(
            setPaginationAction({
              type: AdminTableType.INVOICE_ITEMS,
              modifier: { totalCount: data.getInvoiceItems.totalCount },
            })
          );
          return data.getInvoiceItems;
        })
        .catch(() => {
          dispatch(push(getPath(Routes.ADMIN_ERROR_PAGE)));
        });
    }
);

export const getSubscriptions: any = createAction(
  'admin/GET_SUBSCRIPTIONS',
  async (invoiceNumber: string, legalEntity?: ZuoraTypes.LegalEntity, offset?: number, limit?: number) =>
    (dispatch: Dispatch, getState: () => State.Root) => {
      dispatch(subscriptionListLoading());
      const state = getState();
      const invoices: State.SearchInvoicesResult[] | null = searchInvoicesListSelector(state);
      const invoiceId = invoices?.find((invoice: State.Invoice) => invoice.invoiceNumber === invoiceNumber)?.id;
      return request(GET_SUBSCRIPTIONS, {
        invoiceId,
        offset: offset || 0,
        limit: limit || 10,
        legalEntity,
      })
        .then((data: { getSubscriptionsFromInvoiceId: State.SubscriptionsResult }) => {
          return data.getSubscriptionsFromInvoiceId;
        })
        .catch(() => {
          dispatch(push(getPath(Routes.ADMIN_ERROR_PAGE)));
        });
    }
);

export const cancelInvoice: any = createAction(
  'admin/CANCEL_INVOICE',
  (payload: Invoices.CancelInvoiceParams) => () => {
    return request(CANCEL_INVOICE, payload)
      .then(async () => ({
        success: true,
        error: false,
        empty: false,
      }))
      .catch(err => {
        throw err;
      });
  }
);

export const setPaginationAction: any = createAction('admin/SET_PAGINATION');

export const toggleSelectAllInvoiceItems: any = createAction(
  'admin/TOGGLE_SELECT_ALL_INVOICE_ITEMS',
  (isMultipleInvoiceItemsSelected: boolean, isAllInvoiceItemsSelected: boolean) => {
    return { isMultipleInvoiceItemsSelected, isAllInvoiceItemsSelected };
  }
);

export const toggleSelectInvoiceItem: any = createAction('admin/TOGGLE_SELECT_INVOICE_ITEM', (id: string) => {
  return { id };
});

export const toggleSelectedSubscription: any = createAction(
  'admin/TOGGLE_SELECT_SUBSCRIPTION',
  (subscriptionName: string) => {
    return { subscriptionName };
  }
);

export const toggleSelectAllSubscriptions: any = createAction(
  'admin/TOGGLE_SELECT_ALL_SUBSCRIPTIONS',
  (isMultipleSubscriptionsSelected: boolean, isAllSubscriptionsSelected: boolean) => {
    return { isMultipleSubscriptionsSelected, isAllSubscriptionsSelected };
  }
);

export const resetSubscriptions: any = createAction('admin/RESET_SUBSCRIPTIONS');

export const resetInvoiceItems: any = createAction('admin/RESET_INVOICE_ITEMS');

export const subscriptionListLoading: any = createAction('admin/SUBSCRIPTIONS_LOADING');

export const invoiceItemsLoading: any = createAction('admin/INVOICE_ITEMS_LOADING');

export const toggleSelectInvoice: any = createAction('admin/TOGGLE_SELECT_INVOICE', (invoiceNumber: string) => {
  return { invoiceNumber };
});

export const toggleSelectAllInvoices: any = createAction(
  'admin/TOGGLE_SELECT_ALL_INVOICES',
  (isMultipleInvoicesSelected: boolean, isAllInvoicesSelected: boolean) => {
    return { isMultipleInvoicesSelected, isAllInvoicesSelected };
  }
);

export const setSingleInvoiceSelected: any = createAction('admin/SET_SINGLE_INVOICE_SELECTED', (invoiceId: string) => {
  return { invoiceId };
});

export const resetAdminInvoicesResultModule: any = createAction('admin/RESET_ADMIN_INVOICES_RESULT_MODULE');

export const resetAdminInvoicesPayResultModule: any = createAction('admin/RESET_ADMIN_INVOICES_PAY_RESULT_MODULE');

export const payInvoices: any = createAction(
  'admin/MAKE_INVOICES_PAYMENT',
  (paymentMethodId: string, paymentMethod?: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const startTime: number = Date.now();
    dispatch(toggleModalPaymentProcessingOpen());

    const getTimeoutDiff = (): number => {
      const endTime: number = Date.now();
      const diff: number = endTime - startTime;
      return diff > PAYMENT_PROCESSING_TIMEOUT ? 0 : PAYMENT_PROCESSING_TIMEOUT - diff;
    };
    const state: State.Root = getState();
    const invoices = selectedInvoicesSelector(state);
    const totalAmountToPay: number = getTotalBalanceSelector(state);
    const accountId: string = getAccountIdSelector(state) || '';
    const legalEntity = legalEntitySelector(state) as string;
    const isAdminInvoicesPayment = isAdminInvoicesPaymentJourneySelector(state);
    const invoiceEntity =
      isAdminInvoicesPayment && invoices?.length ? invoices[0]?.legalEntity || legalEntity : undefined;

    // The amount in Zuora is the total amount of the invoice
    // The balance is what we are paying for
    const invoicesToPay = invoices.map(invoice => {
      return {
        invoiceId: invoice.id,
        amount: invoice.balance,
        balance: invoice.balance,
        invoiceNumber: invoice.invoiceNumber,
        orderNumber: invoice.orderNumber,
        currency: invoice?.currency,
      };
    });

    const payInvoicesParams: Invoices.PayInvoicesParams = {
      paymentMethodId,
      accountId,
      totalAmount: totalAmountToPay,
      invoices: invoicesToPay,
      paymentMethod,
      invoiceEntity,
      isPartialPayment: false,
    };

    return request(PAY_INVOICES, payInvoicesParams)
      .then(res => {
        setTimeout(() => {
          dispatch(toggleModalPaymentProcessingOpen());
          dispatch(resetAdminInvoicesResultModule());
          dispatch(push(generatePath(getPath(Routes.ADMIN_INVOICES_PAYMENT_CONFIRMATION))));
        }, getTimeoutDiff());

        return {
          ...res.payInvoices,
          currency: invoicesToPay[0]?.currency,
        };
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(toggleModalPaymentProcessingOpen());
          dispatch(resetAdminInvoicesResultModule());
          dispatch(goToAdminInvoicesPaymentErrorPage({ adminInvoicesPaymentError: true }));
        }, getTimeoutDiff());
      });
  }
);

export const goToAdminInvoicesPaymentErrorPage: any = createAction(
  'admin/ADMIN_INVOICES_PAYMENT_ERROR_PAGE',
  ({ adminInvoicesPaymentError = false }: any = emptyObject) =>
    (dispatch: Dispatch, getState: () => State.Root): void => {
      dispatch(
        push({
          pathname: generatePath(getPath(Routes.ADMIN_INVOICES_PAYMENT_ERROR)),
          state: { adminInvoicesPaymentError },
        })
      );
    }
);

export const getB2BOrderPreview: any = createAction(
  'admin/GET_B2B_ORDER_PREVIEW',
  async (accountId: string, legalEntity?: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(checkoutLoading());
    const state = getState();
    const cart = b2bCartSelector(state);
    const invoiceDetails = invoiceDetailsSelector(state);
    const billingAddress = adminBillingAddressSelector(state);
    const shippingAddress = adminShippingAddressSelector(state);
    const currency = selectedCurrency(state);
    const customHeaders = {
      existingEntity: legalEntity?.toLowerCase(),
      currency: {
        label: currency,
      },
    };
    await updateContext(customHeaders);

    return request(GET_B2B_ORDER_PREVIEW, {
      accountId,
      legalEntity,
      cart,
      invoiceDetails,
      billingAddress,
      shippingAddress,
    }).then((response: { b2bOrderPreview: { taxAmount: number } }) => response.b2bOrderPreview);
  }
);

export const createB2BOrder: any = createAction(
  'admin/CREATE_B2B_ORDER',
  async (accountId: string, legalEntity: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(checkoutLoading());
    const state = getState();
    const cart = b2bCartSelector(state);
    const invoiceDetails = invoiceDetailsSelector(state);
    const billingAddress = adminBillingAddressSelector(state);
    const shippingAddress = adminShippingAddressSelector(state);

    return request(CREATE_B2B_ORDER, {
      accountId,
      legalEntity,
      cart,
      invoiceDetails,
      billingAddress,
      shippingAddress,
    }).then((response: { createB2BOrder: { orderNumber: string } }) => response.createB2BOrder);
  }
);

export const resetImpersonatedUserPasswordLoading: any = createAction('admin/RESET_IMPERSONATED_USER_PASSWORD_LOADING');

export const resetImpersonatedUserPassword: any = createAction(
  'admin/RESET_IMPERSONATED_USER_PASSWORD',
  async () => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const oktaId = userOktaIdSelector(state);

    dispatch(resetImpersonatedUserPasswordLoading());

    return request(GENERATE_TEMPORARY_PASSWORD, { oktaId })
      .then(res => {
        dispatch(resetImpersonatedUserPasswordLoading());
        dispatch(toggleModalResetImpersonatedUserPassword());
        dispatch(sendTemporaryPasswordMail());
        const tempPassword = { code: res.generateTemporaryPassword };
        return alert(tempPassword.code);
      })
      .catch(error => {
        dispatch(resetImpersonatedUserPasswordLoading());
        return Promise.reject({ error: error?.response?.errors?.[0]?.message || '' });
      });
  }
);

export const sendTemporaryPasswordMail: any = createAction('admin/SEND_TEMPORARY_PASSWORD_EMAIL', () => async () => {
  return request(MUTATE_TEMPORARY_PASSWORD_MAIL);
});

interface CancelPayload {
  orderId?: string;
  sku: string;
  subscriptionNumber: string;
  subscriptionStartDate: string;
  ratePlanId: string;
  isAutoRenewable: boolean;
  isOnlyProductOnSubscription: boolean;
  subscriptionEndDate: string;
  zuoraProductId?: string;
  discountedPrice?: number;
  amount: string;
  reason?: string;
  shouldCancelFromStartDate?: boolean;
  isEndOfLastInvoicePeriod?: boolean;
  cancelledQuantity?: string;
  orderProductId?: string;
  typeOfCancellation: string;
  typeOfCancellationReason: string;
  typeOfCancellationComment: string;
  isB2B?: boolean;
  refundAction?: Product.RefundOrCancellationEnums;
  availableSeats?: number;
  assignedSeats?: number;
  seatNumber?: number;
}

export const cancelRefundOrder: any = createAction(
  'admin/CANCEL_REFUND_ORDER',
  (details: {
      orderId: string;
      cancelPayload: CancelPayload[];
      refundPayload: User.RefundRequestDetails;
      cancelAction: string;
      isMembership?: boolean;
      membershipId?: string;
      productType?: string;
      productSku?: string;
      firmAccountId?: string;
      typeOfCancellation?: string;
      typeOfCancellationReason?: string;
      typeOfCancellationComment?: string;
      deprovisionPayload?: Array<{ orderId: string; skusForItemsBeingDeprovisioned: string[] }>;
      isOnlyProductOnSubscription?: boolean;
    }) =>
    async (dispatch: Dispatch, getState: () => State.Root) => {
      let paymentId = '';
      let runBilling = true;
      const {
        orderId,
        cancelPayload,
        refundPayload,
        cancelAction,
        isMembership,
        membershipId,
        productType,
        productSku,
        firmAccountId,
        typeOfCancellation,
        typeOfCancellationReason,
        typeOfCancellationComment,
        deprovisionPayload,
      } = details as any;
      // Scenarios:
      // Cancel Only
      //     - calls cancelOrder (no-refund)
      //     - deprovision product in ct/sf
      // Partial Refund with Cancellation
      //     - calls refund api - (creates Credit memo in zuora)
      //     - calls cancelOrder (no-refund)
      //     - deprovision product in ct/sf
      // Full Refund with Cancellation
      //     - calls refund api - (creates Credit memo in zuora)
      //     - calls cancelOrder (no-refund)
      //     - deprovision product in ct/sf
      // Refund Only
      //     - calls refund api - (creates Credit memo in zuora)
      // Cancel and Move
      //     - calls cancelAndMove api - (un-applies the payment)
      //     - calls refund api - (creates Credit memo in zuora but will not refund)
      //     - calls cancel order (no-refund)
      //     - deprovision product in ct/sf

      const membershipTerminationPayload = {
        productSku: UserUtils.conditionalFunction(productType === Product.ProductType.MEMBERSHIP, '', productSku),
        productType,
        productStatus: UserUtils.conditionalFunction(
          typeOfCancellation === 'Resignation',
          Product.ProductCancellationStatus.RESIGNED,
          Product.ProductCancellationStatus.TERMINATED
        ),
        terminationReason: typeOfCancellationReason,
        terminationComment: typeOfCancellationComment,
        ethicStatus: getState().user.personAccountData.ethicsStatus || '',
      };

      switch (cancelAction) {
        case Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION:
          if (!cancelPayload[0].isB2B) {
            await dispatch(refund(refundPayload));
          }
          break;
        case Product.RefundOrCancellationEnums.PARTIAL_REFUND_WITH_CANCELLATION:
          if (!cancelPayload[0].isB2B) {
            await dispatch(refund({ ...refundPayload, taxAutoCalculation: true }));
            runBilling = false; // since refund is triggered, setting runBilling to false to avoid cm duplication
          }
          break;
        case Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION:
          const refundResult = await dispatch(refund(refundPayload));
          return refundResult.payload;
        case Product.RefundOrCancellationEnums.CANCEL_AND_MOVE:
          const movePaymentZuoraPayload = cancelPayload
            .filter((data: CancelPayload) => Number(data.amount) > 0)
            .map((data: CancelPayload) => ({
              subscriptionNumber: data.subscriptionNumber,
              zuoraProductId: data.zuoraProductId,
              amount: data.amount,
              reason: data.reason,
            }));

          // UN-APPLY PAYMENT
          const unApplyPaymentResponse = await request(CANCEL_AND_MOVE, {
            zuoraPayload: movePaymentZuoraPayload,
          }).catch(() => {
            return {
              success: false,
              error: true,
              empty: false,
            };
          });
          paymentId = unApplyPaymentResponse?.cancelAndMovePayment?.id;

          // CREATE CREDIT MEMO BUT WILL NOT REFUND
          await dispatch(refund(refundPayload));
          runBilling = false; // since refund is triggered, setting runBilling to false to avoid cm duplication
          break;
        case Product.RefundOrCancellationEnums.CANCEL_ONLY:
          runBilling = false; // to avoid creating CM on zuora side
          break;
        default:
          break;
      }

      if (isMembership) {
        // ---------- IF FROM MEMBERSHIP ----------
        const cancelMembershipPayload = cancelPayload.map((data: CancelPayload) => {
          return {
            ratePlanId: data.ratePlanId,
            subscriptionStartDate: data.subscriptionStartDate,
            subscriptionNumber: data.subscriptionNumber,
            zuoraProductId: data.zuoraProductId,
            isOnlyProductOnSubscription: data.isOnlyProductOnSubscription,
            isAutoRenewable: data.isAutoRenewable,
            subscriptionEndDate: data.subscriptionEndDate,
            shouldCancelFromStartDate: data.shouldCancelFromStartDate,
            amount: data.amount,
            discountedPrice: data?.discountedPrice,
            sku: data.sku,
            runBilling,
            orderProductId: data.orderProductId, // b2b
            cancelledQuantity: String(data.cancelledQuantity), // b2b
            cancelAction, // b2b
            isEndOfLastInvoicePeriod: data?.isEndOfLastInvoicePeriod,
            isB2B: data?.isB2B,
            refundAction: data?.refundAction, // b2b
            availableSeats: data?.availableSeats, // b2b
            assignedSeats: data?.assignedSeats, // b2b
            seatNumber: data?.seatNumber, // b2b
            reason: refundPayload?.zuoraPayload?.[0]?.reason, // b2b
          };
        });
        return request(CANCEL_MEMBERSHIP_ORDER, {
          zuoraPayload: cancelMembershipPayload,
          firmAccountId,
        })
          .then(async () => {
            // DEPROVISION PRODUCT
            const skus: string[] = cancelPayload
              .filter((data: CancelPayload) => Boolean(data.sku))
              .map((data: CancelPayload) => data.sku);
            if (skus.length) {
              const isTerminated = Boolean(
                membershipTerminationPayload.productStatus === Product.ProductCancellationStatus.TERMINATED
              );
              const deprovisionPromise: any[] = [];
              deprovisionPayload.forEach(
                (deprovisionItem: { orderId: string; skusForItemsBeingDeprovisioned: string[] }) => {
                  if (deprovisionItem?.orderId) {
                    deprovisionPromise.push(
                      dispatch(
                        deprovisionMultipleProduct(
                          deprovisionItem.orderId,
                          deprovisionItem.skusForItemsBeingDeprovisioned,
                          isTerminated
                        )
                      )
                    );
                  }
                }
              );
              await Promise.all(deprovisionPromise);
            }
            // UPDATE MEMBERSHIP STATUS AND MEMBERSHIP TERMINATION STATUS
            if (membershipId) {
              await dispatch(updateMembershipTerminationStatus(membershipTerminationPayload));
            }

            return {
              success: true,
              error: false,
              paymentId,
            };
          })
          .catch(() => {
            return {
              success: false,
              error: true,
              empty: false,
            };
          });
      }
      const finalCancelPayload = {
        ...cancelPayload[0],
        runBilling,
      };
      // ---------- IF FROM INDIVIDUAL PRODUCT ----------
      return request(CANCEL_ORDER, finalCancelPayload)
        .then(async () => {
          // DEPROVISION PRODUCT
          await dispatch(deprovision(orderId, finalCancelPayload.sku));
          // UPDATE CREDENTIAL / SECTION
          if (areAllTruthy(productType, productSku)) {
            await dispatch(updateMembershipTerminationStatus(membershipTerminationPayload));
          }
          return {
            success: true,
            error: false,
            paymentId,
          };
        })
        .catch(() => {
          return {
            success: false,
            error: true,
            empty: false,
          };
        });
    }
);

export const refund: any = createAction(
  'admin/REFUND',
  (payload: User.RefundRequestDetails) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(REFUND, payload)
      .then((res: any) => ({
        success: true,
        error: false,
      }))
      .catch(err => ({
        success: false,
        error: true,
        errorMessage: err?.response?.errors?.[0]?.message || '',
      }));
  }
);

export const cancelFirmMembership: any = createAction(
  'admin/CANCEL_FIRM_MEMBERSHIP',
  (payload: { cancellationDetails: UserTypes.FirmMembershipCancellationDetails }) => async () => {
    return request(CANCEL_FIRM_MEMBERSHIP, payload)
      .then((res: any) => ({
        success: res.cancelFirmMembership,
        error: !res.cancelFirmMembership ? 'Failed center membership termination/resignation.' : '',
      }))
      .catch((err: any) => ({
        success: false,
        error: err.message,
      }));
  }
);
export const setCenterMembershipList: any = createAction(
  'admin/SET_FIRM_CENTER_MEMBERSHIP',
  (membershipTermId: string) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();
    const centerMemberships = centerMembershipsListSelector(state);

    const activeCenterMembership = centerMemberships.filter(
      (centerMembership: CenterAdmin.FirmMembership) => centerMembership?.membershipTerm?.id !== membershipTermId
    );
    return { data: activeCenterMembership };
  }
);

export const setLegalEntity: any = createAction('admin/SET_LEGAL_ENTITY', (value: string) => {
  return { value };
});

export const setSalesforceAccountNumber: any = createAction('admin/SET_SALESFORCE_ACCOUNT_NUMBER', (value: string) => {
  return { value };
});

export const changeRequestedAction: any = createAction(
  'admin/CHANGE_REQUEST_ACTION',
  (value: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const { selectedProduct } = organizationOrderRefundCancelSelector(state);
    const { subscriptionStartDate, subscriptionNumber, isAutoRenewable, zuoraTermEndDate, ratePlanId } =
      selectedProduct as State.OrganizationOrderDetail;
    const isB2B = isB2BSelector(state);
    const match: any = createMatchSelector(getPath(Routes.ADMIN_ORDER_REFUND_CANCEL))(state as State.Root);
    const { accountId: firmAccountId } = match.params;

    let startDate;
    const numberOfSeats = selectedProduct?.AvailableSeats || 0;
    const discountedPrice =
      Number(selectedProduct?.UnitPrice) - Number(selectedProduct?.RV_Discount__c) / Number(selectedProduct?.Quantity);

    const association = ZuoraTypes.LegalEntity.ASSOCIATION;
    const legalEntity = legalEntitySelector(state) || association;
    const currency = selectedCurrency(state);
    const customHeaders = {
      existingEntity: legalEntity.toLowerCase(),
      currency: {
        label: currency,
      },
    };
    await updateContext(customHeaders);

    switch (value) {
      case Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION:
        startDate = subscriptionStartDate;
        break;
      case Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION:
        startDate = subscriptionStartDate;
        break;
      case Product.RefundOrCancellationEnums.PARTIAL_REFUND_WITH_CANCELLATION:
        startDate = moment().format('YYYY-MM-DD');
        break;
      default:
        startDate = zuoraTermEndDate;
        break;
    }

    const payload = {
      firmAccountId,
      subscriptionStartDate: startDate,
      subscriptionNumber,
      isOnlyProductOnSubscription: isB2B ? true : selectedProduct?.isOnlyProductOnSubscription, //  default to true on Exam Credit B2b refund
      isAutoRenewable,
      shouldCancelFromStartDate: value !== Product.RefundOrCancellationEnums.CANCEL_ONLY,
      ratePlanId,
      isB2B,
      refundAction: value,
      ...(numberOfSeats && !isNaN(Number(numberOfSeats)) && { seatNumber: Number(numberOfSeats) }),
      subscriptionEndDate: zuoraTermEndDate,
      availableSeats: Number(selectedProduct?.AvailableSeats),
      assignedSeats: Number(selectedProduct?.AssignedSeats),
      productSku: selectedProduct?.OrderItemSKU,
      discountedPrice,
    };

    const { previewCancelOrder } =
      value !== Product.RefundOrCancellationEnums.PARTIAL_REFUND_WITH_CANCELLATION
        ? await request(CANCEL_PREVIEW_ORDER, payload)
        : [];

    switch (value) {
      case Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION:
        return {
          requestAction: value,
          refundAmount: previewCancelOrder.refundAmount,
          fullRefundAmount: previewCancelOrder.refundAmount,
          seatNumberDisabled: false, // Set to false since user must be able to set the seat number on full refund with cancellation
          seatNumber: selectedProduct?.AvailableSeats,
          cancellationDateDisabled: true,
          totalRefundDisabled: true,
          isValidSeatNumber: true,
          isValidCancellationDate: false,
          isValidTotalRefund: false,
          previewCancelOrder,
        };
      case Product.RefundOrCancellationEnums.CANCEL_ONLY:
        return {
          requestAction: value,
          refundAmount: previewCancelOrder.refundAmount,
          fullRefundAmount: previewCancelOrder.refundAmount,
          seatNumberDisabled: false,
          seatNumber: '',
          cancellationDateDisabled: true,
          totalRefundDisabled: true,
          isValidSeatNumber: false,
          isValidCancellationDate: false,
          isValidTotalRefund: false,
          previewCancelOrder,
        };
      case Product.RefundOrCancellationEnums.REFUND_WITHOUT_CANCELLATION:
        return {
          requestAction: value,
          refundAmount: previewCancelOrder.refundAmount,
          fullRefundAmount: previewCancelOrder.refundAmount,
          seatNumberDisabled: true,
          cancellationDateDisabled: true,
          totalRefundDisabled: false,
          isValidSeatNumber: true,
          isValidCancellationDate: false,
          isValidTotalRefund: false,
          previewCancelOrder,
        };
      case Product.RefundOrCancellationEnums.PARTIAL_REFUND_WITH_CANCELLATION:
        return {
          requestAction: value,
          refundAmount: 0,
          fullRefundAmount: 0,
          seatNumberDisabled: false,
          seatNumber: '',
          cancellationDateDisabled: true,
          totalRefundDisabled: false,
          isValidSeatNumber: false,
          isValidCancellationDate: false,
          isValidTotalRefund: false,
        };
      case '':
        return {
          requestAction: '',
          refundAmount: previewCancelOrder.refundAmount,
          fullRefundAmount: previewCancelOrder.refundAmount,
          seatNumberDisabled: true,
          cancellationDateDisabled: true,
          totalRefundDisabled: true,
          isValidSeatNumber: false,
          isValidCancellationDate: false,
          isValidTotalRefund: false,
          previewCancelOrder,
        };
      default:
        return {
          requestAction: value,
          refundAmount: previewCancelOrder.refundAmount,
          fullRefundAmount: previewCancelOrder.refundAmount,
          seatNumberDisabled: false,
          cancellationDateDisabled: true,
          totalRefundDisabled: false,
          isValidSeatNumber: false,
          isValidCancellationDate: false,
          isValidTotalRefund: false,
          previewCancelOrder,
        };
    }
  }
);

export const onSeatNumberBlur: any = createAction(
  'admin/SEAT_NUMBER_BLUR',
  (value: number) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const state: State.Root = getState();
    const { requestedAction } = organizationOrderRefundCancelSelector(state);
    const { selectedProduct } = organizationOrderRefundCancelSelector(state);
    const { subscriptionNumber, isAutoRenewable, subscriptionStartDate, zuoraTermEndDate, ratePlanId } =
      selectedProduct as State.OrganizationOrderDetail;
    const isB2B = isB2BSelector(state);
    const match: any = createMatchSelector(getPath(Routes.ADMIN_ORDER_REFUND_CANCEL))(state as State.Root);
    const { accountId: firmAccountId } = match.params;
    const startDate =
      requestedAction === Product.RefundOrCancellationEnums.REFUND_WITH_CANCELLATION
        ? subscriptionStartDate
        : moment().format('YYYY-MM-DD');
    const discountedPrice =
      Number(selectedProduct?.UnitPrice) - Number(selectedProduct?.RV_Discount__c) / Number(selectedProduct?.Quantity);

    const payload = {
      firmAccountId,
      subscriptionStartDate: startDate,
      subscriptionNumber,
      isOnlyProductOnSubscription: isB2B ? true : selectedProduct?.isOnlyProductOnSubscription, //  default to true on Exam Credit B2b refund
      isAutoRenewable,
      shouldCancelFromStartDate: true,
      ratePlanId,
      isB2B,
      refundAction: requestedAction,
      ...(value && !isNaN(Number(value)) && { seatNumber: Number(value) }),
      subscriptionEndDate: zuoraTermEndDate,
      availableSeats: Number(selectedProduct?.AvailableSeats),
      assignedSeats: Number(selectedProduct?.AssignedSeats),
      productSku: selectedProduct?.OrderItemSKU,
      discountedPrice,
    };

    const { previewCancelOrder } = await request(CANCEL_PREVIEW_ORDER, payload);
    return {
      refundAmount: previewCancelOrder?.refundAmount,
      fullRefundAmount: previewCancelOrder?.refundAmount,
      seatNumberDisabled: false,
      cancellationDateDisabled: true,
      totalRefundDisabled: false,
      isValidSeatNumber: true,
      isValidCancellationDate: false,
      isValidTotalRefund: true,
      previewCancelOrder,
    };
  }
);

export const setSeatNumber: any = createAction(
  'admin/SET_SEAT_NUMBER',
  (value: number) => async (dispatch: Dispatch, getState: () => State.Root) => {
    const isValidSeatNumber = !isNaN(value);
    return {
      value,
      isValidSeatNumber,
    };
  }
);

export const setCancellationDate: any = createAction('admin/SET_CANCELLATION_DATE', (value: number) => ({
  value,
  isValidCancellationDate: true,
}));

export const setSelectedProduct: any = createAction(
  'admin/SET_SELECTED_PRODUCT',
  (selectedProduct: State.OrganizationOrderDetail) => async (dispatch: Dispatch, getState: () => State.Root) => ({
    selectedProduct,
  })
);

export const resetLegalEntity: any = createAction('admin/RESET_LEGAL_ENTITY');

export const setRefundAmount: any = createAction('admin/SET_REFUND_AMOUNT', (value: number) => ({ value }));

export const setTotalRefund: any = createAction('admin/SET_TOTAL_REFUND', (value: number) => ({ value }));

export const setReason: any = createAction('admin/SET_REASON', (value: string) => ({ value }));
export const selectMultipleInvoice: any = createAction(
  'admin/SELECT_MULTIPLE_INVOICE',
  (listOfInvoiceId: string[]) => (dispatch: Dispatch, getState: () => State.Root) => {
    const state = getState();

    return {
      ...state.admin.invoicesSelected,
      invoicesSelected: listOfInvoiceId,
    };
  }
);

export const resetOrganizationOrderRefundCancel: any = createAction('admin/RESET_ORGANIZATION_ORDER_REFUND_CANCEL');

export const getBulkRenewalAccess: any = createAction('admin/GET_RENEWAL_ROLE', () => {
  const authHelper = getAuthHelper();
  if (!(authHelper instanceof AppcAuthHelper)) {
    return Promise.reject('Authentication is mis-configured');
  }
  const shouldHaveBulkRenewalAccess = authHelper.getBulkRenewalAccess();
  return shouldHaveBulkRenewalAccess ? shouldHaveBulkRenewalAccess : false;
});

export const setTransactionHistoryTableLoading: any = createAction(
  'admin/TRANSACTION_HISTORY_LOADING',
  (isTransactionHistoryLoading: boolean) => () => isTransactionHistoryLoading
);

export const setReportHistoryTableLoading: any = createAction(
  'admin/REPORT_HISTORY_LOADING',
  (isReportHistoryLoading: boolean) => () => isReportHistoryLoading
);

export const getTransactionHistory: any = createAction(
  'admin/GET_TRANSACTION_HISTORY',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setTransactionHistoryTableLoading(true));
    return request(GET_BULK_RENEWAL_TRANSACTION_HISTORY)
      .then(res => {
        dispatch(setTransactionHistoryTableLoading(false));
        return res.getBulkRenewalTransactionHistory;
      })
      .catch(() => {
        dispatch(setTransactionHistoryTableLoading(false));
        return [
          {
            uniqueID: '',
            selections: '',
            generatedBy: '',
            date: '',
            time: '',
          },
        ];
      });
  }
);

export const getRecentTransaction: any = createAction(
  'admin/GET_RECENT_TRANSACTION',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(GET_RECENT_BULK_RENEWAL_TRANSACTION)
      .then(res => {
        return res.getRecentBulkRenewalTransaction[0];
      })
      .catch(() => {
        dispatch(setTransactionHistoryTableLoading(false));
        return {
          uniqueID: '',
          action: BulkRenewalAndReportActions.RENEWAL,
          status: BulkRenewalAndReportStatus.COMPLETED,
        };
      });
  }
);

export const getRecentDownloadedReport: any = createAction(
  'admin/GET_REPORT_HISTORY',
  () => async (dispatch: Dispatch, getState: () => State.Root) => {
    dispatch(setReportHistoryTableLoading(true));
    return request(GET_RECENT_DOWNLOAD_REPORT).then(res => {
      dispatch(setReportHistoryTableLoading(false));
      return res.getRecentDownloadedReport;
    });
  }
);

export const generateBulkRenewalReport: any = createAction(
  'admin/PROCESS_DOWNLOAD',
  async (membershipType: string, inBetweenDates: boolean, startDate: string, endDate: string) =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<any> => {
      return request(GENERATE_BULK_RENEWAL_REPORT, { membershipType, inBetweenDates, startDate, endDate }).then(res => {
        return res.generateBulkRenewalReport;
      });
    }
);

export const downloadReport: any = createAction(
  'admin/DOWNLOAD_REPORT',
  async (uniqueID: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    return request(DOWNLOAD_BULK_RENEWAL_REPORT, { uniqueID }).then(res => {
      return { preSignedUrl: res.downloadBulkRenewalReport };
    });
  }
);

export const processRenewal: any = createAction(
  'admin/PROCESS_RENEWAL',
  async (uniqueID: string) =>
    async (dispatch: Dispatch, getState: () => State.Root): Promise<any> => {
      return request(PROCESS_BULK_RENEWAL, { id: uniqueID, action: BulkRenewalAndReportActions.RENEWAL }).then(res => {
        return res.processBulkRenewalUpdateReport;
      });
    }
);

export const resetBulkRenewalState: any = createAction('admin/RESET_BULK_RENEWAL_STATE');

export const extensionRaiseCreditMemo: any = createAction(
  'admin/EXTENSION_RAISE_CREDIT_MEMO',
  (productSKU: string, orderNumber: string) => async (dispatch: Dispatch, getState: () => State.Root) => {
    try {
      const response = await request(EXTENSION_RAISE_CREDIT_MEMO, { productSKU, orderNumber });
      return {
        success: response.extensionRaiseCreditMemo.success,
        error: false,
      };
    } catch (err) {
      return {
        success: false,
        error: true,
        errorMessage: err.response.errors?.[0].message || '',
      };
    }
  }
);

export const setModalExtendAccessLoading: any = createAction('admin/OPEN_EXTEND_ACCESS_MODAL_LOADING');

export const openModalExtendAccess: any = createAction(
  'admin/OPEN_EXTEND_ACCESS_MODAL',
  (product: Common.ProductItemData) => async (dispatch: Dispatch) => {
    await dispatch(setModalExtendAccessLoading());
    await dispatch(toggleModalExtendAccessOpen());

    return product;
  }
);

export const confirmModalExtendAccess: any = createAction(
  'admin/CONFIRM_EXTEND_ACCESS_MODAL',
  (payload: Orders.ProductAccessExtensionB2C[]) => async (dispatch: Dispatch) => {
    await dispatch(setModalExtendAccessLoading());

    const extendAccess = await request(EXTEND_PRODUCT_ACCESS, {
      payload,
    });

    await dispatch(toggleModalExtendAccessOpen());

    return {
      ...extendAccess,
      extendedDate: moment(payload?.[0]?.extendEndDate).format('MMM D, YYYY'),
      success: extendAccess.extendProductAccess,
    };
  }
);

export const resetModalExtendAccess: any = createAction('admin/RESET_EXTEND_ACCESS_MODAL');
