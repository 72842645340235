import { handleActions } from 'redux-actions';
import { mipState } from './constants';
import {
  getMipPracticeRequirements,
  getMipPracticeRequirementsLoading,
  getRenewalSeason,
  getMipWorkHistory,
  getMipContact,
  getMipContactLoading,
  upsertWorkHistory,
  setMipLoading,
  deleteMipRecords,
} from './action';
import { createMipContact } from './actions';

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS: any = {
  [getMipPracticeRequirementsLoading]: (state: State.MIP, action: any): State.MIP => ({
    ...state,
    loading: true,
  }),
  [getMipPracticeRequirements]: {
    next: (state: State.MIP, action: any): State.MIP => ({
      ...state,
      contactDetails: {
        ...state.contactDetails,
        practiceList: action.payload,
      } as State.MipContact, // tslint:disable-line:no-object-literal-type-assertion
      practiceRequirement:
        action.payload.filter(
          (practiceRequirement: State.MipPracticeRequirement) => practiceRequirement.primaryPractice
        )[0] || action.payload[0],
      loading: false,
    }),
    throw: (state: State.MIP): State.MIP => ({
      ...state,
    }),
  },
  [getMipContactLoading]: {
    next: (state: State.MIP, action: any): State.MIP => ({
      ...state,
      loading: true,
    }),
    throw: (state: State.MIP): State.MIP => ({
      ...state,
    }),
  },
  [getMipContact]: {
    next: (state: State.MIP, action: any): State.MIP => ({
      ...state,
      contactDetails: {
        ...state.contactDetails,
        ...action.payload.accountDetails,
        contactPointPhone: action.payload.getMipContact.ContactPointPhone,
        isApplicationPartRenewal: action.payload.getMipContact.isApplicationPartRenewal,
      } as State.MipContact, // tslint:disable-line:no-object-literal-type-assertion
      loading: false,
    }),
    throw: (state: State.MIP): State.MIP => ({
      ...state,
    }),
  },
  [createMipContact]: {
    next: (state: State.MipContact, action: any): State.MipContact => ({
      ...state,
      ...action.payload,
      loading: false,
    }),
    throw: (state: State.MipContact): State.MipContact => ({
      ...state,
    }),
  },
  [getRenewalSeason]: {
    next: (state: State.MIP, action: any): State.MIP => ({
      ...state,
      ...action.payload,
    }),
    throw: (state: State.MipContact): State.MipContact => ({
      ...state,
    }),
  },
  [upsertWorkHistory]: {
    next: (state: State.MIP, action: any): State.MIP => ({
      ...state,
      workHistory: action.payload,
      loading: false,
    }),
    throw: (state: State.MIP): State.MIP => ({
      ...state,
    }),
  },
  [getMipWorkHistory]: {
    next: (state: State.MIP, action: any): State.MIP => ({
      ...state,
      workHistory: action.payload,
      loading: false,
    }),
    throw: (state: State.MIP): State.MIP => ({
      ...state,
    }),
  },
  [setMipLoading]: (state: State.MIP, action: any): State.MIP => ({
    ...state,
    loading: true,
  }),
  [deleteMipRecords]: (state: State.MIP, action: any): State.MIP => ({
    ...action.payload,
    loading: false,
  }),
};

// ------------------------------------
// ------------------------------------
export default handleActions(ACTION_HANDLERS, mipState);

export * from './selectors';
export * from './constants';
export * from './action';
export * from './actions';
