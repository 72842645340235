// ------------------------------------
// Constants
// ------------------------------------
import { FilterActiveKeys, ProfileListSortByValues } from '../../constants';

export const initialState: State.Layouts = {
  burgerExpanded: false,
  userSettingsExpanded: false,
  acrossLinksExpand: false,
  adminStickyFooterInvoiceFilterVisible: false,
  selectedTopicId: null,
  selectedIndustryId: null,
  selectedTrendId: null,
  selectedSkillId: null,
  selectedJobRoleId: null,
  selectedCareerStageId: null,
  selectedContentLanguageId: null,
  selectedLocationId: null,
  currentContentId: null,
  previousContentId: null,
  megaMobileNavHistory: ['/'],
  modalTopicUnfollowMeta: null,
  isPaymentProcessingModalOpen: false,
  isProvisionProcessingModalOpen: false,
  isAddressValidationModalOpen: false,
  isExtendAccessModalOpen: false,
  isOnboardingFinishedModalOpen: false,
  isOnboardingStartModalOpen: false,
  isOnboardingCancelModalOpen: false,
  searchModalExpanded: false,
  reloadAfterLogin: false,
  paginationInfo: {
    pagination: { from: 1, to: 1, perPage: 10, pageNumber: 1, total: 1 },
    currentPage: 1,
    filtering: {
      active: FilterActiveKeys.ACTIVE,
      history: ProfileListSortByValues.ALL_TIME,
    },
  },
  isCardSetDefaultModalOpen: false,
  isCardRemoveModalOpen: false,
  isCimaDeleteDefaultPaymentModalOpen: false,
  isCimaDeleteOnlyPaymentMethodModalOpen: false,
  isAddCreditCardModalOpen: false,
  isCardOperationBannerVisible: false,
  cardBeingProcessed: null,
  cardProcessed: null,
  isDeleteCardBeingProcessed: false,
  creditCardOperation: { name: 'none', success: false },
  orgProfileExpanded: false,
  subscriptionRenewalToggle: {},
  cancelSOrderModalMeta: null,
  subscriptionRenewalToggleSuccess: null,
  subscriptionRenewalToggleLoading: false,
  recentlyAddedBundleDiscountPercentage: null,
  cartBundleMergeBannerMessage: '',
  isCartContainsInvalidBundles: false,
  isImpersonatedUserPasswordModalOpen: false,
  cimaDeleteOnlyPaymentMethodModalButtonLoading: false,
};

export enum AuthenticationPageToShow {
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  LOGIN_MODAL,
  REGISTRATION_MODAL,
  OTHER,
}

export enum LayoutActionNames {
  SET_BURGER_EXPAND = 'layouts/burgerExpand',
  SET_SEARCH_MODAL_EXPAND = 'layouts/searchModalExpand',
  SET_USER_SETTINGS_EXPAND = 'layouts/SET_USER_SETTINGS_EXPAND',
  SET_ACROSS_LINKS_EXPAND = 'layouts/ACROSS_LINKS_EXPAND',
  SET_ADMIN_STICKY_FOOTER_INVOICE_FILTER_VISIBLE = 'layouts/SET_ADMIN_STICKY_FOOTER_INVOICE_FILTER_VISIBLE',
  SET_ORG_PROFILE_EXPAND = 'layouts/SET_ORG_PROFILE_EXPAND',
  SELECT_TOPIC_ID = 'layouts/SELECT_TOPIC_ID',
  SELECT_INDUSTRY_ID = 'layouts/SELECT_INDUSTRY_ID',
  SELECT_TREND_ID = 'layouts/SELECT_TREND_ID',
  SELECT_SKILL_ID = 'layouts/SELECT_SKILL_ID',
  SELECT_JOBROLE_ID = 'layouts/SELECT_JOBROLE_ID',
  SET_CURRENT_CONTENT_ID = 'layouts/SET_CURRENT_CONTENT_ID',
  SET_PREVIOUS_CONTENT_ID = 'layouts/SET_PREVIOUS_CONTENT_ID',
  RESET_CURRENT_CONTENT_ID = 'layouts/RESET_CURRENT_CONTENT_ID',
  SET_MEGAMENU_EXPAND = 'layouts/SET_MEGAMENU_EXPAND',
  PUSH_MEGA_MOBILE_NAV_HISTORY = 'layouts/PUSH_MEGA_MOBILE_NAV_HISTORY',
  BACK_MEGA_MOBILE_NAV_HISTORY = 'layouts/BACK_MEGA_MOBILE_NAV_HISTORY',
  CLEAR_MEGA_MOBILE_NAV_HISTORY = 'layouts/CLEAR_MEGA_MOBILE_NAV_HISTORY',
  TOGGLE_MODAL_TOPIC_UNFOLLOW = 'layouts/TOGGLE_MODAL_TOPIC_UNFOLLOW',
  TOGGLE_MODAL_PAYMENT_PROCESSING = 'layouts/TOGGLE_MODAL_PAYMENT_PROCESSING',
  TOGGLE_MODAL_RESET_IMPERSONATED_USER_PASSWORD = 'layouts/TOGGLE_MODAL_RESET_IMPERSONATED_USER_PASSWORD',
  TOGGLE_PROVISION_PROCESSING = 'layouts/TOGGLE_PROVISION_PROCESSING',
  TOGGLE_ONBOARDING_FINISHED = 'layouts/TOGGLE_ONBOARDING_FINISHED',
  TOGGLE_ONBOARDING_START = 'layouts/TOGGLE_ONBOARDING_START',
  GO_TO_PREVIOUS_ROUTE = 'layouts/GO_TO_PREVIOUS_ROUTE',
  SHOW_REGISTRATION = 'layouts/SHOW_REGISTRATION',
  SHOW_LOGIN = 'layouts/SHOW_LOGIN',
  CLOSE_AUTHENTICATION_MODAL = 'layouts/CLOSE_AUTHENTICATION_MODAL',
  TOGGLE_ONBOARDING_CANCEL = 'layouts/TOGGLE_ONBOARDING_CANCEL',
  TOGGLE_LOGIN_RELOAD = 'layouts/TOGGLE_LOGIN_RELOAD',
  TOGGLE_CARD_SET_DEFAULT_MODAL = 'layouts/TOGGLE_CARD_SET_DEFAULT_MODAL',
  TOGGLE_CARD_REMOVE_MODAL = 'layouts/TOGGLE_CARD_REMOVE_MODAL',
  TOGGLE_DELETE_DEFAULT_PAYMENT_MODAL = 'layouts/TOGGLE_DELETE_DEFAULT_PAYMENT_MODAL',
  TOGGLE_DELETE_CARD_BEING_PROCESSED = 'layouts/TOGGLE_DELETE_CARD_BEING_PROCESSED',
  TOGGLE_DELETE_ONLY_PAYMENT_METHOD_MODAL = 'layouts/TOGGLE_DELETE_ONLY_PAYMENT_METHOD_MODAL',
  TOGGLE_DELETE_ONLY_PAYMENT_METHOD_MODAL_BUTTON_LOADING = 'layouts/TOGGLE_DELETE_ONLY_PAYMENT_METHOD_MODAL_BUTTON_LOADING',
  TOGGLE_ADD_CREDIT_CARD_MODAL = 'layouts/TOGGLE_ADD_CREDIT_CARD_MODAL',
  SET_CARD_BEING_PROCESSED = 'layouts/SET_CARD_BEING_PROCESSED',
  SET_CARD_PROCESSED = 'layouts/SET_CARD_PROCESSED',
  CLOSE_CARD_OPERATION_BANNER = 'layouts/CLOSE_CARD_OPERATION_BANNER',
  SHOW_CARD_OPERATION_BANNER = 'layouts/SHOW_CARD_OPERATION_BANNER',
  SET_CARD_OPERATION = 'layouts/SET_CARD_OPERATION',
  TOGGLE_SUBSCRIPTION_RENEWAL = 'layouts/TOGGLE_SUBSCRIPTION_RENEWAL',
  TOGGLE_CANCEL_STANDING_ORDER_MODAL = 'layouts/TOGGLE_CANCEL_STANDING_ORDER_MODAL',
  TOGGLE_ADDRESS_VALIDATION_MODAL = 'layouts/TOGGLE_ADDRESS_VALIDATION_MODAL',
  TOGGLE_SUBSCRIPTION_RENEWAL_TOGGLE_MODAL = 'layouts/TOGGLE_SUBSCRIPTION_RENEWAL_TOGGLE_MODAL',
  TOGGLE_EXTEND_ACCESS_MODAL = 'layouts/TOGGLE_EXTEND_ACCESS_MODAL',
  SET_SUBSCRIPTION_RENEWAL_TOGGLE_SUCCESS = 'layouts/SET_SUBSCRIPTION_RENEWAL_TOGGLE_SUCCESS',
  SET_RECENTLY_ADDED_BUNDLE_DISCOUNT = 'layouts/SET_RECENTLY_ADDED_BUNDLE_DISCOUNT',
  SET_CART_BUNDLE_MERGE_BANNER_MESSAGE = 'layouts/SET_CART_BUNDLE_MERGE_BANNER_MESSAGE',
  SET_IS_CART_CONTAINS_INVALID_BUNDLES = 'layouts/SET_IS_CART_CONTAINS_INVALID_BUNDLES',
  SET_CART_MIGRATION_MESSAGES = 'layouts/SET_CART_MIGRATION_MESSAGES',
  SHOW_MY_CONSENTS = 'user/SHOW_MY_CONSENTS',
  SHOW_MARKETING_PREFERENCES = 'user/SHOW_MARKETING_PREFERENCES',
  SHOW_TOPIC_PREFERENCES = 'user/SHOW_TOPIC_PREFERENCES',
  SHOW_PERSONAL_DETAILS = 'user/SHOW_PERSONAL_DETAILS',
  SHOW_CONTACT_DETAILS = 'user/SHOW_CONTACT_DETAILS',
  SHOW_EMPLOYMENT_DETAILS = 'user/SHOW_EMPLOYMENT_DETAILS',
  SHOW_TUITION_PROVIDER = 'user/SHOW_TUITION_PROVIDER',
  SHOW_MY_RECEIPTS = 'user/SHOW_MY_RECEIPTS',
  SHOW_MY_PAYMENT_METHODS = 'user/SHOW_MY_PAYMENT_METHODS',
  SHOW_CIMA_PERSONAL_DETAILS = 'SHOW_CIMA_PERSONAL_DETAILS',
  SHOW_CIMA_CONTACT_DETAILS = 'SHOW_CIMA_CONTACT_DETAILS',
  SHOW_PER_EMPLOYMENT = 'SHOW_PER_EMPLOYMENT',
  SET_SUBSCRIPTION_RENEWAL_TOGGLE_LOADING = 'SET_SUBSCRIPTION_RENEWAL_TOGGLE_LOADING',
}
